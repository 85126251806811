import _ from 'lodash'
import Schema from 'async-validator';
export default function useChannelOperations () {
  const layerRules = {
    layerName: { required: true, message: '数据图层名称不能为空' },
    datasetId: { required: true, message: '数据图层必须链接一个数据集' },

  }
  const validator = new Schema(layerRules)

  const validateLayers4Update = async (layers4Update, callback) => {
    console.log('validate Layers4Update')
    let allOk = true
    for (let i = 0; i < layers4Update.length; i++) {
      let element = layers4Update[i]
      let modelControl = element.modelControl
      try {
        await validator.validate(element)
      } catch ({ errors, fields }) {
        console.log("validate element fail", i, errors, fields);
        allOk = false
        for (let key in fields) {
          modelControl[key] = fields[key][0].message
        }
        console.log(modelControl);
      }
    }
    if (allOk && (typeof callback == "function")) callback()
  }

  const validateInputHandler = (item, e) => {
    const prop = e.target.attributes.prop.value
    if (!prop) {
      return false
    }
    let modelControl = item.modelControl
    if (modelControl[prop]) {
      let obj = {}
      obj[prop] = item[prop]

      validator.validate(obj, (errors, fields) => {
        if (errors && fields[prop]) {
          //console.log(errors, fields);
          //console.log(fields[prop][0].message);

          modelControl[prop] = fields[prop][0].message
          return errors
        }
        modelControl[prop] = null
      })
    } else {
      return
    }
  }

  const validateInput = _.debounce(validateInputHandler, 300);

  return {
    layerRules,
    validateLayers4Update,
    validateInput
  }

}
<template>
  <div class="yy-main-area column items-stretch">
    <BoardHead
      :title="portionTitle"
      :darkMode="true"
      @go-back="closeBoard"
    ></BoardHead>
    <div v-if="isEditor" class="yy-box-wrapper">
      <keep-alive exclude="CoverUpdate">
        <component
          :is="portionName"
          @go2Box="go2Box"
          @back2Box="back2Box"
          @updateLoading="loadingVisible = $event"
          v-bind="comPros"
        ></component>
      </keep-alive>
      <q-inner-loading
        :showing="loadingVisible"
        label="正在保存数据，请稍等..."
        label-class="text-true-main-color3"
        label-style="font-size: 1.1em"
      />
    </div>
    <NeedAuth v-else></NeedAuth>
  </div>
</template>
    
<script>
import BoardHead from "@components/drawer/BoardHead";
import MyProject from "@components/drawer/projectboard/MyProject";
import ChannelEdit from "@components/drawer/projectboard/ChannelEdit";
import CoverUpdate from "@components/drawer/projectboard/CoverUpdate";
import DataLayer from "@components/drawer/projectboard/DataLayer";
import NeedAuth from "@components/common/NeedAuth";
import { useStore } from "vuex";
import { computed } from "vue";
export default ({
  name: "ProjectBoard",
  emits: ["closeBoard"],
  setup () {
    console.log('project board setup')
    const store = useStore()
    return {
      isAuthenticated: computed(() => store.getters.isAuthenticated),
      isEditor: computed(() => store.getters.isEditor)
    }
  },
  data () {
    return {
      portions: new Map([['MyProject', '我的项目'], ['ChannelEdit', ['创建频道', '设置频道'],], ['DataLayer', '数据图层管理'], ['CoverUpdate', ['设置封面', '修改封面']]]),
      portionTitle: "",
      portionName: "",
      comPros: {},
      portionStack: [],
      loadingVisible: false
    }
  },
  components: {
    BoardHead,
    MyProject,
    ChannelEdit,
    CoverUpdate,
    DataLayer,
    NeedAuth
  },
  created () {
    this.portionNames = [...this.portions.keys()]
    this.portionTitles = [...this.portions.values()]
    this.portionName = this.portionNames[0]
    this.portionTitle = this.portionTitles[0]
  },
  methods: {
    closeBoard () {
      console.log('closeboard')
      if (this.portionStack.length == 0) {
        this.$emitter.emit('toggleDrawerEvent', 0)
        this.$emit('closeBoard')
      } else {
        this.back2Box()
      }
    },

    back2Box (args) {
      console.log('back2Box', args)
      let last = this.portionStack.length - 1
      this.portionName = this.portionStack[last][0]
      this.portionTitle = this.portionStack[last][1]
      this.portionStack.splice(last, 1)
      this.comPros = args || {}
      console.log("comPros", args)
    },
    go2Box (args) {
      console.log('go2Box', args)
      this.portionStack.push([this.portionName, this.portionTitle])
      this.portionName = args[0]
      this.portionTitle = args[1]
      this.comPros = args[2] ? args[2] : {}
      console.log("comPros", args[2])
    }
  }
})
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/common.my.sass"
</style>
//mutation types
export const MT_SET_AUTHENTICATED = "SET_AUTHENTICATED"
export const MT_SET_USER = "SET_USER"
export const MT_SET_USER_NAME = "SET_USER_NAME"
export const MT_SET_USER_AVATAR = "SET_USER_AVATAR"
export const MT_SET_USER_BIO = "SET_USER_BIO"
export const MT_SET_USER_EMAIL = "SET_USER_EMAIL"
export const MT_SET_USER_NICK_NAME = "SET_USER_NICK_NAME"
export const MT_SET_USER_ROLES = "SET_USER_ROLES"

export const MT_SET_CHANNEL_SELECTED = "SET_CHANNEL_SELECTED"
export const MT_SET_MAP_SELECTED = "SET_MAP_SELECTED"
export const MT_SET_CHANNEL_LIST = "SET_CHANNEL_LIST"
export const MT_SET_CHANNEL_INFO_LIST = "SET_CHANNEL_INFO_LIST"
export const MT_SET_CHANNEL_SELECTED_OWNER = "MT_SET_CHANNEL_SELECTED_OWNER"

//storage keys
export const ST_ACCESS_TOKEN = "access_token"
export const ST_USER_INFO = "user_info"
export const ST_SELECTED_CHANNEL = "selected_channel"
export const ST_SELECTED_MAP = "selected_map"

//map
export const MAP_PLATFORM_MAPBOX = "mapbox"
export const MAP_PLATFORM_GAODE = "amap"
//md5
export const MD_SIGNATURE_SECRET = 'dd05f1c54d63749eda95f9fa6d49v442a'




<template>
  <div class="column items-stretch">
    <BoardHead
      title="登录"
      @go-back="$emit('changeStatus', 'Guest')"
    ></BoardHead>
    <q-form class="self-center" @submit.prevent.stop="login">
      <div class="yy-main-box column items-center self-center">
        <!-- <div class="yy-title">社交账号登录</div>
      <div class="row yy-social-icon-wrapper">
        <q-btn flat round dense class="yy-social-icon">
          <img
            class="q-icon"
            aria-hidden="true"
            role="img"
            src="@img/icon-weibo.png"
          />
        </q-btn>
        <q-btn flat round dense class="yy-social-icon"
          ><img
            class="q-icon"
            aria-hidden="true"
            role="img"
            src="@img/icon-qq.png"
        /></q-btn>
        <q-btn flat round dense class="yy-social-icon"
          ><img
            class="q-icon"
            aria-hidden="true"
            role="img"
            src="@img/icon-wechat.png"
        /></q-btn>
      </div>
      <div class="yy-title-line">或邮箱密码登录</div> -->
        <div class="yy-title q-mb-lg">邮箱密码登录</div>

        <q-input
          class="yy-input-wrapper"
          outlined
          clearable
          dense
          autofocus
          hide-bottom-space
          color="true-main-color3"
          bg-color="gray-color3"
          type="email"
          maxlength="50"
          v-model="model.email"
          placeholder="邮箱"
          input-class="yy-input"
          :rules="emailRules"
          lazy-rules
        />
        <q-input
          class="yy-input-wrapper"
          outlined
          clearable
          dense
          hide-bottom-space
          color="true-main-color3"
          bg-color="gray-color3"
          :type="isPwd ? 'password' : 'text'"
          maxlength="50"
          v-model="model.password"
          placeholder="密码"
          input-class="yy-input"
          :rules="passRules"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>
        <div class="row item-start justify-between yy-code-wrapper">
          <q-input
            class="yy-input-wrapper"
            outlined
            clearable
            dense
            hide-bottom-space
            color="true-main-color3"
            bg-color="gray-color3"
            type="text"
            maxlength="20"
            v-model="model.smsCode"
            placeholder="验证码"
            input-class="yy-input"
            :rules="smsCodeRules"
          />
          <img
            v-if="smsCodeSuccess"
            :src="randCodeImage"
            @click="handleChangeCheckCode"
          />
          <img v-else src="@img/checkcode.png" @click="handleChangeCheckCode" />
        </div>
        <q-checkbox
          class="yy-thirty"
          size="xs"
          name="shape"
          color="true-main-color3"
          keep-color
          label="30天免登录"
          v-model="rememberMe"
        />
        <q-btn
          class="yy-btn"
          unelevated
          color="main-color3"
          label="登录"
          type="submit"
          :loading="doing"
          :disable="doing"
        />

        <div class="yy-other-way">
          <span @mousedown.prevent="$emit('changeStatus', 'ResetPass')"
            >忘了密码</span
          ><span>|</span
          ><span @mousedown.prevent="$emit('changeStatus', 'Register')"
            >注册账号</span
          >
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import BoardHead from "@components/drawer/BoardHead";
import { getSmsCaptcha } from "@api/auth-api";
import { useStore } from "vuex";
import useUserInfoRules from "@composables/useUserInfoRules";
import { ref } from "vue"
export default {
  name: "Login",
  emits: ["changeStatus"],
  setup () {
    const rememberMe = ref(true)
    const { emailRulesFunc, passRules, smsCodeRules } =
      useUserInfoRules();
    const emailRules = emailRulesFunc("登录账号");

    const store = useStore();
    return {
      emailRules,
      passRules,
      smsCodeRules,
      rememberMe,

      sevLogin: (loginParams) => store.dispatch("login", loginParams),
    };
  },
  props: {
    userId: { type: String, default: "" }
  },
  data () {
    return {
      model: {
        email: "",
        password: "",
        smsCode: "",
      },

      smsCodeSuccess: false,
      randCodeImage: "",
      currdatetime: "",
      doing: false,
      isPwd: true,
    };
  },
  components: {
    BoardHead,
  },
  created () {
    this.handleChangeCheckCode();
    this.login = this.__.debounce(this.loginHandler, 500);
    this.model.email = this.userId

    if (
      this.$route.redirectedFrom &&
      this.$route.redirectedFrom.path.includes("activelogin")
    ) {
      this.model.email = this.$route.redirectedFrom.params.email;
    }
  },
  methods: {
    handleChangeCheckCode () {
      this.currdatetime = new Date().getTime();
      this.model.smsCode = "";
      getSmsCaptcha(this.currdatetime)
        .then((res) => {
          this.randCodeImage = res;
          this.smsCodeSuccess = true;
        })
        .catch((err) => {
          console.log("ge sms code fail:", err);
          this.smsCodeSuccess = false;
        });
    },
    loginHandler () {
      this.doing = true;
      let loginParams = {
        username: this.model.email,
        password: this.model.password,
        captcha: this.model.smsCode,
        checkKey: this.currdatetime,
        rememberMe: this.rememberMe,
      };
      this.sevLogin(loginParams)
        .then((res) => {
          console.log("login result:", res);
          this.$emit("changeStatus", "User");
        })
        .finally(() => {
          this.doing = false;
        });
    },
  },
};
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/drawer.auth.sass"
</style>
<style lang="sass">
@import "@sass/quasar.sass"
@import "@sass/drawer.auth.g.sass"
</style>

<template>
  <div class="yy-policy column items-stretch no-wrap">
    <BoardHead
      :title="files.get(fileName) || fileTitle"
      @go-back="$emit('changeStatus', backStatus)"
    ></BoardHead>
    <q-scroll-area
      class="yy-scroll-wrapper"
      @scroll="toggleBackBtn"
      ref="scrollAreaRef"
    >
      <div v-if="done" class="column self-stretch yy-content-wrapper no-wrap">
        <span v-if="fileName != 'about'" v-html="fileContent"></span>
        <template v-else v-for="item in fileContent" :key="item.year">
          <div class="yy-about-year">——{{ item.year }}——</div>
          <div
            v-for="dayItem in item.list"
            :key="dayItem.day"
            class="yy-about-day-wrapper row no-wrap"
          >
            <div class="yy-about-day">{{ dayItem.day }}</div>
            <div class="yy-about-description">{{ dayItem.description }}</div>
          </div>
        </template>
      </div>
    </q-scroll-area>
    <div v-show="backVisible" class="yy-back-wrapper">
      <q-btn
        outline
        round
        size="sm"
        color="main-color3"
        icon="arrow_upward"
        @click="back2Top"
      />
    </div>
  </div>
</template>
<script>
import BoardHead from "@components/drawer/BoardHead";
import axios from 'axios'
import { ref } from 'vue'
export default {
  name: "Policy",
  emits: ["changeStatus"],
  props: {
    fileName: { type: String, default: "privacy" },
    backStatus: { type: String, default: "Profile" },
    fileTitle: { type: String, default: "fileTitle" }
  },
  setup () {
    const scrollAreaRef = ref(null)
    return {
      scrollAreaRef,
    }
  },
  data () {
    return {
      files: new Map([
        ['privacy', '隐私政策'],
        ['agreement', '用户协议'],
        ['about', '关于我们'],
        ['contact', '联系我们'],
        ['doing', null]]),
      fileContent: '暂无内容',
      backVisible: false,
      done: false
    }
  },
  components: {
    BoardHead,
  },
  created () {
    axios.get(this.fileName + '.json').then(res => {
      this.fileContent = res.data.content
      console.log('result', res)
    })
      .catch(error => {
        alert("error", error);
      })
      .finally(() => {
        this.done = true
      })
  },
  methods: {
    toggleBackBtn (info) {
      if (info.verticalPercentage > 0.2) {
        this.backVisible = true
      } else {
        this.backVisible = false
      }
    },
    back2Top () {
      this.scrollAreaRef.setScrollPosition('vertical', 0, 300)
    }
  }

};
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/drawer.auth.sass"
.yy-policy
  height: 100vh
  position: relative
  .yy-scroll-wrapper
    height: calc(100vh - #{$drawer-board-header-height})
    //height: calc(100vh - 74px)
    .yy-content-wrapper
      padding: $drawer-xpadding
      color: $gray-color1
      word-wrap: break-word
      word-break: break-all
      white-space: break-spaces
      font-size: 12px
      .yy-about-year
        margin-top: 18px
        text-align: center
        font-size: 18px
        font-weight: 700
        line-height: 24px
        color: $gray-color5
        &:first-child
          margin-top: 4px
      .yy-about-day-wrapper
        margin-top: 18px
        font-size: 12px
        line-height: 18px
        .yy-about-day
          flex-shrink: 0
        .yy-about-description
          margin-left: 10px
  .yy-back-wrapper
    position: absolute
    right: 20px
    bottom: 20px
</style>
<style lang="sass">
@import "@sass/quasar.sass"
</style>


import { createStore } from "vuex";
import { MT_SET_AUTHENTICATED, MT_SET_USER, MT_SET_USER_NAME, MT_SET_USER_AVATAR, MT_SET_USER_BIO, ST_ACCESS_TOKEN, ST_USER_INFO, MT_SET_USER_EMAIL, MT_SET_USER_NICK_NAME, MT_SET_CHANNEL_SELECTED, MT_SET_MAP_SELECTED, MT_SET_CHANNEL_LIST, MT_SET_CHANNEL_INFO_LIST, MT_SET_USER_ROLES, MT_SET_CHANNEL_SELECTED_OWNER, MAP_PLATFORM_MAPBOX, ST_SELECTED_CHANNEL, ST_SELECTED_MAP } from "@config/constant"
import { login } from "@api/auth-api";
import { useStorage } from "vue3-storage-secure";

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    isAuthenticated: false,
    user: {},
    userName: null,
    userAvatar: null,
    userBio: "",
    selectedChannel: null,
    selectedMap: MAP_PLATFORM_MAPBOX,
    channelList: null,
    channelINfoList: null,
  },
  mutations: {
    [MT_SET_AUTHENTICATED] (state, isAuthenticated = false) {
      if (isAuthenticated) state.isAuthenticated = isAuthenticated;
      else state.isAuthenticated = false;
    },

    [MT_SET_USER] (state, user = {}) {
      if (user) state.user = user;
      else state.user = {};
    },

    [MT_SET_USER_NAME] (state, userName = null) {
      if (userName) state.userName = userName;
      else state.userName = null;
    },

    [MT_SET_USER_AVATAR] (state, userAvatar = null) {
      if (userAvatar) {
        state.userAvatar = process.env.VUE_APP_API_BASE_STATIC_URL + userAvatar
        state.user.avatar = userAvatar
      }
      else {
        state.userAvatar = null
        state.user.avatar = null
      }
    },

    [MT_SET_USER_BIO] (state, userBio = "") {
      if (userBio) {
        state.userBio = userBio;
        state.user.bio = userBio;
      }
      else {
        state.userBio = "";
        state.user.bio = "";
      }
    },

    [MT_SET_USER_EMAIL] (state, userEmail = null) {
      if (userEmail) state.user.email = userEmail;
      else state.user.email = null;
    },

    [MT_SET_USER_NICK_NAME] (state, userNickName = null) {
      if (userNickName) state.user.nickname = userNickName;
      else state.user.nickname = null;
    },

    [MT_SET_USER_ROLES] (state, roles = []) {
      if (roles) state.user.roles = roles;
      else state.user.roles = [];
    },


    [MT_SET_CHANNEL_SELECTED] (state, selectedChannel = null) {
      if (selectedChannel) {
        state.selectedChannel = selectedChannel
      }
      else state.selectedChannel = null;
    },

    [MT_SET_CHANNEL_SELECTED_OWNER] (state, owner = null) {
      if (owner) {
        state.selectedChannel.owner = owner
      }
      else state.selectedChannel.owner = null;
    },

    [MT_SET_MAP_SELECTED] (state, selectedMap = null) {
      if (selectedMap) state.selectedMap = selectedMap;
      else state.selectedMap = null;
    },

    [MT_SET_CHANNEL_LIST] (state, channelList = null) {
      if (channelList) state.channelList = channelList;
      else state.channelList = null;
    },
    [MT_SET_CHANNEL_INFO_LIST] (state, channelInfoList = null) {
      if (channelInfoList) state.channelInfoList = channelInfoList;
      else state.channelInfoList = null;
    },
  },
  actions: {
    setAuthenticated: ({ commit }, isAuthenticated) => {
      commit(MT_SET_AUTHENTICATED, isAuthenticated);
    },
    setUser: ({ commit }, userInfo) => {
      commit(MT_SET_AUTHENTICATED, true)
      commit(MT_SET_USER, userInfo)
      commit(MT_SET_USER_NAME, userInfo.username)
      commit(MT_SET_USER_AVATAR, userInfo.avatar)
      commit(MT_SET_USER_BIO, userInfo.bio)
    },
    updateUserAvatar: ({ commit, state }, userAvatar) => {
      commit(MT_SET_USER_AVATAR, userAvatar)
      useStorage().setSecureStorageSync(ST_USER_INFO, state.user)
    },

    updateUserNickName: ({ commit, state }, userNickName) => {
      commit(MT_SET_USER_NICK_NAME, userNickName)
      useStorage().setSecureStorageSync(ST_USER_INFO, state.user)
    },

    updateUserEmail: ({ commit, state }, userEmail) => {
      commit(MT_SET_USER_EMAIL, userEmail)
      useStorage().setSecureStorageSync(ST_USER_INFO, state.user)
    },

    updateUserBio: ({ commit, state }, userBio) => {
      commit(MT_SET_USER_BIO, userBio)
      useStorage().setSecureStorageSync(ST_USER_INFO, state.user)
    },

    // 登录
    login ({ dispatch }, user) {
      return new Promise((resolve, reject) => {
        console.log('login userInfo', user)
        login(user).then(res => {
          const storage = useStorage()
          const userInfo = res.userInfo
          userInfo.roles = res.roles || []
          let rememberMilliseconds = 30 * 60 * 1000
          if (user.rememberMe) {
            rememberMilliseconds = 30 * 24 * 60 * 60 * 1000
            //storage.setSecureStorageSync(ST_USER_PASS, userInfo.password)
          }
          console.log('remember mseconds', rememberMilliseconds)
          storage.setSecureStorageSync(ST_ACCESS_TOKEN, res.token, rememberMilliseconds)
          storage.setSecureStorageSync(ST_USER_INFO, userInfo)
          dispatch("setUser", userInfo)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    clearUser ({ commit }) {
      commit(MT_SET_AUTHENTICATED)
      commit(MT_SET_USER)
      commit(MT_SET_USER_NAME)
      commit(MT_SET_USER_AVATAR)
      commit(MT_SET_USER_BIO)
    },

    updateUser ({ commit, state }, model) {
      commit(MT_SET_USER_NICK_NAME, model.nickName)
      commit(MT_SET_USER_EMAIL, model.email)
      commit(MT_SET_USER_BIO, model.bio)
      useStorage().setSecureStorageSync(ST_USER_INFO, state.user)
    },

    updateUserRoles ({ commit, state }, roles) {
      commit(MT_SET_USER_ROLES, roles)
      useStorage().setSecureStorageSync(ST_USER_INFO, state.user)
    },

    removeUser ({ dispatch }) {
      dispatch('clearUser')
      useStorage().removeStorageSync(ST_ACCESS_TOKEN);
    },

    setSelectedChannel ({ commit }, selectedChannel) {
      commit(MT_SET_CHANNEL_SELECTED, selectedChannel)
    },

    updateSelectedChannel ({ commit }, selectedChannel) {
      commit(MT_SET_CHANNEL_SELECTED, selectedChannel)
      useStorage().setSecureStorageSync(ST_SELECTED_CHANNEL, selectedChannel)
    },

    setSelectedChannelObj ({ commit }, selectedObj) {
      commit(MT_SET_CHANNEL_SELECTED, selectedObj.selectedChannel)
      commit(MT_SET_CHANNEL_SELECTED_OWNER, selectedObj.owner)
    },

    updateSelectedChannelObj ({ commit }, selectedObj) {
      commit(MT_SET_CHANNEL_SELECTED, selectedObj.selectedChannel)
      commit(MT_SET_CHANNEL_SELECTED_OWNER, selectedObj.owner)
      useStorage().setSecureStorageSync(ST_SELECTED_CHANNEL, selectedObj.selectedChannel)
    },

    setSelectedMap ({ commit }, selectedMap) {
      commit(MT_SET_MAP_SELECTED, selectedMap)
    },

    updateSelectedMap ({ commit }, selectedMap) {
      commit(MT_SET_MAP_SELECTED, selectedMap)
      useStorage().setSecureStorageSync(ST_SELECTED_MAP, selectedMap)
    },

    setChannelList ({ commit }, channelList) {
      commit(MT_SET_CHANNEL_LIST, channelList)
    },

    setChannelInfoList ({ commit }, channelInfoList) {
      commit(MT_SET_CHANNEL_INFO_LIST, channelInfoList)
    },

  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    userName: state => state.userName,
    user: state => state.user,
    userBio: state => state.userBio,
    selectedChannel: state => state.selectedChannel,
    selectedMap: state => state.selectedMap,
    channelList: state => state.channelList,
    channelInfoList: state => state.channelInfoList,
    isEditor: (state) => {
      return state.user != undefined && state.user.roles != undefined && undefined != state.user.roles.find((role) => {
        return role == "yunyu_edit"
      })
    }
  },
  modules: {},
});

<template>
  <drawer-head subTitle="问题反馈"></drawer-head>
</template>
<script>
import DrawerHead from "@components/frontline/DrawerHead";
export default {
  name: "Feedback",
  components: {
    DrawerHead,
  },
};
</script>

<template>
  <drawer-head mainTitle="国际频道" subTitle="频道介绍"></drawer-head>
</template>
<script>
import DrawerHead from "@components/frontline/DrawerHead";
export default {
  name: "ChannelIntroduction",
  components: {
    DrawerHead,
  },
};
</script>
<style lang="sass" scoped></style>

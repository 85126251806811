<template>
  <q-dialog v-model="showDialog" persistent>
    <div class="yy-my-dialog">
      <div class="row justify-between items-center">
        <div class="yy-my-dialog-title">上传数据集到云端</div>
        <q-btn
          class="yy-my-dialog-btn"
          unelevated
          v-close-popup
          @click="$emit('clickClose')"
        >
          <q-icon
            class="yy-my-dialog-btn-icon"
            :name="'img:' + iconBase64.get('closeDialog')"
          ></q-icon>
        </q-btn>
      </div>
      <div class="yy-my-dialog-caption">目前仅支持上传GeoJSON格式文件。</div>
      <div class="yy-my-dialog-box column justify-end items-center">
        <q-btn class="yy-q-btn" label="打开文件夹" @click="openFolder"></q-btn>
        <div class="yy-my-dialog-note">
          *上传后的数据将保存在您的云端数据库中，在未经授权的情况下，只有您有权限访问并编辑。
        </div>
      </div>
      <q-inner-loading
        :showing="loadingVisible"
        label="Please wait..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
      />
    </div>
    <input
      type="file"
      id="uploads"
      class="yy-my-file"
      accept=".geojson,.json,application/json"
      @change="selectFile"
      ref="uploadFile"
    />
  </q-dialog>
</template>
<script>
import { ref, watch, toRefs } from 'vue'
import useDrawerMaterial from "@composables/useDrawerMaterial";
import useDatasetOperations from '@/composables/useDatasetOperations'
export default {
  name: "UploadDatasetDialog",
  emits: ["clickClose", "reloadClose"],
  props: {
    show: { type: Boolean, default: false }
  },
  setup (props) {
    const { iconBase64 } = useDrawerMaterial();
    const { uploadDataset, validateGeoJson } = useDatasetOperations()
    const { show } = toRefs(props)
    console.log('props confirm', show)
    const showDialog = ref(false)

    watch(
      show,
      (value) => {
        console.log('watch confirm', value)
        showDialog.value = value
      }
    )
    return {
      iconBase64,
      showDialog,
      uploadDataset,
      validateGeoJson
    }
  },
  data () {
    return {
      geojson: "",
      datasetName: "",
      loadingVisible: false
    }
  },
  methods: {
    openFolder () {
      console.log('openFolder ', document.getElementById("uploads"))
      document.getElementById("uploads").click();

    },
    selectFile (e) {
      console.log('select file', e)
      var file = e.target.files[0];
      console.log('file:', file)
      const fileMaxSize = 1024 * 200
      if (file.size > fileMaxSize) {
        this.$q.notify({
          message: `上传的数据集文件大小不能超过200KB`
        })
        this.$refs.uploadFile.value = ''
        return
      }
      this.datasetName = file.name.substring(0, file.name.lastIndexOf("."))
      console.log('file name:', this.datasetName)
      var reader = new FileReader();
      reader.onload = e => {
        try {
          console.log("onload e", e)
          this.loadingVisible = true
          this.geojson = JSON.parse(e.target.result);
          console.log(this.geojson)
          if (!this.validateGeoJson(this.geojson)) {
            return
          }
          this.uploadDataset({ datasetName: this.datasetName, geojson: this.geojson }, () => {
            console.log('callback')
            //this.loadingVisible = false
            this.$emit('reloadClose')
          }, () => {
            //this.loadingVisible = false
          })
        } catch (e) {
          this.$q.notify({
            message: "无法正确解析文件，原因：" + e
          })
        } finally {
          this.loadingVisible = false
          this.$refs.uploadFile.value = ''
        }
      }
      reader.readAsText(file);

    },

  }
}
</script>
<style lang="sass" scoped>
@import "@sass/common.edit.sass"
@import "@sass/common.dialog.sass"
.yy-my-dialog
  &-box
    min-width: 560px
    height: 244px
    border: 1px dashed $gray-color2
    border-radius: 4px
    position: relative
    @media screen and (max-width: 570px)
      min-width: 300px
  &-note
    font-size: 12px
    line-height: 20px
    padding: 8px 0
</style>


import axios from 'axios'
import { useStorage } from "vue3-storage-secure";
import { ST_ACCESS_TOKEN } from '@config/constant';
import store from "@store"
/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL: '/jeecg-boot'
 * 则映射后端域名，通过 vue.config.js
 * @type {*|string}
 */
let apiBaseUrl = process.env.VUE_APP_API_BASE_URL || "/jeecg-boot";
console.log("apiBaseUrl= ", apiBaseUrl)
// 创建 axios 实例
const service = axios.create({
  //baseURL: '/jeecg-boot',
  baseURL: apiBaseUrl, // api base_url
  timeout: 9000 // 请求超时时间
})

const err = (error) => {
  console.log("fail response:", error)
  let errObj = {}
  if (error.response) {
    let data = error.response.data
    const token = useStorage().getSecureStorageSync(ST_ACCESS_TOKEN)
    console.log("------异常响应------", token)
    console.log("------异常响应------", error.response.status)
    let blobTokenInvalid = false
    switch (error.response.status) {
      case 403:
        errObj = { message: '系统提示', description: '拒绝访问', duration: 4 }
        break
      case 500:
        console.log("------error.response------", error.response)
        errObj = { message: '系统提示', description: data.message, duration: 4 }

        if (error.response.request.responseType === 'blob') {
          blobTokenInvalid = blobToJson(data);
          break;
        }
        if (blobTokenInvalid || token && data.message.includes("Token失效")) {
          store.dispatch("removeUser")
          errObj = { message: '系统提示', description: '很抱歉，登录已过期，请重新登录', duration: 4 }

          //useStore().dispatch("removeUser")
        }

        break
      case 404:
        errObj = { message: '系统提示', description: '很抱歉，资源未找到!', duration: 4 }
        break
      case 504:
        errObj = { message: '系统提示', description: '网络超时' }
        break
      case 401:
        errObj = { message: '系统提示', description: '未授权，请重新登录', duration: 4 }
        break
      default:
        errObj = {
          message: '系统提示',
          description: data.message,
          duration: 4
        }
        break
    }
  } else if (error.message) {
    if (error.message.includes('timeout')) {
      errObj = { message: '系统提示', description: '网络超时' }
    } else {
      errObj = { message: '系统提示', description: error.message }
    }
  }
  error.errObj = errObj
  return Promise.reject(error)
};

// request interceptor
service.interceptors.request.use(config => {

  const token = useStorage().getSecureStorageSync(ST_ACCESS_TOKEN)
  if (token) {
    config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  console.log("request config:", config)
  return config
}, (error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
  console.log("success response:", response)
  if (response.data.success) return response.data.result
  else if (response.data.success == undefined) return response.data
  else {
    let error = {
      errObj: {
        message: "服务端返回",
        description: response.data.message || "未知错误",
        code: response.data.code || "未知错误代码"
      }
    }
    return Promise.reject(error)
  }
  //return response.data
}, err)

/**
 * Blob解析
 * @param data
 */
function blobToJson (data) {
  let blobTokenInvalid = false
  let fileReader = new FileReader();
  let token = useStorage().getSecureStorageSync(ST_ACCESS_TOKEN)
  fileReader.onload = function () {
    try {
      let jsonData = JSON.parse(this.result);  // 说明是普通对象数据，后台转换失败
      console.log("jsonData", jsonData)
      if (jsonData.status === 500) {
        console.log("token----------》", token)
        if (token && jsonData.message.includes("Token失效")) {
          blobTokenInvalid = true
        }
      }
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      console.log("blob解析fileReader返回err", err)
    }
  };
  fileReader.readAsText(data)
  return blobTokenInvalid
}

export {
  axios as o_axios,
  service as axios
}
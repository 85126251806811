<template>
  <div class="yy-my yy-my-project column no-wrap items-stretch">
    <div class="yy-my-tabs row items-center justify-between">
      <div
        v-for="(item, index) in tabs"
        :key="item.title"
        class="yy-my-tab column items-center"
        @click="selectTab(index)"
      >
        <q-icon
          :name="'img:' + (activeTab == index ? item.iconActive : item.icon)"
          class="yy-my-tab-icon"
        ></q-icon>
        <div
          class="yy-my-tab-title"
          :class="{ 'title-active': activeTab == index }"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="yy-my-head row items-center justify-between">
      <div class="yy-my-head-title">我的频道</div>
      <q-btn
        unelevated
        class="yy-my-head-btn"
        @click="$emit('go2Box', ['ChannelEdit', '创建频道', { channel: {} }])"
        >创建频道</q-btn
      >
    </div>
    <div flat class="yy-line"></div>
    <q-scroll-area
      v-if="channelList.length > 0"
      class="yy-my-list-wrapper yy-channel-list-wrapper"
    >
      <q-list class="yy-my-list yy-channel-list">
        <q-item
          v-ripple
          v-for="(item, index) in channelList"
          :key="item.id"
          class="yy-channel-list-item"
        >
          <q-item-section class="column">
            <div class="yy-channel-main row">
              <q-avatar
                class="yy-channel-avatar"
                @click.stop="selectItem(index)"
              >
                <q-icon
                  class="yy-channel-avatar-icon"
                  :name="'img:' + item.avatar"
                />
              </q-avatar>
              <div
                class="column yy-channel-info"
                @click.stop="selectItem(index)"
              >
                <div class="yy-channel-title">{{ item.channelName }}</div>
                <div class="yy-channel-intro">{{ item.channelIntro }}</div>
              </div>
              <div class="row">
                <q-btn
                  unelevated
                  round
                  color="dark-color5"
                  class="yy-oper-btn"
                  @mouseenter="item.iconDeleteLight = true"
                  @mouseleave="item.iconDeleteLight = false"
                  @click.stop="deleteChannel(index)"
                  ><q-icon
                    class="yy-oper-btn-icon"
                    :name="
                      'img:' +
                      (item.iconDeleteLight
                        ? iconBase64.get('channelDeleteLight')
                        : iconBase64.get('channelDelete'))
                    "
                  ></q-icon>
                  <q-tooltip
                    class="yy-avatar-tip"
                    anchor="top middle"
                    self="bottom middle"
                    :offset="[0, 0]"
                    transition-show="slide-down"
                    transition-hide="slide-up"
                    >删除</q-tooltip
                  >
                </q-btn>
                <q-btn
                  unelevated
                  round
                  color="dark-color5"
                  class="yy-oper-btn"
                  @mouseenter="item.iconSettingLight = true"
                  @mouseleave="item.iconSettingLight = false"
                  @click="setChannel(index)"
                  ><q-icon
                    class="yy-oper-btn-icon"
                    :name="
                      'img:' +
                      (item.iconSettingLight
                        ? iconBase64.get('channelSettingLight')
                        : iconBase64.get('channelSetting'))
                    "
                  ></q-icon>
                  <q-tooltip
                    class="yy-avatar-tip"
                    anchor="top middle"
                    self="bottom middle"
                    :offset="[0, 0]"
                    transition-show="slide-down"
                    transition-hide="slide-up"
                    v-model="item.setTipVisible"
                    >设置</q-tooltip
                  ></q-btn
                >
              </div>
            </div>
            <div class="yy-channel-sub row justify-between">
              <div class="yy-data-layer" @click="goDataLayer(index)">
                数据图层
              </div>
              <q-checkbox
                left-label
                label="公开"
                size="xs"
                true-value="1"
                false-value="0"
                v-model="item.shared"
                @update:model-value="toggleShare(index)"
              />
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <div v-else class="yy-my-tips q-mt-lg">您还没有创建任何频道</div>
    <DialogConfirm
      :confirm="confirm"
      @clickOk="confirm.callback"
      @clickCancel="confirm.cancel"
    ></DialogConfirm>
  </div>
  <q-inner-loading
    :showing="loadingVisible"
    :label="loadingLabel"
    label-class="text-true-main-color3"
    label-style="font-size: 1.1em"
  />
</template>
<script>
//import { reactive } from "vue"
//import { useStore } from "vuex";
import useDrawerMaterial from "@composables/useDrawerMaterial";
import UseChannelOperations from "@composables/useChannelOperations"
import DialogConfirm from '@/components/common/DialogConfirm'
import { getMyChannelList, deleteChannel, updateChannelInfo } from "@api/channel-api"
import { ref, onMounted, watch, toRefs, inject } from "vue"
import { useStore } from "vuex";
export default {
  name: "MyProject",
  emits: ["go2Box"],
  props: {
    reload: { type: Number, default: Date.now() }
  },
  setup (props) {
    const store = useStore();
    const { reload } = toRefs(props)
    const emitter = inject('$emitter')
    const channelList = ref([])
    const activeItem = ref(null)
    const activeIndex = ref(null)
    const { iconBase64 } = useDrawerMaterial();
    const { channelListHandler, refreshSharedChannels, refreshSharedChannelsIfNeed } = UseChannelOperations();
    const tabs = [{
      title: "我的创建", icon: iconBase64.get("myCreated"), iconActive: iconBase64.get("myCreatedLight"),
    }, {
      title: "参与管理", icon: iconBase64.get("myParticipated"), iconActive: iconBase64.get("myParticipatedLight")
    }]

    const iconDelete = ref(iconBase64.get("channelDelete"))
    const iconSetting = ref(iconBase64.get("channelSetting"))
    const owner = { userName: store.state.userName, nickName: store.state.user.nickname, avatar: store.state.userAvatar }

    const selectItem = function (index, force = false) {
      console.log('select my channel item', index)
      if (activeIndex.value == index && !force) return
      if (index >= 0) {
        activeIndex.value = index
        activeItem.value = channelList.value[index]
        activeItem.value.owner = owner
      } else {
        activeIndex.value = null
        activeItem.value = null
      }
      emitter.emit('selectChannelEvent', activeItem.value)
    }

    const getChannels = async () => {
      const result = await getMyChannelList()
      channelList.value = channelListHandler(result)
      console.log('fetch my channels', channelList.value)
    }
    onMounted(getChannels)

    watch(reload, (newValue) => {
      console.log('The new reload value is: ', reload.value, newValue)
      getChannels()
    })

    watch(channelList, (newValue) => {
      console.log('watch channel List', newValue)
      if (newValue.length > 0) {
        if (activeItem.value == null) {
          selectItem(0, true)
        } else {
          let foundIndex = newValue.findIndex(value => value.id == activeItem.value.id)
          if (foundIndex < 0) {
            selectItem(0, true)
          } else {
            selectItem(foundIndex, true)
          }
        }
      } else (
        selectItem(-1, true)
      )
    }, { immediate: true })

    return {
      iconBase64,
      tabs,
      iconDelete,
      iconSetting,
      channelList,
      getChannels,
      refreshSharedChannels,
      refreshSharedChannelsIfNeed,
      activeItem,
      activeIndex,
      selectItem
    };
  },
  components: {
    DialogConfirm

  },
  data () {
    return {
      activeTab: 0,

      confirm: {
        show: false
      },
      loadingVisible: false,
      loadingLabel: "",

    };
  },
  created () {
    this.$emitter.on("channelDataLayersUpdated", this.refreshActiveItem)
  },
  methods: {
    selectTab (index) {
      console.log('select tab', index)
      this.activeTab = index
    },
    deleteChannel (index) {
      console.log('delte channel', index)
      this.confirm = {
        show: true,
        question: "从我的项目中删除该频道？",
        description: "该频道将立即被删除，此操作无法撤销。",
        cancelText: "取消",
        okText: "删除",
        callback: this.deleteChannelHandler,
        channel: this.channelList[index]
      }
    },
    async deleteChannelHandler () {
      let channel = this.confirm.channel
      console.log('delete channel', channel)
      try {
        await deleteChannel({ id: channel.id })
        this.$q.notify({
          message: "频道删除成功！",
          color: "positive"
        })
        if (channel.id == this.activeItem.id) {
          this.activeItem = null
          this.activeIndex = null
        }
        this.getChannels()
        this.refreshSharedChannelsIfNeed(channel)
      } catch (err) {
        console.log("delete channel fail:", err)
      }
    },
    toggleShare (index) {
      console.log('toggle share', this.channelList[index].shared)
      this.confirm.channel = this.channelList[index]
      if (this.channelList[index].shared == '0') {
        this.confirm = {
          ...this.confirm,
          show: true,
          question: "是否将频道设置为不公开？",
          description: "该频道将设置为不公开状态，其他用户无法浏览频道。",
          cancelText: "取消",
          okText: "确定",
          callback: this.toggleShareHandler,
          cancel: this.keepShareState
        }
      }
      else {
        this.toggleShareHandler()
      }
    },
    async toggleShareHandler () {
      let channel = this.confirm.channel
      console.log('toggle share handler', channel)
      try {
        let { id, shared } = channel
        await updateChannelInfo({ id, shared })
        //console.log('update shared status success', res)
        this.refreshSharedChannels()
        this.$q.notify({
          message: "频道公开状态修改成功！",
          color: "positive"
        })
      } catch (err) {
        console.log("update share status fail")
        channel.shared = channel.shared == '1' ? '0' : '1'
      }
    },
    keepShareState () {
      this.confirm.channel.shared = '1'
    },
    setChannel (index) {
      let channel = this.channelList[index]
      channel.setTipVisible = false
      channel.iconSettingLight = false

      this.$emit("go2Box", ["ChannelEdit", "设置频道", { channel }])
    },
    goDataLayer (index) {
      let channel = this.channelList[index]
      this.$emit("go2Box", ["DataLayer", "数据图层管理", { channel }])
    },
    refreshActiveItem (event) {
      console.log('refresh my channel active item')
      if (event.channel.id == this.activeItem.id && event.type != "shared") {
        this.selectItem(this.activeIndex, true)
      }
      this.refreshSharedChannelsIfNeed(event.channel)
    }
  },
  beforeUnmounted () {
    this.$emitter.off("channelDataLayersUpdated", this.refreshActiveItem)
  }
};
</script>

<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/quasar.sass"
@import "@sass/common.edit.sass"
@import "@sass/common.my.sass"
.yy-my-project
  .yy-channel-list-wrapper
    .yy-channel-list
      &-item
        padding: 6px 4px 12px 2px!important
        border-radius: 8px
        &:hover
          background-color: $dark-color7!important
          .q-focus-helper
            opacity: 0!important
          .yy-channel-avatar
            @include removeGray
            &-icon
              border-color: $gray-color2
            &:hover
              cursor: pointer
          .yy-channel-title
            color: $gray-color2
          .yy-channel-sub
            .yy-data-layer
              background-color: $main-color3
              color: $gray-color2
        .yy-channel-info
          flex: 1
          cursor: pointer
          .yy-channel-title
            font-size: 18px
            line-height: 24px
            color: $gray-color2
            font-weight: 400
          .yy-channel-intro
            font-size: 12px
            line-height: 18px
            color: $gray-color5
            font-weight: 400
        .yy-channel-avatar
          margin-right: 2px
          padding: 0!important
          width: 36px
          height: 36px
          @include gray
          &-icon
            width: 28px
            height: 28px
            border: 2px solid $gray-color5
            border-radius: 50%
            object-fit: cover
        .yy-channel-sub
          margin-top: 12px
          .yy-data-layer
            width: 64px
            height: 24px
            border-radius: 4px
            margin-left: 40px
            background-color: $dark-color7
            color: $gray-color5
            font-size: 14px
            line-height: 24px
            text-align: center
            cursor: pointer
</style>
<style lang="sass">
@import "@sass/quasar.variables.sass"
.yy-channel-list-item
  .yy-channel-sub
    .q-checkbox__inner.text-true-main-color3
      color: $main-color3
    .q-checkbox__inner
      min-width: 24px
      width: 24px
      height: 24px
      color: $dark-color7
      .q-checkbox__bg
        background-color: currentColor
    .q-checkbox__label
      color: $gray-color5
      font-size: 14
  &:hover
    .yy-channel-sub
      .q-checkbox__inner
        color: $main-color3
      .q-checkbox__label
        color: $gray-color2
</style>


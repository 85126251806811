<template>
  <div class="yy-data-layer column items-stretch" @click="cancelNewLayer">
    <div class="yy-channel-info column">
      <div class="yy-channel-name">{{ channel.channelName }}</div>
      <div class="yy-update-time">
        上次更新 {{ channel.updateTime || channel.createTime }}
      </div>
    </div>
    <div class="yy-list-head row items-center justify-between">
      <div class="yy-list-head-title">数据图层（{{ dataLayers.length }}）</div>
      <q-btn
        unelevated
        class="yy-list-head-btn"
        @click="commitUpdate"
        :disable="!updateEnable"
        >保存更新</q-btn
      >
    </div>
    <div flat class="yy-line"></div>
    <q-scroll-area class="yy-layer-list-wrapper">
      <q-list class="yy-layer-list">
        <template v-for="(item, index) in layers4Update" :key="item.id">
          <q-item
            v-if="item.status != 'new'"
            v-ripple
            class="yy-layer-list-item row items-center"
          >
            <q-icon
              :name="'img:' + iconBase64.get('layerActive')"
              class="yy-layer-icon"
            ></q-icon>
            <div class="yy-layer-wrapper column items-stretch">
              <label class="yy-layer-label">图层名</label>
              <label class="yy-layer-name">{{ item.layerName }}</label>
              <div class="yy-set-wrapper row items-center justify-between">
                <!-- <div
                class="yy-set-box row no-wrap items-center"
                @mouseenter="item.iconSetLight = true"
                @mouseleave="item.iconSetLight = false"
                @click.stop="toggleDataset(index)"
              > -->
                <div class="yy-set-box row no-wrap items-center">
                  <q-icon
                    class="yy-set-icon"
                    :name="
                      'img:' +
                      (item.iconSetLight
                        ? iconBase64.get('dataSetLight')
                        : iconBase64.get('dataSet'))
                    "
                  ></q-icon>
                  <label>{{ item.datasetName }}</label>
                </div>
                <OperBtnGroup
                  :btnGroup="item.shared == '1' ? btnGroup1 : btnGroup0"
                  @doOperation="operHandler($event, index)"
                ></OperBtnGroup>
              </div>
            </div>
            <DatasetList
              v-if="item.showSetList"
              :datasets="datasets4Show"
              @selectDataset="selectDataset($event, index)"
            ></DatasetList>
          </q-item>
          <div
            v-else
            class="yy-new-layer yy-layer-list-item row items-center"
            @click.stop
          >
            <q-icon
              :name="'img:' + iconBase64.get('layerActive')"
              class="yy-layer-icon"
            ></q-icon>
            <div class="yy-layer-wrapper column items-stretch">
              <div class="yy-my-input-wrapper yy-input-wrapper column">
                <input
                  id="layerName"
                  autofocus
                  autocomplete="off"
                  :ref="'layerNameRef' + index"
                  v-model="item.layerName"
                  @input="validateInput(item, $event)"
                  prop="layerName"
                  :class="{
                    'validate-feedback': item.modelControl['layerName'],
                  }"
                />
                <label class="order-first" for="layerName">图层名</label>
                <div
                  class="yy-validate-feedback"
                  v-if="item.modelControl['layerName']"
                >
                  {{ item.modelControl['layerName'] }}
                </div>
              </div>
              <div class="yy-set-wrapper row items-center justify-between">
                <div
                  class="yy-set-box row justify-center"
                  @click.stop="toggleDataset(index)"
                >
                  <label>{{
                    item.datasetId == '' ? '链接数据集' : item.datasetName
                  }}</label>
                </div>
                <OperBtnGroup
                  :btnGroup="item.shared == '1' ? btnGroup1 : btnGroup0"
                  @doOperation="operHandler($event, index)"
                ></OperBtnGroup>
              </div>
              <input type="hidden" prop="datasetId" v-model="item.datasetId" />
              <div
                class="yy-validate-feedback"
                v-if="item.modelControl['datasetId']"
              >
                {{ item.modelControl['datasetId'] }}
              </div>
            </div>
            <DatasetList
              v-if="item.showSetList"
              :datasets="datasets4Show"
              @selectDataset="selectDataset($event, index)"
            ></DatasetList>
          </div>
        </template>
        <div class="yy-layer-add-box" @click.stop="addNewLayer">添加图层</div>
      </q-list>
    </q-scroll-area>
    <DialogConfirm
      :confirm="confirm"
      @clickOk="confirm.callback"
      @clickCancel="confirm.cancel"
    ></DialogConfirm>
  </div>
</template>
<script>
import { toRefs, reactive, ref, onActivated, onDeactivated, computed, inject/*, nextTick*/ } from "vue"
import DialogConfirm from '@/components/common/DialogConfirm'
import useDrawerMaterial from "@composables/useDrawerMaterial";
import OperBtnGroup from '@/components/common/OperBtnGroup'
import DatasetList from '@/components/drawer/projectboard/DatasetList'
import useChanneLayerlOperations from '@/composables/useChanneLayerlOperations'
import UseChannelOperations from "@composables/useChannelOperations"
import { fetchDatasets } from "@api/dataset-api"
import { getChannelDatasetList, updateChannelDatasets, deleteChannelDataset, updateChannelDataset }
  from "@api/channel-api"
import _ from 'lodash'
import { useQuasar } from 'quasar'
export default {
  name: "DataLayer",
  emits: ["updateLoading", "updated"],
  props: {
    channel: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  setup (props, context) {
    const $q = useQuasar()
    const layerNameRef = ref(null)
    const { iconBase64 } = useDrawerMaterial();
    const { channel } = toRefs(props)
    const emitter = inject('$emitter')
    const dataLayers = ref([])
    const layers4Update = reactive([])
    const datasets = ref(null)

    const { validateLayers4Update, validateInput } = useChanneLayerlOperations()
    const { refreshSharedChannelsIfNeed } = UseChannelOperations();

    const getChannelDatasets = async () => {
      if (datasets.value == null) {
        const result = await fetchDatasets()
        datasets.value = result.records
      }
      dataLayers.value = await getChannelDatasetList({ channelId: channel.value.id })
      let layers = _.cloneDeep(dataLayers.value);
      let newLayers = layers.map(value => {
        let dataset = datasets.value.find(item => item.id == value.datasetId)
        return { ...value, datasetName: dataset ? dataset.datasetName : '', modelControl: {}, showSetList: false }
      })
      layers4Update.length = 0
      layers4Update.push(...newLayers)
      console.log('fetch  channel datasets', layers4Update)
    }

    const getMyDatasets = async () => {
      const result = await fetchDatasets()
      datasets.value = result.records
      console.log('fetch my  datasets', datasets)
    }
    onActivated(getChannelDatasets)
    onDeactivated(() => {
      datasets.value = null
      layers4Update.length = 0
      dataLayers.value.length = 0
    })

    const confirm = ref({})

    const commitDelete = (index) => {
      console.log('commitDelete')
      let layer = layers4Update[index]
      if (layer.status == "new") {
        layers4Update.splice(index, 1)
      } else {
        confirm.value = {
          show: true,
          question: "从频道中删除该数据图层？",
          description: "该数据图层将立即被删除，此操作无法撤销。",
          cancelText: "取消",
          okText: "删除",
          callback: deleteLayer,
          index
        }
        console.log('delete confirm', confirm)
      }
    }
    const deleteLayer = async () => {
      context.emit("updateLoading", true)
      try {
        let index = confirm.value.index
        let id = layers4Update[index].id
        await deleteChannelDataset({ id })
        $q.notify({
          message: "数据图层删除成功！",
          color: "positive"
        })
        emitter.emit("channelDataLayersUpdated", { channel: channel.value, type: "delete" })
        layers4Update.splice(index, 1)
        let layerIndex = dataLayers.value.findIndex(value => value.id == id)
        dataLayers.value.splice(layerIndex, 1)

      } catch (err) {
        console.log("delete fail, cause:", err)
      } finally {
        context.emit("updateLoading", false)
      }
    }

    const toggleVisible = async (index) => {
      console.log('toggleVisible', index)
      try {
        let element = layers4Update[index]
        let shared = element.shared == '1' ? '0' : '1'
        let id = element.id
        if (element.status != "new") {
          await updateChannelDataset({ id, shared })
          $q.notify({
            message: "修改数据图层分享状态成功！",
            color: "positive"
          })
          emitter.emit("channelDataLayersUpdated", { channel: channel.value, type: "shared" })
          let layer = dataLayers.value.find(value => value.id == id)
          layer.shared = shared
        }

        element.shared = shared

      } catch (err) {
        console.log('toggle visible fail', err)
      }
    }

    let sorting = false
    const sortDataSet = (index) => {
      let len = layers4Update.length
      if (sorting || len == 1) return
      sorting = true
      console.log('sortDataSet')
      if (index == len - 1) {
        layers4Update.unshift(layers4Update.splice(index, 1)[0])
      } else {
        layers4Update[index] = layers4Update.splice(index + 1, 1, layers4Update[index])[0];
      }
      sorting = false
    }

    const btnGroup = new Map([[['channelDelete', '删除'], commitDelete], [['setVisible', '共享'], toggleVisible], [['setSort', '排序'], sortDataSet]])

    const addStatus = ref(false)
    const showSetList = ref(false)

    const btnVisible = ['setVisible', 'setUnvisibleLight', '取消共享']
    const btnGroup0 = reactive([...btnGroup.keys()])
    const btnGroup1 = reactive([...btnGroup.keys()])
    btnGroup1.splice(1, 1, btnVisible)
    btnGroup.set(btnVisible, toggleVisible)

    const updateEnable = computed(() => {
      console.log('compute update enable')
      for (let i = 0; i < layers4Update.length; i++) {
        let element = layers4Update[i]
        if (element.status == "new") { return true }
        let layer = dataLayers.value[i]
        if (element.id != layer.id || element.datasetId != layer.datasetId || element.layerName != layer.layerName) {
          return true
        }
      }
      return false
    })

    return { iconBase64, dataLayers, datasets, confirm, btnGroup, btnGroup0, btnGroup1, addStatus, showSetList, getMyDatasets, getChannelDatasets, layerNameRef, layers4Update, updateEnable, validateLayers4Update, validateInput, refreshSharedChannelsIfNeed }
  },
  components: {
    DialogConfirm,
    OperBtnGroup,
    DatasetList
  },
  data () {
    return {
      datasets4Show: []
    }
  },
  methods: {
    addNewLayer () {
      this.layers4Update.push({ id: null, channelId: this.channel.id, layerName: "", datasetId: "", datasetName: "", shared: '1', status: "new", modelControl: {}, showSetList: false })
      this.$nextTick(() => {
        this.$refs['layerNameRef' + (this.layers4Update.length - 1)].focus()
      })
      console.log("add new layer to layers4Update", this.layers4Update)
    },
    selectDataset (dataset, index) {
      console.log('select dataset', dataset)
      let layer = this.layers4Update[index]
      layer.datasetId = dataset.id
      layer.datasetName = dataset.datasetName
      layer.showSetList = false
      layer.modelControl.datasetId = ""
    },
    commitUpdate () {
      this.confirm = {
        show: true,
        question: "确定保存并更新数据图层设置？",
        description: "保存后将覆盖原有图层设置，此操作无法撤销。",
        cancelText: "取消",
        okText: "保存",
        callback: this.updateLayers
      }
    },
    updateLayers () {
      this.validateLayers4Update(this.layers4Update, async () => {
        try {
          console.log("do commit")
          this.$emit("updateLoading", true)
          //let lastNumber = 0
          let mapLayers = this.layers4Update.map((value, index) => {
            const { id, layerName, channelId, datasetId, shared } = value
            /* let serialNumber = value.serialNumber != undefined ? (value.serialNumber + 1) : (index == 0 ? index : (lastNumber))
            lastNumber = serialNumber */
            let layer = { id, layerName, channelId, datasetId, shared, serialNumber: index }
            return layer
          })
          console.log("map Layers", mapLayers)
          let layers = mapLayers.filter((value, index) => {
            let layer = this.dataLayers[index]
            if (layer && value.id == layer.id && value.datasetId == layer.datasetId && value.layerName == layer.layerName) {
              return false
            }
            return true
          })
          await updateChannelDatasets(layers)
          this.$q.notify({
            message: "数据图层更新成功！",
            color: "positive"
          })
          this.getChannelDatasets()
          //this.refreshSharedChannelsIfNeed(this.channel, true)
          this.$emitter.emit("channelDataLayersUpdated", { channel: this.channel, type: "update" })
        } catch (err) {
          console.log("update layers fail", err)
        } finally {
          this.$emit("updateLoading", false)
        }
      })
    },
    operHandler (btnIndex, index) {
      console.log('oper handler', btnIndex, index, this.btnGroup.values())
      let btnArr = [...this.btnGroup.values()]
      btnArr[btnIndex](index)
    },
    newOperHandler (event) {
      console.log('new oper handler', event)
      this.newBtnGroup.get(event)()
    },
    cancelNewLayer () {
      this.addStatus = false
      this.showSetList = false
    },
    toggleDataset (index) {
      let dataLayer = this.layers4Update[index]
      if (dataLayer.showSetList) {
        dataLayer.showSetList = false
      } else {
        this.layers4Update.forEach(value => {
          value.showSetList = false
        })
        dataLayer.showSetList = true
      }
      if (dataLayer.showSetList) {
        this.filterDatasets(dataLayer.datasetId)
      }
    },
    async filterDatasets (includeId) {
      console.log('filterDataset')
      try {
        if (this.datasets == null) {
          const result = await fetchDatasets()
          this.datasets = result.records
          console.log('fetch my  datasets', this.datasets)
        }
        this.datasets4Show = this.datasets.filter(item => (undefined == this.layers4Update.find(layer => {
          return layer.datasetId == item.id
        }) || includeId == item.id
        ))
        console.log("datasets for show", this.datasets4Show)
      } catch (err) {
        console.log('filter dataset fail', err)
      }
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/common.edit.sass"
.yy-data-layer
  padding: 14px 2px 0 0
  height: 100%
  @media screen and (max-height: 212px)
    height: auto
  .yy-channel-info
    font-weight: 400
    margin-left: 40px
    height: 90px
    .yy-channel-name
      max-height: 72px
      line-height: 32px
      padding: 4px 0
      font-size: 24px
      color: $gray-color2
      overflow: hidden
    .yy-update-time
      line-height: 16px
      font-size: 12px
      color: $gray-color5
  .yy-list-head
    padding-right: 0
  .yy-layer-list-wrapper
    height: calc(100% - 104px - 48px - 2px)
    margin-right: -16px
    @media screen and (max-height: 212px)
      height: 200px
    .yy-layer-list
      margin-right: 16px
      color: $gray-color5
      font-size: 12px
      &-item
        padding: 0 4px 0 0!important
        border-radius: 8px
        min-height: 0
        position: relative
        &:before
          position: absolute
          content: ' '
          width: 8px
          left: 0
          top: 0
          bottom: 0
          background-color: $main-color4
          border-radius: 8px 0 0 8px
        &:hover
          background-color: $dark-color7!important
          .yy-layer-name
            color: $gray-color2
          .yy-layer-icon
            visibility: visible
        .yy-layer-icon
          width: 12px
          height: 44px
          margin-left: 13px
          visibility: hidden
        .yy-layer-wrapper
          padding: 4px 0 6px 15px
          flex: 1
          .yy-layer-label
            line-height: 20px
          .yy-layer-name
            font-size: 14px
            line-height: 24px
          .yy-set-wrapper
            margin-top: 6px
            .yy-set-box
              margin-left: -6px
              border: 1px dashed $gray-color5
              width: 160px
              padding: 6px

              border-radius: 6px
              cursor: pointer

              .yy-set-icon
                width: 24px
                height: 24px
                margin-right: 10px
              label
                line-height: 24px
                cursor: pointer
              &:hover
                border: 1px solid $main-color3
                label
                  color: $gray-color2
            .yy-oper-btn
              margin-top: 0

      .yy-layer-add-box
        margin: 4px
        border: 1px dashed $gray-color5
        height: 88px
        line-height: 88px
        font-size: 14px
        text-align: center
        border-radius: 4px
        cursor: pointer
        &:hover
          border-color: $main-color3
          color: $main-color3
      .yy-new-layer
        background-color: $dark-color7!important
        .yy-layer-icon
          visibility: visible
        .yy-input-wrapper
          font-weight: 400
          label
            font-size: 12px
            line-height: 20px
            padding: 0
          input
            background-color: $dark-color6
            font-size: 14px
            width: 230px
            height: 24px
            margin-left: -4px
            padding: 0 4px
        .yy-set-box
          border-style: solid!important
</style>
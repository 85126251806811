import { computed } from "vue";
import _ from 'lodash'
export default function useUserInfoRules (model, pass1Ref) {
  function validateEmail (email) {
    return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)
  }
  function emailRulesFunc (desc) {
    return [
      (val) => !!val || `${desc}不能为空`,
      (val) =>
        validateEmail(val) ||
        "请输入正确的邮箱地址",
    ]
  };
  const passRules = [(val) => !!val || "登录密码不能为空"];
  const smsCodeRules = [(val) => !!val || "验证码不能为空"];
  function pass1RulesFunc (desc) {
    return computed(() => {
      console.log("pass1 rules computed", model);
      return [
        (val) => !!val || `${desc}不能为空`,
        (val) =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,10}$/.test(val) ||
          "密码须包含大小写字母和数字，长度8到10位",
      ];
    })
  };
  function pass2RulesFunc (desc) {
    return computed(() => {

      return [
        (val) => {
          console.log("pass2 rules computed", _.isEmpty(model.password1), !pass1Ref, !pass1Ref.value, !!val)
          return (_.isEmpty(model.password1) || !pass1Ref || !pass1Ref.value.validate() || !!val) || `请重输${desc}`
        },
        (val) => (!pass1Ref || !pass1Ref.value.validate() && _.isEmpty(val) || val == model.password1) || "两次输入的密码不相同",
      ];
    })
  };
  const nickNameRules = [(val) => /^[\u4e00-\u9fa5A-Za-z0-9-_]*$/.test(val) || "昵称只能包含中英文、数字、下划线、减号"];
  return {
    validateEmail,
    emailRulesFunc,
    passRules,
    pass1RulesFunc,
    pass2RulesFunc,
    smsCodeRules,
    nickNameRules
  }
}
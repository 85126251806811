import { fetchDatasets, delDataset, addDataset, fetchDatasetByTableName } from "@api/dataset-api"
import Notify from 'quasar/src/plugins/Notify.js';
import GeoJSONSchema from "../../public/json-schema/yunyu/YunyuGeoJSON"
import Ajv from "ajv";
export default function useDatasetOperations (confirm, datasets) {

  const getDatasets = async () => {
    const result = await fetchDatasets()
    datasets.value = result.records
    console.log('fetch dataset', datasets.value)
  }

  const deleteDataset = (index) => {
    console.log('deleteDatabase', index)
    confirm.value = {
      show: true,
      question: "从云端数据中删除该数据？",
      description: "该数据将立即被删除，此操作无法撤销。",
      cancelText: "取消",
      okText: "删除",
      callback: () => {
        deleteDatasetHandler(index)
      }
    }
  }

  const deleteDatasetHandler = async (index) => {
    console.log('delete dataset ', datasets.value[index].id)
    let id = datasets.value[index].id
    await delDataset(id)
    Notify.create({
      message: `删除数据集“${datasets.value[index].datasetName}”成功`,
      color: "positive",
    })
    getDatasets()
  }

  const uploadDataset = async (parameter, success, fail) => {
    try {
      console.log('upload dataset ', parameter)
      await addDataset(parameter)
      Notify.create({
        message: `上传数据集“${parameter.datasetName}”成功`,
        color: "positive",
      })
      if (typeof success == 'function') {
        success()
      }
    } catch (e) {
      if (typeof fail == 'function') {
        fail()
      }
    }
  }

  const validateGeoJson = (geoJson) => {
    console.log("geojson:", geoJson)
    console.log("GeoJSONSchema", GeoJSONSchema)

    const options = {}; // 具体的配置
    const ajv = new Ajv(options); // 某些情况下，需要改为 new Ajv.default()

    // 开启校验
    const isValid = ajv.validate(GeoJSONSchema, geoJson); // schemas 具体配置，data数据
    if (!isValid) {
      console.log(ajv.errorsText())
      Notify.create({
        message: "文件包含的内容不是正确的或指定的GeoJSON格式，目前仅支持点（Point）数据，其中属性部分必须包含name属性"

      })
      return false
    }
    return true
  }

  const getDatasetByTableName = async (tableName, callback) => {
    console.log('getDatasetByTableName', tableName)
    const result = await fetchDatasetByTableName(tableName)
    console.log("getDatasetByTableName result", result)
    if (typeof callback == "function") {
      callback()
    }
  }

  return {
    datasets,
    getDatasets,
    deleteDataset,
    uploadDataset,
    validateGeoJson,
    getDatasetByTableName
  }
}
<template>
  <div class="column items-stretch">
    <BoardHead
      title="重置密码"
      @go-back="$emit('changeStatus', 'Login')"
    ></BoardHead>
    <q-form class="self-center" @submit.prevent.stop="resetPass">
      <div class="yy-main-box column items-center self-center">
        <div class="yy-title q-mb-lg">使用邮箱重置密码</div>
        <q-input
          class="yy-input-wrapper"
          outlined
          clearable
          dense
          autofocus
          hide-bottom-space
          color="true-main-color3"
          bg-color="gray-color3"
          type="email"
          maxlength="50"
          v-model="model.email"
          placeholder="邮箱"
          input-class="yy-input"
          :rules="emailRules"
          lazy-rules
          @update:model-value="updateEmail"
          @blur="emailBlur = true"
          ref="emailRef"
        />
        <div class="row item-start justify-between yy-code-wrapper">
          <q-input
            class="yy-input-wrapper"
            outlined
            clearable
            dense
            hide-bottom-space
            color="true-main-color3"
            bg-color="gray-color3"
            type="text"
            maxlength="20"
            v-model="model.smsCode"
            placeholder="验证码"
            input-class="yy-input"
            :rules="smsCodeRules"
          />
          <q-btn
            class="yy-code-btn"
            unelevated
            color="main-color3"
            :label="codeBtnLabel"
            :disable="codeBtnDisable"
            :loading="codeDoing"
            @click="getVeriCode"
          />
        </div>
        <q-input
          class="yy-input-wrapper"
          outlined
          dense
          clearable
          hide-bottom-space
          color="true-main-color3"
          bg-color="gray-color3"
          :type="isPwd1 ? 'password' : 'text'"
          maxlength="10"
          v-model="model.password1"
          placeholder="设置密码"
          input-class="yy-input"
          :rules="pass1Rules"
          lazy-rules
          reactive-rules
          ref="pass1Ref"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd1 ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd1 = !isPwd1"
            />
          </template>
        </q-input>
        <q-input
          class="yy-input-wrapper"
          outlined
          dense
          clearable
          hide-bottom-space
          color="true-main-color3"
          bg-color="gray-color3"
          :type="isPwd2 ? 'password' : 'text'"
          maxlength="10"
          v-model="model.password2"
          placeholder="重输密码"
          input-class="yy-input"
          :rules="pass2Rules"
          lazy-rules
          reactive-rules
          ref="pass2Ref"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd2 ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd2 = !isPwd2"
            />
          </template>
        </q-input>
        <q-btn
          class="yy-btn"
          unelevated
          color="main-color3"
          label="提交"
          type="submit"
          :loading="doing"
          :disable="doing"
        />
      </div>
    </q-form>
  </div>
</template>
<script>
import BoardHead from "@components/drawer/BoardHead";
import useUserInfoRules from "@composables/useUserInfoRules";
import usePasswordWatch from "@composables/usePasswordWatch";
import { ref, reactive } from "vue";
import { emailVeriCode, resetPassword } from "@api/auth-api";
import { VeriCodeBtnMixin } from "@mixins/VeriCodeBtnMixin"
const WTIMES = 60
export default {
  name: "ResetPass",
  emits: ["changeStatus"],
  mixins: [VeriCodeBtnMixin],
  setup () {
    const emailRef = ref(null);
    const pass1Ref = ref(null);
    const pass2Ref = ref(null);
    const model = reactive({
      email: "",
      smsCode: "",
      password1: "",
      password2: "",
    });
    const { validateEmail, emailRulesFunc, smsCodeRules, pass1RulesFunc, pass2RulesFunc } =
      useUserInfoRules(model, pass1Ref);
    const emailRules = emailRulesFunc("邮箱地址")
    const pass1Rules = pass1RulesFunc("新密码")
    const pass2Rules = pass2RulesFunc("新密码")
    usePasswordWatch(model, pass2Ref)

    /*   const $q = useQuasar()
      $q.notify.setDefaults({
        
      }) */
    return {
      validateEmail,
      emailRules,
      smsCodeRules,
      emailRef,
      pass1Rules,
      pass2Rules,
      model,
      pass1Ref,
      pass2Ref,

    };
  },
  data () {
    return {
      doing: false,
      done: false,
      isPwd1: true,
      isPwd2: true,
      codeBtnDisable: true,
      emailBlur: false,
    };
  },
  components: {
    BoardHead,
  },
  created () {
    this.getVeriCode = this.__.debounce(this.getVeriCodeHandler, 500);
    this.resetPass = this.__.debounce(this.resetPassHandler, 500);
  },
  methods: {
    updateEmail (value) {
      if (!this.__.isEmpty(value) && this.countTime == WTIMES && this.validateEmail(value)) {
        this.codeBtnDisable = false

      } else {
        this.codeBtnDisable = true
      }
    },
    getVeriCodeHandler () {
      this.codeDoing = true
      const params = {
        email: this.model.email
      };
      emailVeriCode(params)
        .then(() => {
          this.$q.notify({
            message: "验证码已经发送到指定的电子邮箱中。",
            caption: "验证码发送成功",
            color: "primary"
          })
          this.codeBtnDisable = true
          this.countDown()
        })
        .catch(() => {
          this.codeBtnDisable = false
        })
        .finally(() => {
          this.codeDoing = false
        })

    },
    resetPassHandler () {
      this.doing = true
      const params = {
        email: this.model.email,
        password: this.model.password2,
        vericode: this.model.smsCode
      }
      resetPassword(params).then(() => {
        this.$q.notify({
          message: "重置密码成功。",
          color: "positive",
          actions: [
            { label: '前往登录', color: 'white', handler: () => { this.$emit('changeStatus', ['Login', this.model.email]) } }
          ]
        })
      }).finally(() => {
        this.doing = false
      })
    },
  },
};
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/drawer.auth.sass"
.yy-title
  margin-bottom: 26px
/** .yy-btn
 margin-top: 10px!important **/
/** .yy-code-btn
 height: 36px!important
 min-height: 36px!important
 width: 86px
 font-size: 12px **/

.yy-other-way
  &:last-child
    margin-top: 6px
</style>
<style lang="sass">
@import "@sass/quasar.sass"
@import "@sass/drawer.auth.g.sass"
</style>

<template>
  <transition name="slide">
    <keep-alive :exclude="excludeNames">
      <component
        :is="status"
        :profileStatus="profileStatus"
        :userId="userId"
        :fileName="fileName"
        :fileTitle="fileTitle"
        :backStatus="backStatus"
        @change-status="changeStatus"
        v-bind="$attrs"
      ></component>
    </keep-alive>
  </transition>
</template>
<script>
import Profile from "@components/drawer/mainboard/Profile";
import Login from "@components/drawer/mainboard/Login";
import Register from "@components/drawer/mainboard/Register";
import ResetPass from "@components/drawer/mainboard/ResetPass";
import Avatar from "@components/drawer/mainboard/Avatar";
import UserEdit from "@components/drawer/mainboard/UserEdit";
import Policy from "@components/drawer/mainboard/Policy";
import Feedback from "@components/drawer/mainboard/Feedback";
import Setting from "@components/drawer/mainboard/Setting";
export default {
  name: "MainBoard",
  props: {
    portionName: {
      type: String,
      default: ""
    }
  },

  data () {
    return {
      status: "Profile",
      profileStatus: "Guest",
      userId: "",
      fileName: "privacy",
      backStatus: "Profile",
      fileTitle: "",
      excludeAll: ["Profile", "Login", "Register", "ResetPass", "Avatar", "UserEdit", "Policy", "Feedback", "Setting"],
      excludeNames: ""
    };
  },
  components: {
    Profile,
    Login,
    Register,
    ResetPass,
    Avatar,
    UserEdit,
    Policy,
    Feedback,
    Setting
  },

  created () {
    if (
      this.$route.redirectedFrom &&
      this.$route.redirectedFrom.path.includes("activelogin") || this.portionName == "Login"
    ) {
      this.status = "Login";
    }
    this.excludeNames = this.excludeAll
  },
  methods: {
    changeStatus (status) {
      console.log("mainboard get status:", status);
      if (!(this.status == "Register" && status[0] && status[0] == "Policy") && !(this.status = "Policy" && status == "Register")) {
        this.excludeNames = this.excludeAll
      }
      if (status == "Guest" || status == "User") {
        this.status = "Profile";
        this.profileStatus = status;
      } else if (status[0] && status[0] == "Login") {
        this.status = status[0]
        this.userId = status[1] || ""

      } else if (status == "Register") {
        this.status = status
        this.excludeNames = this.excludeAll.filter(function (val) {
          return val != status
        })
      }
      else if (status[0] && status[0] == "Policy") {
        this.status = "Policy"
        this.fileName = status[1]
        this.backStatus = status[2]
        this.fileTitle = status[3]

      }
      else this.status = status;
      console.log("this.excludeNames:", this.excludeNames);
    },
  },
};
</script>
<style lang="sass" scoped>
@import "@sass/transition.sass"
@import "@sass/drawer.board.sass"
$m-left: 36px
.yy-head
  height: 80px
  Font-family: Roboto
  font-weight: 300
  Font-size: 36px
  Line-height: 80px
  color: $gray-color1

.yy-profile
  margin-top: 24px
  margin-left: $m-left
  img
    width: 48px
    height: 48px
  .yy-info
    margin-left: 12px
    font-size: 18px
    font-weight: 400
    color: $gray-color2
    .yy-name
      line-height: 32px
    .yy-intro
      font-size: 12px
      line-height: 16px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
.yy-btn-wrapper
  margin: 30px 0 18px $m-left
  flex: 1
  .yy-btn
    width: 48px!important
    height: 24px!important
    background-color: $dark-color3
    color: $btn-color1
    border-radius: 8px
    font-size: 12px
    &:not(:first-child)
      margin-left: 12px
.yy-info-wrapper
  margin: 18px 0 18px $m-left
  .yy-info
    display: flex
    font-size: 12px
    font-weight: 400
    color: $gray-color1
    align-items: center
    &:hover
      cursor: pointer
    &:not(:first-child)
      margin-top: 14px
    @mixin info-before($img-data)
      &::before
        display: inline-block
        content: " "
        width: 18px
        height: 18px
        margin-right: 8px
        background: left / contain no-repeat url($img-data)
    &-about
      @include info-before("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.5' y='.5' width='17' height='17' rx='8.5' stroke='%2385969F'/%3E%3Cpath d='M10.148 14h-2.31V6.604h2.31V14zM7.701 4.696c0-.328.119-.597.356-.806.237-.21.544-.315.922-.315.379 0 .686.105.923.315.237.21.356.478.356.806 0 .328-.119.597-.356.807-.237.21-.544.314-.923.314-.378 0-.685-.104-.922-.314a1.028 1.028 0 0 1-.356-.807z' fill='%2385969F'/%3E%3C/svg%3E")
    &-feedback
      @include info-before("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.5' y='4.5' width='14' height='11' rx='1.5' stroke='%2385969F'/%3E%3Cpath d='M3 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2' stroke='%2385969F'/%3E%3C/svg%3E")
    &-contact
      @include info-before("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.5' y='2.5' width='17' height='13' rx='1.5' stroke='%2385969F'/%3E%3Cpath d='M1 3l8 5 8-5' stroke='%2385969F'/%3E%3C/svg%3E")
    &-setting
      @include info-before("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.789 16.294L.577 9 4.79 1.706h8.422L17.423 9l-4.212 7.294H4.79z' stroke='%2385969F'/%3E%3Ccircle cx='9' cy='9' r='3.5' stroke='%2385969F'/%3E%3C/svg%3E")
.yy-other
  margin: 18px 0 75px $m-left
  font-size: 12px
  font-weight: 400
  color: $dark-color4
  .yy-rule-wrapper
    .yy-rule
      &:not(:first-child)
        margin-left: 8px
      &:hover
        cursor: pointer
</style>

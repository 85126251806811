<template>
  <div class="column items-stretch">
    <BoardHead
      title="修改个人信息"
      @go-back="$emit('changeStatus', 'Profile')"
    ></BoardHead>
    <div ref="mainBox" class="yy-main-box column items-center self-stretch">
      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="true-main-color3"
        indicator-color="true-main-color3"
        align="justify"
        narrow-indicator
      >
        <q-tab name="info" label="基本信息" />
        <q-tab name="pass" label="登录密码" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated class="bg-dark-color1 yy-tab-panels">
        <q-tab-panel name="info">
          <q-form class="items-center" @submit.prevent.stop="updateInfo">
            <q-input
              class="yy-input-wrapper"
              outlined
              dense
              hide-bottom-space
              color="true-main-color3"
              bg-color="gray-color3"
              label-color="main-color3"
              type="text"
              maxlength="50"
              v-model="model.name"
              label="账号"
              input-class="yy-edit-input text-center"
              readonly
            />
            <q-input
              class="yy-input-wrapper"
              outlined
              clearable
              dense
              autofocus
              hide-bottom-space
              label-color="main-color3"
              color="true-main-color3"
              bg-color="gray-color3"
              type="text"
              maxlength="10"
              v-model="model.nickName"
              label="昵称"
              input-class="yy-edit-input text-center"
              :rules="nickNameRules"
              lazy-rules
            />
            <q-input
              class="yy-input-wrapper"
              outlined
              clearable
              dense
              hide-bottom-space
              autogrow
              label-color="main-color3"
              color="true-main-color3"
              bg-color="gray-color3"
              type="text"
              maxlength="20"
              v-model="model.bio"
              label="个性签名"
              input-class="yy-edit-textarea text-center"
            />
            <q-input
              class="yy-input-wrapper"
              outlined
              clearable
              dense
              hide-bottom-space
              label-color="main-color3"
              color="true-main-color3"
              bg-color="gray-color3"
              type="email"
              maxlength="50"
              v-model="model.email"
              label="邮箱"
              input-class="yy-edit-input text-center"
              :rules="emailRules"
              lazy-rules
              @update:model-value="updateEmail"
              ref="emailRef"
            />
            <div
              v-if="codeVisuable"
              class="row item-start justify-between yy-code-wrapper"
            >
              <q-input
                class="yy-input-wrapper"
                outlined
                clearable
                dense
                hide-bottom-space
                color="true-main-color3"
                bg-color="gray-color3"
                type="text"
                maxlength="20"
                v-model="model.smsCode"
                placeholder="验证码"
                input-class="yy-input"
                :rules="smsCodeRules"
              />
              <q-btn
                class="yy-code-btn"
                unelevated
                color="main-color3"
                :label="codeBtnLabel"
                :disable="codeBtnDisable"
                :loading="codeDoing"
                @click="getVeriCode"
                type="button"
              />
            </div>

            <q-btn
              class="yy-btn"
              unelevated
              color="main-color3"
              label="提交"
              type="submit"
              :loading="doing"
              :disable="doing || !changed"
            />
          </q-form>
        </q-tab-panel>

        <q-tab-panel name="pass">
          <q-form class="items-center" @submit.prevent.stop="changePass">
            <q-input
              class="yy-input-wrapper"
              outlined
              dense
              clearable
              autofocus
              hide-bottom-space
              color="true-main-color3"
              bg-color="gray-color3"
              label-color="main-color3"
              :type="isPwd ? 'password' : 'text'"
              maxlength="10"
              v-model="model.password"
              label="当前密码"
              input-class="yy-edit-input text-center"
              :rules="passRules"
              lazy-rules
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
            <q-input
              class="yy-input-wrapper"
              outlined
              dense
              clearable
              hide-bottom-space
              color="true-main-color3"
              bg-color="gray-color3"
              label-color="main-color3"
              :type="isPwd1 ? 'password' : 'text'"
              maxlength="10"
              v-model="model.password1"
              label="新密码"
              input-class="yy-edit-input text-center"
              :rules="pass1Rules"
              lazy-rules
              reactive-rules
              ref="pass1Ref"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd1 ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd1 = !isPwd1"
                />
              </template>
            </q-input>
            <q-input
              class="yy-input-wrapper"
              outlined
              dense
              clearable
              hide-bottom-space
              color="true-main-color3"
              bg-color="gray-color3"
              label-color="main-color3"
              :type="isPwd2 ? 'password' : 'text'"
              maxlength="10"
              v-model="model.password2"
              label="重输新密码"
              input-class="yy-edit-input text-center"
              :rules="pass2Rules"
              lazy-rules
              reactive-rules
              ref="pass2Ref"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd2 ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd2 = !isPwd2"
                />
              </template>
            </q-input>
            <q-btn
              class="yy-btn"
              unelevated
              color="main-color3"
              label="提交"
              type="submit"
              :loading="doingPass"
              :disable="doingPass"
            />
          </q-form>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>
<script>
import BoardHead from "@components/drawer/BoardHead";
import { VeriCodeBtnMixin } from "@mixins/VeriCodeBtnMixin"
import { ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import useUserInfoRules from "@composables/useUserInfoRules";
import usePasswordWatch from "@composables/usePasswordWatch";
import { emailVeriCode2New, userEdit, changePassword } from "@api/account-api";

export default {
  name: "UserEdit",
  emits: ["changeStatus"],
  mixins: [VeriCodeBtnMixin],
  setup () {
    const emailRef = ref(null);
    const pass1Ref = ref(null);
    const pass2Ref = ref(null);
    const codeVisuable = ref(false)
    const changed = ref(false)
    const store = useStore();
    const model = reactive({
      name: store.state.userName,
      email: store.state.user.email,
      nickName: store.state.user.nickname,
      bio: store.state.userBio,
      smsCode: "",
      password: "",
      password1: "",
      password2: ""
    });
    const { validateEmail, emailRulesFunc, smsCodeRules, nickNameRules, pass1RulesFunc, pass2RulesFunc } =
      useUserInfoRules(model, pass1Ref);
    const emailRules = emailRulesFunc("新邮箱地址")
    const pass1Rules = pass1RulesFunc("新密码")
    const pass2Rules = pass2RulesFunc("新密码")
    const passRules = [(val) => !!val || "当前密码不能为空"];
    usePasswordWatch(model, pass2Ref)

    const checkModel = function () {
      console.log("store state", store.state.user)
      if (model.email != store.state.user.email || model.nickName != store.state.user.nickname || model.bio != store.state.userBio) {
        changed.value = true
      }
      else {
        changed.value = false
      }
      console.log('model change', changed)
    }
    watch(
      () => model.nickName,
      () => {
        console.log('watch nickName', model)
        checkModel()
      })
    watch(
      () => model.bio,
      () => {
        console.log('watch bio', model)
        checkModel()
      })

    watch(
      () => model.email,
      () => {
        console.log('watch email', model)
        checkModel()
      })

    return {
      model,
      validateEmail,
      emailRules,
      smsCodeRules,
      emailRef,
      passRules,
      pass1Rules,
      pass2Rules,
      nickNameRules,
      pass1Ref,
      pass2Ref,
      codeVisuable,
      changed
    }
  },
  components: {
    BoardHead,

  },
  data () {
    return {
      doing: false,
      tab: 'info',
      isPwd: true,
      isPwd1: true,
      isPwd2: true,
      doingPass: false
    }
  },
  created () {
    this.getVeriCode = this.__.debounce(this.getVeriCodeHandler, 500);
    this.updateInfo = this.__.debounce(this.updateInfoHandler, 500);
    this.changePass = this.__.debounce(this.changePassHandler, 500);
  },
  mounted () {
    this.$nextTick(function () {
      var elements = document.getElementsByClassName('yy-main-box')[0].getElementsByClassName('q-tab__label');
      for (var i = 0, len = elements.length | 0; i < len; i = i + 1 | 0) {
        elements[i].onmousedown = function (event) {
          event.preventDefault();
        };
      }

    })
  },
  beforeUnmount () {
    try {
      var elements = document.getElementsByClassName('yy-main-box')[0].getElementsByClassName('q-tab__label');
      /* let mainBox = this.$refs.mainBox
      console.log('unmounted mainBox', mainBox) */

      for (var i = 0, len = elements.length | 0; i < len; i = i + 1 | 0) {
        elements[i].onmousedown = '';
      }
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    updateEmail (value) {
      console.log('update email', value, this.$store)
      if (!this.__.isEmpty(value) && this.validateEmail(value) && value != this.$store.state.user.email) {
        this.codeVisuable = true
        this.codeBtnDisable = false
      } else {
        this.codeVisuable = false
      }
    },
    getVeriCodeHandler () {
      this.codeDoing = true
      const params = {
        email: this.model.email
      };
      emailVeriCode2New(params)
        .then(() => {
          this.$q.notify({
            message: "验证码已经发送到新输入的电子邮箱中。",
            caption: "验证码发送成功",
            color: "primary"
          })
          this.codeBtnDisable = true
          this.countDown()
        })
        .catch(() => {
          this.codeBtnDisable = false
        })
        .finally(() => {
          this.codeDoing = false
        })
    },
    updateInfoHandler () {
      this.doing = true
      const params = {
        id: this.$store.state.user.id,
        email: this.model.email,
        nickname: this.model.nickName,
        bio: this.model.bio,
        vericode: this.model.smsCode
      }
      userEdit(params).then(() => {
        this.$q.notify({
          message: "修改基本信息成功。",
          color: "positive"
        })
        this.$store.dispatch("updateUser", this.model)
        this.codeVisuable = false
        this.changed = false
      }).finally(() => {
        this.doing = false
      })
    },
    changePassHandler () {
      this.doingPass = true
      const params = {
        id: this.$store.state.user.id,
        password: this.model.password,
        newPassword: this.model.password1
      }
      changePassword(params).then(() => {
        this.$store.dispatch("removeUser")
        this.$q.notify({
          message: "修改密码成功，需要重新登录。",
          color: "positive",
          actions: [
            { label: '前往登录', color: 'white', handler: () => { this.$emit('changeStatus', ['Login', this.model.name]) } }
          ]
        })
      }).finally(() => {
        this.doingPass = false
      })
    },

  }
}
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/drawer.auth.sass"
.yy-tab-panels
  height: 460px
</style>



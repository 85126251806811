import { watch, nextTick, onMounted } from 'vue'
export default function useTextareaHandler (textareaRef, textareaContent) {
  onMounted(() => {
    nextTick(adjustTextareaSize);
  });

  watch(textareaContent, () => {
    console.log('watch textareaContent', textareaContent)
    nextTick(adjustTextareaSize)
  })

  // 参考vant组件库中的field组件
  const adjustTextareaSize = () => {
    let textarea = textareaRef.value

    textarea.style.height = 'auto';
    const height = textarea.scrollHeight + 10;

    if (height) { // 改变textarea高度达到自适应
      textarea.style.height = height + "px";
    }
  }
}
import "./styles/quasar.sass";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";
import "@quasar/extras/material-icons-sharp/material-icons-sharp.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import "@quasar/extras/ionicons-v4/ionicons-v4.css";
import "@quasar/extras/mdi-v4/mdi-v4.css";
import "@quasar/extras/eva-icons/eva-icons.css";
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
// To be used on app.use(Quasar, { ... })
export default {
  config: {
    notify: {
      caption: "系统提示",
      message: "系统提示信息",
      color: 'negative',
      position: "top-left",
      icon: 'report_problem',
      multiLine: true,
      actions: [
        { label: '关闭', color: 'white', handler: () => { /* ... */ } }
      ]
    }
  },
  plugins: { Notify, Dialog },
};

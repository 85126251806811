<template>
  <div v-if="selectedChannel" class="yy-main-area column items-stretch">
    <div
      class="yy-info-head-wrapper"
      v-bind:style="{
        'background-image': 'url(' + selectedChannel.coverUrl + ')',
      }"
    >
      <BoardHead
        title="频道探索"
        allTransparent
        :darkMode="false"
        @go-back="closeBoard"
      ></BoardHead>
      <div class="yy-selected-panel">
        <SelectedPanel
          :title="selectedChannel.channelName"
          :intro="selectedChannel.channelIntro"
          @refreshData="refreshData"
          bgTransparent
        ></SelectedPanel>
      </div>
    </div>
    <div class="yy-box-wrapper">
      <div class="yy-channel-info column no-wrap items-stretch">
        <div class="yy-manager row items-end">
          <q-btn
            round
            unelevated
            v-for="item in managerList"
            :key="item.userName"
            padding="0"
          >
            <q-tooltip
              class="yy-avatar-tip"
              anchor="top middle"
              self="bottom middle"
              :offset="[0, 0]"
              transition-show="slide-up"
              transition-hide="slide-down"
              >{{ item.nickName || item.userName }}</q-tooltip
            >
            <q-avatar>
              <img :src="item.avatar" />
            </q-avatar>
          </q-btn>
        </div>

        <div flat class="yy-line"></div>

        <div class="yy-list-head row justify-between items-center">
          <div class="yy-label">频道内容</div>
          <div class="yy-list-sort">
            <span :class="{ 'yy-current-sort': sortType == 0 }">最新</span
            ><span>热点</span><span>推荐</span>
          </div>
        </div>

        <q-virtual-scroll
          v-if="allInfoList.length > 0"
          class="yy-update-list-wrapper"
          :items="allInfoList"
        >
          <template v-slot="{ item }">
            <q-item
              :key="item.id"
              clickable
              v-ripple
              class="yy-update-list-item"
              @click="selectChannelItem(item)"
            >
              <q-item-section top avatar class="yy-item-icon">
                <img :src="iconBase64.get('channelListPoint')" />
              </q-item-section>

              <q-item-section>
                <q-item-label :lines="1" class="yy-main-label">{{
                  item.dataName
                }}</q-item-label>
                <q-item-label :lines="1" class="yy-sub-label"
                  ><span>{{
                    item.updateTime == null
                      ? item.createTime.substring(0, 10)
                      : item.updateTime.substring(0, 10)
                  }}</span>
                  <span>{{ item.views }} 次浏览</span>
                </q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-virtual-scroll>
        <div v-else class="yy-tips">该频道暂无共享数据</div>
      </div>
    </div>
    <q-inner-loading
      :showing="loadingVisible"
      label="正在刷新数据，请稍等..."
      label-class="text-true-main-color3"
      label-style="font-size: 1.1em"
    />
  </div>
  <div v-else>
    <BoardHead
      title="频道探索"
      :darkMode="true"
      @go-back="closeBoard"
    ></BoardHead>
    <div class="yy-tips-wrapper">
      <div class="yy-tips">暂无共享频道数据</div>
    </div>
  </div>
</template>
<script>
import BoardHead from "@components/drawer/BoardHead";
import SelectedPanel from "@components/drawer/channel/SelectedPanel"
import { useStore } from "vuex";
import useDrawerMaterial from "@composables/useDrawerMaterial";
import { ref, computed, watch } from "vue"
import { getSharedChannelById, getSharedChannelData } from "@api/channel-api"
import { getUserNameAvatar } from "@api/account-api"
import useChannelOperations from "@composables/useChannelOperations.js"
export default {
  name: "ChannelInfoWithHead",
  emits: ["closeBoard"],
  setup () {
    const store = useStore();
    const selectedChannel = ref(store.getters.selectedChannel)
    const { iconBase64 } = useDrawerMaterial();
    const channelInfoList = ref(store.getters.channelInfoList)
    const { channelHandler } = useChannelOperations()
    const allInfoList = computed(() => {
      let list = []
      channelInfoList.value.forEach(element => {
        list.push.apply(list, element)
      });
      return list
    })

    watch(() => store.getters.selectedChannel, (newValue) => {
      selectedChannel.value = newValue
    })

    return {
      selectedChannel,
      iconBase64,
      channelInfoList,
      allInfoList,
      channelHandler
    }
  },
  data () {
    return {
      name: "前线",
      sortType: 0,
      managerList: [
        { userName: "朱朝阳", avatar: "/img/temp-zcy.png" },
        { userName: "数学老师", avatar: "/img/temp-m.png" },
        { userName: "女孩", avatar: "/img/temp-g.png" },
        { userName: "母亲", avatar: "/img/temp-p.png" },
      ],
      current: 1,
      searchKey: "",
      loadingVisible: false
    };
  },
  inject: ["boardWidth"],
  components: {
    SelectedPanel,
    BoardHead
  },
  computed: {
    boardMaxWidth () {
      return { "max-width": `${this.boardWidth.value}px` };
    },
  },
  mounted () {
    if (this.selectedChannel && this.selectedChannel.owner) {
      this.managerList.unshift(this.selectedChannel.owner)
    }
  },
  created () {
    this.refreshData = this.__.debounce(this.refreshDataHandler, 500);
  },
  methods: {
    login () {
      this.$emit("changeStatus", "User");
    },
    selectChannelItem (item) {
      console.log("emit selectChannelItem event")
      this.$emitter.emit('selectChannelItemEvent', { feature: item, owner: this.selectedChannel.owner })
    },
    closeBoard () {
      this.$emitter.emit('toggleDrawerEvent', 0)
      this.$emit('closeBoard')
    },
    async refreshDataHandler () {
      try {
        console.log("refresh channel data")
        this.loadingVisible = true
        let id = this.selectedChannel.id
        let channel = await getSharedChannelById({ id })
        let infoList = []
        if (channel) {
          let owner = await getUserNameAvatar({ userName: channel.createBy })
          channel.owner = owner
          infoList = await getSharedChannelData({ id })
        }
        channel = this.channelHandler(channel)
        this.selectedChannel = channel
        this.channelInfoList = infoList
        this.$q.notify({
          message: "频道数据刷新完成。",
          color: "positive"
        })
      } catch (err) {
        console.log('refresh channel data fail', err)
      } finally {
        this.loadingVisible = false
      }
    }
  },
};
</script>
<style lang="sass">
@import '@sass/quasar.variables.sass'
.yy-channel-info
  .yy-update-list
    &-item
      padding: 9px 8px
      &:hover,
      &:focus
        background-color: $dark-color6!important
        .q-focus-helper
          opacity: 0!important
</style>
<style lang="sass" scoped>
@import "@sass/quasar.sass"
@import "@sass/drawer.board.sass"
@import "@sass/common.my.sass"
$info-header-height: calc(#{$drawer-board-header-height} + 104px)
$top-height: calc(#{$info-header-height} + 46px + 2px + 48px )
$media-max-height: calc(#{$top-height} + 50px)
.yy-main-area
  width: 100%
  min-width: $drawer-width
  .yy-info-head-wrapper
    padding-bottom: 32px
    background: url(/img/mls.jpg) no-repeat center/cover
    .yy-selected-panel
      margin-top: 8px
      padding: 0 $drawer-xpadding
  .yy-box-wrapper
    height: calc(100vh - #{$info-header-height})
    padding: 0 $drawer-xpadding
    white-space: break-spaces
    .yy-channel-info
      height: 100%
      @media screen and (max-height: $media-max-height)
        height: auto

      .yy-manager
        padding-left: 40px
        margin: 5px 0
        .q-btn
          padding: 0 2px!important
          .q-avatar
            width: 24px
            height: 24px
          &:first-child
            padding: 0 6px 0 0
            .q-avatar
              width: 36px
              height: 36px
        .yy-avatar-tip
          background-color: $gray-color3
          font-size: 12px!important
          color: $gray-color2

      .yy-list-head
        margin: 8px 0
        padding-left: 40px
        color: $gray-color5
        .yy-label, .yy-list-sort
          font-size: 14px
          line-height: 32px
        .yy-label
          color: $gray-color5
        .yy-list-sort
          color: $main-color3
          .yy-current-sort
            color: $gray-color2
            cursor: auto
          span
            display: inline-block
            padding: 0 5px
            line-height: 14px
            cursor: pointer
            &:not(:first-child)
              border-left: 1px solid $main-color3
              margin-left: 0
            &:last-child
              padding-right: 0
      .yy-update-list-wrapper
        margin-right: 0 - $drawer-xpadding
        padding-right: 0 - $drawer-xpadding
        height: calc(100vh - #{$top-height})
        @include scrollBarStyle

        @media screen and (max-height: $media-max-height)
          height: 200px
        .yy-update-list

          &-item
            padding: 9px 0 9px 8px
            .yy-item-icon
              align-items: start
              min-width: 32px!important
              img
                width: 24px!important
                height: 30px!important
            .yy-main-label
              color: $gray-color5
              font-size: 14px
              width: 230px
              line-height: 14px!important
            .yy-sub-label
              color: $gray-color5
              font-size: 12px
              line-height: 12px!important
              span:not(:first-child)
                margin-left: 30px
  .yy-tips
    color: $gray-color5
</style>

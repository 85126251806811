<template>
  <div class="column items-stretch">
    <BoardHead
      title="注册"
      @go-back="$emit('changeStatus', backStatus)"
    ></BoardHead>
    <q-form v-if="!done" class="self-center" @submit.prevent.stop="register">
      <div class="yy-main-box column items-center self-center">
        <div class="yy-title">使用电子邮箱注册</div>
        <q-input
          class="yy-input-wrapper"
          outlined
          dense
          autofocus
          clearable
          hide-bottom-space
          color="true-main-color3"
          bg-color="gray-color3"
          type="email"
          maxlength="50"
          v-model="model.email"
          placeholder="邮箱地址"
          input-class="yy-input"
          :rules="emailRules"
          lazy-rules
        />
        <q-input
          class="yy-input-wrapper"
          outlined
          dense
          clearable
          hide-bottom-space
          color="true-main-color3"
          bg-color="gray-color3"
          :type="isPwd1 ? 'password' : 'text'"
          maxlength="10"
          v-model="model.password1"
          placeholder="设置密码"
          input-class="yy-input"
          :rules="pass1Rules"
          lazy-rules
          reactive-rules
          ref="pass1Ref"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd1 ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd1 = !isPwd1"
            />
          </template>
        </q-input>
        <q-input
          class="yy-input-wrapper"
          outlined
          dense
          clearable
          hide-bottom-space
          color="true-main-color3"
          bg-color="gray-color3"
          :type="isPwd2 ? 'password' : 'text'"
          maxlength="10"
          v-model="model.password2"
          placeholder="重输密码"
          input-class="yy-input"
          :rules="pass2Rules"
          lazy-rules
          reactive-rules
          ref="pass2Ref"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd2 ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd2 = !isPwd2"
            />
          </template>
        </q-input>
        <q-btn
          class="yy-btn"
          unelevated
          color="main-color3"
          label="立即注册"
          type="submit"
          :loading="doing"
          :disable="doing"
        />
        <div class="yy-other-way">
          注册即同意<span
            @mousedown.prevent="
              $emit('changeStatus', ['Policy', 'agreement', 'Register'])
            "
            >《用户协议》</span
          >
        </div>
        <div class="yy-other-way">
          <span @mousedown.prevent="changeStatus('Login')">已有账号</span
          ><span>|</span
          ><span
            @mousedown.prevent="
              $emit('changeStatus', ['Policy', 'contact', 'Register'])
            "
            >不能注册</span
          >
        </div>
      </div>
    </q-form>
    <div v-else class="column items-center self-center yy-success-text">
      <h6>注册成功！</h6>
      <div class="yy-notice">
        感谢您的注册，您的注册账号是：{{
          model.email
        }}，系统已向该邮箱地址发送了一封邮件，请访问该邮箱，点击所收到邮件中的激活链接来激活您的注册账号，激活链接有效期为24小时。
      </div>
    </div>
  </div>
</template>
<script>
import BoardHead from "@components/drawer/BoardHead";
import { ref, reactive } from "vue";
import { register } from "@api/auth-api";
import useUserInfoRules from "@composables/useUserInfoRules";
import usePasswordWatch from "@composables/usePasswordWatch";
export default {
  name: "Register",
  emits: ["changeStatus"],
  setup () {
    const pass1Ref = ref(null);
    const pass2Ref = ref(null);
    const model = reactive({
      email: "",
      password1: "",
      password2: "",
    });
    const { emailRulesFunc, pass1RulesFunc, pass2RulesFunc } =
      useUserInfoRules(model, pass1Ref);
    const emailRules = emailRulesFunc("注册账号")
    const pass1Rules = pass1RulesFunc("注册密码")
    const pass2Rules = pass2RulesFunc("注册密码")
    usePasswordWatch(model, pass2Ref)
    return {
      emailRules,
      pass1Rules,
      pass2Rules,
      model,
      pass1Ref,
      pass2Ref,
    };
  },
  components: {
    BoardHead,
  },
  data () {
    return {
      doing: false,
      done: false,
      backStatus: "Guest",
      isPwd1: true,
      isPwd2: true
    };
  },
  created () {
    this.register = this.__.debounce(this.registerHandler, 500);
  },
  methods: {
    registerHandler () {
      this.doing = true;
      const params = {
        email: this.model.email,
        password: this.model.password1,
      };
      register(params)
        .then(() => {
          this.done = true;
          this.backStatus = "Login";
        })
        .finally(() => {
          this.doing = false;
        });
    },

    changeStatus (status) {
      console.log("changeStatus");
      this.$emit("changeStatus", status);
    },

  },
};
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/drawer.auth.sass"
.yy-title
  margin-bottom: 26px
// .yy-btn
//   margin-top: 12px!important
.yy-other-way
  &:last-child
    margin-top: 6px
.yy-success-text
  //width: $drawer-width - 14px - 14px
  padding: 0 14px
  color: $gray-color1
  font-size: 12px
  .yy-notice
    width: 100%
    word-wrap: break-word
    white-space: pre-wrap
</style>
<style lang="sass">
@import "@sass/quasar.sass"
@import "@sass/drawer.auth.g.sass"
</style>

<template>
  <div
    class="yy-channel-selected row justify-between"
    :class="{ 'yy-bg-transparent': bgTransparent }"
  >
    <div class="yy-channel-selected-info column">
      <div
        class="yy-channel-selected-info-name"
        :style="{ fontSize: selectNameFontSize + 'rem' }"
      >
        {{ title }}
      </div>
      <div class="yy-channel-selected-info-intro">
        {{ intro }}
      </div>
    </div>
    <div class="yy-channel-selected-tool column justify-between">
      <q-btn flat round class="yy-channel-selected-tool-btn">
        <q-icon
          size="1.125rem"
          :name="'img:' + iconBase64.get('channelFavorite')"
        />
      </q-btn>
      <q-btn
        flat
        round
        class="yy-channel-selected-tool-btn"
        @click="$emit('refreshData')"
      >
        <q-icon size="1rem" :name="'img:' + iconBase64.get('channelRefresh')" />
      </q-btn>
    </div>
  </div>
</template>
<script>
import useDrawerMaterial from "@composables/useDrawerMaterial";
export default {
  name: "SelectedChannelPanel",
  emits: ["refreshData"],
  props: {
    title: { type: String, default: "频道标题" },
    intro: { type: String, default: "频道简介" },
    bgTransparent: { type: Boolean, default: false }
  },
  setup () {
    const { iconBase64 } = useDrawerMaterial();
    return {
      iconBase64
    };
  },
  computed: {
    selectNameFontSize () {
      if (this.getRealLength(this.title) > 19) {
        return 1.125
      }
      else if (this.getRealLength(this.title) > 15) {
        return 1.25
      }
      else {
        return 1.5
      }
    }
  },
  methods: {

    getRealLength (str) {
      var realLength = 0, len = str.length, charCode = -1;
      for (var i = 0; i < len; i++) {
        charCode = str.charCodeAt(i);
        if (charCode >= 0 && charCode <= 128)          //重点
          realLength += 1;
        else
          realLength += 2;
      }
      return realLength;
    },
  }
}
</script>
<style lang="sass" scoped>
@import '@sass/quasar.variables.sass'
.yy-channel-selected
  padding: 6px
  border-radius: 4px
  background-color: $back-color1
  &-info
    padding-left: 34px
    color: $gray-color2
    width: 232px
    white-space: nowrap

    &-name
      width: 100%
      font-weight: 400
      font-size: 24px
      line-height: 36px
      text-overflow: ellipsis
      overflow: hidden
    &-intro
      width: 100%
      font-size: 12px
      line-height: 16px
      text-overflow: ellipsis
      overflow: hidden
  &-tool
    &-btn
      min-width: 20px
      min-height: 20px
      width: 22px
      height: 22px
.yy-bg-transparent
  background-color: transparent
</style>
<template>
  <q-dialog class="yy-confirm-dialog" v-model="showDialog" persistent>
    <q-card>
      <q-card-section class="yy-confirm-question">
        <div>{{ confirm.question }}</div>
      </q-card-section>

      <q-card-section class="yy-confirm-description">
        {{ confirm.description }}
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          flat
          :label="confirm.cancelText"
          v-close-popup
          @click="cancelHandler"
          class="yy-q-btn"
        />
        <q-btn
          flat
          :label="confirm.okText"
          v-close-popup
          @click="confirmHandler"
          class="yy-q-btn"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, watch, toRefs } from 'vue'
export default {
  name: "DialogConfirm",
  emits: ["clickOk", "clickCancel"],
  props: {
    confirm: {
      type: Object,
      default: () => {
        return {
          show: false,
          question: "需要确认的问题",
          description: "对问题的详细描述",
          okText: "确定",
          cancelText: "取消"
        }
      }
    }
  },
  setup (props) {
    const { confirm } = toRefs(props)
    console.log('props confirm', confirm.show)
    const showDialog = ref(false)

    watch(
      confirm,
      (value) => {
        console.log('watch confirm', value)
        showDialog.value = value.show
      }
    )
    return {
      showDialog
    }
  },

  methods: {
    confirmHandler () {
      console.log("confirm emit click ok")
      this.$emit('clickOk')
    },
    cancelHandler () {
      this.$emit('clickCancel')
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@sass/common.edit.sass"
.yy-confirm-dialog
  .q-card
    width: 448px
    min-height: 160px
    border-radius: 16px
    background-color: $dark-color5
    color: $gray-color2
    padding: 16px 40px 8px
    overflow: hidden
    .yy-confirm-question
      font-size: 18px
      line-height: 24px
      padding: 6px 0
    .yy-confirm-description
      font-size: 14px
      line-height: 18px
      padding: 9px 0
    .q-card__actions
      margin-top: 12px
</style>
<template>
  <div class="row">
    <q-btn
      v-for="(item, index) in btnList"
      :key="item.key"
      unelevated
      round
      color="dark-color5"
      class="yy-oper-btn"
      @mouseenter="lightIcon(index, true)"
      @mouseleave="lightIcon(index, false)"
      @click.stop="clickBtn(index)"
      ><q-icon class="yy-oper-btn-icon" :name="'img:' + item.icon"></q-icon>
      <q-tooltip
        v-if="item.title"
        class="yy-avatar-tip"
        anchor="top middle"
        self="bottom middle"
        :offset="[0, 0]"
        transition-show="slide-down"
        transition-hide="slide-up"
        >{{ item.title }}</q-tooltip
      >
    </q-btn>
  </div>
</template>
<script>
import useDrawerMaterial from "@composables/useDrawerMaterial";
import { reactive, watchEffect } from "vue"
export default {
  name: "OperBtnGroup",
  emits: ["doOperation"],
  props: {
    btnGroup: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const { iconBase64 } = useDrawerMaterial();

    /* const btnList = reactive(props.btnGroup.map(value => {
      if (value instanceof Array) {
        return {
          name: value[0],
          icon: iconBase64.get(value[0]),
          iconLight: iconBase64.get(value[1])
        }
      } else {
        return {
          name: value,
          icon: iconBase64.get(value)
        }
      }

    })) */
    const btnList = reactive([])
    watchEffect(() => {
      console.log('btn group watcheffect', props.btnGroup)
      btnList.length = 0
      props.btnGroup.forEach(value => {
        let key = value
        let name = value
        let icon = null
        let iconLight = null
        let title = null
        if (value instanceof Array && value.length == 0) {
          return
        }
        if (value instanceof Array && value.length > 0) {
          name = value[0]
          icon = iconBase64.get(name)
          if (value.length == 2)
            title = value[1]
          if (value.length > 2) {
            iconLight = iconBase64.get(value[1])
            title = value[2]
          }
        }
        else {
          icon = iconBase64.get(value)
        }
        btnList.push({
          key,
          name,
          icon,
          iconLight,
          title
        })
      })
    })
    return { btnList, iconBase64 }
  },
  methods: {
    lightIcon (index, lightOn) {
      console.log('light icon', index, lightOn)
      let btn = this.btnList[index]
      btn.icon = lightOn ? (btn.iconLight ? btn.iconLight : this.iconBase64.get(btn.name + 'Light')) : this.iconBase64.get(btn.name)
    },
    clickBtn (btnIndex) {
      this.$emit('doOperation', btnIndex)
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@sass/common.edit.sass"
.yy-oper-btn
  margin-top: 0!important
</style>
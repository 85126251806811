<template>
  <p
    class="yy-copyright row justify-center items-center"
    :class="{ 'yy-copyright-left': left }"
  >
    ©{{ beginYear }}{{ thisYear }}&nbsp;&nbsp;云舆科技&nbsp;&nbsp;
    <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
      >粤ICP备14056492号</a
    >
    <a
      target="_blank"
      href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010802000896"
      class="yy-icon-gongan"
      ><img src="@img/icon-gongan.png" />
      <p class="yy-num-gongan">琼公网安备 46010802000896号</p></a
    >
  </p>
</template>
<script>
export default {
  name: "Copyright",
  props: {
    left: { type: Boolean, default: false }
  },
  data () {
    return {
      thisYear: "",
      beginYear: 2021,
    };
  },

  mounted () {
    let year = new Date().getFullYear();
    this.thisYear = year > this.beginYear ? "-" + year : "";
  },
}
</script>
<style lang="sass" scoped>
@import "@sass/quasar.variables.sass"
.yy-copyright
  position: fixed
  bottom: 0
  margin: 0 auto 4px
  color: #fff
  font-size: 12px
  a
    color: #fff
    text-decoration: none
  .yy-icon-gongan
    margin-left: 14px
    img
      float: left
      width: 18px
      height: 18px
    .yy-num-gongan
      float: left
      height: 20px
      line-height: 20px
      margin: 0px 0px 0px 5px
      color: #939393
.yy-copyright-left
  position: absolute
  color: $dark-color4
  a
    color: $dark-color4
  .yy-icon-gongan
    margin-left: 0
    .yy-num-gongan
      color: $dark-color4
</style>
import { postAction, getAction, deleteAction/* , putAction, baseAction  */ } from '@utils/request'

const pageSize = 1000

/**
 * 获取数据集列表
 */
export function fetchDatasets () {
  return getAction(`/yunyu/yunyuDataset/list?column=createTime&order=desc`, { pageNo: 1, pageSize })
}

/**
 * 设置数据集的删除标志
 */
export function delDataset (parameter) {
  return deleteAction(`/yunyu/yunyuDataset/delete?id=${parameter}`)
}

/**
 * 上传数据集
 */
export function addDataset (parameter) {
  return postAction(`/yunyu/yunyuDataset/add`, parameter)
}

/**
 * 获取某个数据集内容
 */
export function fetchDatasetByTableName (tableName) {
  return getAction(`/yunyu/yunyuDatasetTemplate/list`, { tableName })
}
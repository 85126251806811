<template>
  <div v-if="loading" class="yy-loading">
    <div class="yy-spinner-box column items-center">
      <div class="yy-circle-wrapper">
        <div class="yy-circle-border">
          <div class="yy-circle-core"></div>
        </div>
        <div class="yy-big-logo"></div>
      </div>
      <div class="yy-banner">YUNYU</div>
    </div>
  </div>
  <Layout v-else :initSuccess="initSuccess"></Layout>
</template>
<script>
import { ref } from "vue";
import Layout from "@layout/Layout05";
import { useStorage } from "vue3-storage-secure";
import { useStore } from "vuex";
import { ST_USER_INFO, ST_ACCESS_TOKEN, ST_SELECTED_MAP, ST_SELECTED_CHANNEL } from "@config/constant"
import { getUserRoles } from "@api/account-api"
import useChannelOperations from "@composables/useChannelOperations"
export default {
  name: "App",
  components: {
    Layout,
  },
  setup () {
    let { refreshSharedChannels } = useChannelOperations()
    const loading = ref(true);
    const initSuccess = ref(false);
    console.log("app setup");
    const storage = useStorage();
    const store = useStore();

    let doing = true
    let delay = 3000
    const showLoading = function () {
      if (doing) {
        setTimeout(showLoading, delay)
        delay = 100
      } else {
        loading.value = false
      }
    }
    showLoading();

    (async () => {
      //console.log("s.hasKey", storage.hasKey(ST_ACCESS_TOKEN))
      //console.log("s.expire", storage.isExpire(ST_ACCESS_TOKEN))
      if (
        storage.hasKey(ST_ACCESS_TOKEN) &&
        !storage.isExpire(ST_ACCESS_TOKEN)
      ) {
        const userInfo = storage.getSecureStorageSync(ST_USER_INFO);
        console.log("userInfo", userInfo);
        await store.dispatch("setUser", userInfo);
      }
      const selectedMap = storage.getSecureStorageSync(ST_SELECTED_MAP)
      await store.dispatch("setSelectedMap", selectedMap)

      const selectedChannel = storage.getSecureStorageSync(ST_SELECTED_CHANNEL)
      await store.dispatch("setSelectedChannel", selectedChannel)

      refreshSharedChannels(async () => {
        initSuccess.value = true
        try {
          if (store.getters.isAuthenticated) {
            let res = await getUserRoles({ username: store.getters.userName })
            console.log('getUserRoles', res)
            store.dispatch('updateUserRoles', res)
          }
        } catch (e) {
          console.log('getUserRoles error:', e)
        } finally {
          doing = false
        }
      }, () => { doing = false })

    })();

    return {
      loading,
      initSuccess
    };
  },

  data () {
    return {

    };
  },

};
</script>
<style lang="sass" scoped>
@import "@sass/quasar.sass"

@keyframes spin
  from
    transform: rotate(0)
  to
    transform: rotate(359deg)
.yy-loading
  position: relative
  height: 100vh
  width: 100%
  background-color: $dark-color1
  .yy-spinner-box
    @include pos-center
    width: 300px
    height: 300px
    display: flex
    justify-content: center
    align-items: center
    background-color: transparent
    .yy-circle-wrapper
      position: relative
      .yy-circle-border
        width: 134px
        height: 134px
        padding: 3px
        display: flex
        justify-content: center
        align-items: center
        border-radius: 50%
        background: rgb(63,249,220)
        //background: linear-gradient(0deg, #4894BF 33%, rgba(72, 148, 191, 0) 100%,)
        //background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%)
        //background: conic-gradient(#FFF, 0.5%, #4894BF 0.5%, #282A2F 100%)

        background: conic-gradient(#FFF, 0.5%, $dark-color1 0.5%, #4894BF 100%)
        animation: spin 2.8s linear 0s infinite
        .yy-circle-core
          width: 100%
          height: 100%
          background-color: $dark-color2
          border-radius: 50%

      .yy-big-logo
        @include pos-center
        width: 108px
        height: 108px
        border-radius: 50%
        background: $dark-color2 center center/cover no-repeat url("~@img/logo-big.svg")
  .yy-banner
    background: Linear-Gradient(to bottom right,#E5E5E5 20%,rgba(229, 229, 229, 0))
    -webkit-background-clip: text
    color: transparent
    font-size: 48px
    font-weight: 700
  .yy-copyright
    position: fixed
    bottom: 4px
    color: #fff
    font-size: 12px
    left: 50%
    transform: translateX(-50%)
    a
      color: #fff
      text-decoration: none
</style>

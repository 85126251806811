import Point from '../Point.js';

import BoundingBox from '../ref/BoundingBox.js';

export default {
  title: 'GeoJSON Feature',
  type: 'object',
  required: ['type', 'properties', 'geometry'],
  properties: {
    type: {
      type: 'string',
      enum: ['Feature']
    },
    id: {
      oneOf: [{ type: 'number' }, { type: 'string' }]
    },
    properties: {
      //oneOf: [{ type: 'null' }, { type: 'object' }]
      type: 'object',
      required: ['name'],
      properties: {
        name: { type: 'string' },
      }
    },
    geometry: {
      oneOf: [
        { type: 'null' },
        Point

      ]
    },
    bbox: BoundingBox
  }
};


const WTIMES = 60
export const VeriCodeBtnMixin = {
  data () {
    return {
      countTime: WTIMES,
      codeBtnLabel: "获取验证码",
      codeDoing: false,
      codeBtnDisable: true
    }
  },
  watch: {
    countTime (newValue) {
      console.log('watch')
      if (newValue == WTIMES) {
        this.codeBtnLabel = "获取验证码"
      } else {
        this.codeBtnLabel = this.countTime + " 秒"
      }
    }
  },
  methods: {
    countDown () {
      let that = this
      let interval = window.setInterval(() => {
        if (that.countTime-- <= 0) {
          that.countTime = WTIMES;
          window.clearInterval(interval);

          if (that.validateEmail(that.model.email)) {
            that.codeBtnDisable = false
          }
        }
      }, 1000);
    },
  }
}
<template>
  <div class="column items-stretch">
    <BoardHead
      title="重设头像"
      @go-back="$emit('changeStatus', 'Profile')"
    ></BoardHead>

    <div class="yy-main-box yy-avatar column items-center self-center">
      <q-avatar v-if="userAvatar"> <img :src="userAvatar" /></q-avatar>
      <q-avatar v-else color="gray-color3" text-color="white"
        >{{ firstChar }}
      </q-avatar>

      <div id="preview" class="yy-title q-mb-lg">
        {{ previewing ? '预览头像' : '当前头像' }}
      </div>
      <div v-if="pic != ''" class="yy-cropper-wrapper">
        <!-- 图片裁切插件 -->
        <VuePictureCropper
          :boxStyle="{
            width: '100%',
            height: '100%',
            backgroundColor: '#f8f8f8',
            margin: 'auto',
          }"
          :img="pic"
          :options="{
            viewMode: 1,
            dragMode: 'move',
            aspectRatio: 1,
            cropBoxResizable: false,
          }"
          :presetMode="{
            mode: 'fixedSize',
            width: 100,
            height: 100,
          }"
          @crop="previewUpdate"
        />
        <!-- 图片裁切插件 -->
      </div>
      <input
        type="file"
        id="uploads"
        class="yy-img-file"
        accept="image/png, image/jpeg, image/gif, image/jpg"
        @change="selectFile"
        ref="uploadImg"
      />
      <div class="yy-btn-wrapper row no-wrap">
        <q-btn flat label="选择图片" class="yy-btn" @click="selectPicture" />
        <q-btn
          flat
          label="预览头像"
          class="yy-btn"
          :disable="commitBtnDisable"
          @click="previewPicture"
        />
        <q-btn
          flat
          label="确定修改"
          class="yy-btn"
          :disable="commitBtnDisable"
          :loading="doing"
          @click="commitPicture"
        />
      </div>
    </div>
  </div>
</template>
<script>
import BoardHead from "@components/drawer/BoardHead";
import { useStore } from "vuex";
import VuePictureCropper, { cropper } from 'vue-picture-cropper'
import { reactive, ref } from 'vue'
import { uploadAvatar } from "@api/account-api";
export default ({
  name: "Avatar",
  emits: ["changeStatus"],
  setup () {
    const store = useStore();
    const uploadImg = ref(null)
    const pic = ref('')
    const userAvatar = ref('')
    const userId = ref('')
    const result = reactive({
      dataURL: '',
      blobURL: '',
      blob: null,
      blob1: null
    })
    /**
    * 获取裁切结果
    */
    const getResult = async (callback) => {
      // console.log(cropper)
      // 获取生成的base64图片地址
      const base64 = cropper.getDataURL()
      // 获取生成的blob文件信息
      const blob = await cropper.getBlob()
      result.blob = blob

      // 获取生成的file文件信息
      const file = await cropper.getFile()
      console.log({ base64, blob, file })
      // 把base64赋给结果展示区
      result.dataURL = base64
      try {
        result.blobURL = URL.createObjectURL(blob)
      } catch (e) {
        result.blobURL = ''
      }
      console.log({ result })
      if (callback instanceof Function) callback()
    }
    userAvatar.value = store.state.userAvatar
    userId.value = store.state.user.id
    return {
      firstChar: store.state.userName ? store.state.userName.charAt(0) : "",
      userAvatar,
      userId,
      uploadImg,
      pic,
      result,
      getResult,
      store
    }
  },
  components: {
    BoardHead,
    VuePictureCropper
  },
  data () {
    return {
      commitBtnDisable: true,
      previewing: false,
      doing: false
    }
  },
  created () {
    this.commitPicture = this.__.debounce(this.uploadAvatarHandler, 500);
  },
  methods: {
    selectPicture () {
      document.getElementById("uploads").click();
    },
    selectFile (e) {
      //上传图片
      // this.option.img
      var file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        this.$q.notify({
          message: "图片类型必须是.gif、jpeg、jpg、png、bmp中的一种"
        })

        return false;
      }
      var reader = new FileReader();
      reader.onload = e => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.pic = data;
        this.$refs.uploadImg.value = ''
        this.commitBtnDisable = false
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file);
    },
    // 预览头像
    previewPicture () {
      this.getResult(() => {
        this.userAvatar = this.result.dataURL
        this.previewing = true
      })
    },
    previewUpdate () {
      if (this.previewing) {
        this.previewPicture()
      }
    },
    //上传头像
    uploadAvatarHandler () {
      this.getResult(() => {
        if (this.__.isEmpty(this.result.blobURL)) {
          this.$q.notify({
            message: "获取图像截取内容失败！请重新选择图片并进行截取操作。",
            caption: "系统提示",
          })
          return
        }
        this.doing = true
        const userInfo = {
          id: this.userId,
          fileName: this.userId + ".png"
        };

        (async () => {
          try {
            const res = await uploadAvatar(this.result.blob, userInfo)
            this.userAvatar = process.env.VUE_APP_API_BASE_STATIC_URL + res.avatarPath
            this.previewing = false
            await this.store.dispatch("updateUserAvatar", res.avatarPath);
            this.$q.notify({
              message: "头像更新成功！",
              color: "primary"
            })
          } finally {
            this.doing = false
          }
        })();

      })
    }
  }
})
</script>
<style lang="sass" scoped>
@import "@sass/drawer.auth.sass"
@import "@sass/drawer.board.sass"
.yy-avatar
  margin-top: 40px
  .yy-title
    margin-top: 4px
  .yy-cropper-wrapper
    height: 230px
    width: 230px
  .yy-img-file
    position: absolute
    clip-path: circle(0)
  .yy-btn-wrapper
    margin-top: 4px
</style>

import { createRouter, createWebHistory } from "vue-router";
import Launch from "@views/Launch.vue";
const routes = [
  {
    path: "/",
    name: "Launch",
    component: Launch,
    redirect: "/home",
    children: [
      {
        path: "/home",
        alias: '/',
        name: "Home",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "home" */ "@views/Home.vue"),
      },

    ]
  },
  { path: '/activelogin/:email', redirect: '/' },
  {
    path: "/active/:activecode",
    name: "Active",
    component: () =>
      import(/* webpackChunkName: "active" */ "@views/Active.vue"),
  },

  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: { title: '404-页面不存在' },
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import { axios } from './axios-plus'
import signMd5Utils from './signMd5Utils'
import Notify from 'quasar/src/plugins/Notify.js';
export function baseAction (url, parameter, method, headers = {}, notifyErr = true) {
  console.log('baseAction param', parameter)
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: method,
      ...parameter,
      headers: headers
    }).then(res => {
      resolve(res)
    }).catch(err => {
      if (notifyErr) {
        Notify.create({
          message: err.errObj.description,
          caption: err.errObj.message,
        })
      }
      reject(err.errObj)
    })
  })

}

export function httpAction (url, parameter, method, notifyErr = true) {
  let sign = signMd5Utils.getSign(url, parameter);
  //将签名和时间戳，添加在请求接口 Header
  let signHeader = { "X-Sign": sign, "X-TIMESTAMP": signMd5Utils.getDateTimeToString() };
  return baseAction(url, parameter, method, signHeader, notifyErr)
}

//post
export function postAction (url, parameter, notifyErr = true) {
  return httpAction(url, { data: parameter }, "post", notifyErr)
}

//get
export function getAction (url, parameter) {
  return httpAction(url, { params: parameter }, "get")
}

//put
export function putAction (url, parameter) {
  return baseAction(url, { data: parameter }, 'put')
}

//deleteAction
export function deleteAction (url, parameter) {
  return baseAction(url, { params: parameter }, 'delete')
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile (url, parameter) {
  return axios({
    url: url,
    params: parameter,
    method: 'get',
    responseType: 'blob'
  })
}

/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
export function downloadFile (url, fileName, parameter) {
  return downFile(url, parameter).then((data) => {
    if (!data || data.size === 0) {
      throw ('文件下载失败')
    }
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(new Blob([data]), fileName)
    } else {
      let url = window.URL.createObjectURL(new Blob([data]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) //下载完成移除元素
      window.URL.revokeObjectURL(url) //释放掉blob对象
    }
  })
}

/**
 * 文件上传 用于富文本上传图片
 * @param url
 * @param parameter
 * @returns {*}
 */
export function uploadAction (url, parameter) {
  return axios({
    url: url,
    data: parameter,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',  // 文件上传
    },
  })
}



<template>
  <div class="yy-search-wrapper row items-center">
    <q-icon
      class="yy-search-icon"
      :name="'img:' + iconBase64.get('channelSearch')"
    />
    <input class="yy-search-box" v-model="searchKey" placeholder="频道内搜索" />
    <button label="搜索" class="yy-search-btn">搜索</button>
  </div>
</template>
<script>
import useDrawerMaterial from "@composables/useDrawerMaterial";
export default {
  name: "SearchBox",
  setup () {
    const { iconBase64 } = useDrawerMaterial();
    return {
      iconBase64,
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@sass/quasar.sass"
.yy-search-wrapper
  padding: 0 7px
  background-color: $dark-color6
  border-radius: 16px
  font-size: 14px
  .yy-search-icon
    width: 17px
    height: 20px
    padding-top: 4px
  .yy-search-box
    height: 32px
    margin-left: 15px
    background-color: $dark-color6
    border: none
    outline: medium
    color: $gray-color5
    flex: auto
    // &:-webkit-input-placeholder,
    // &:-moz-placeholder,
    // &:-ms-input-placeholder
    @include input-placeholder
      color: $gray-color3
  .yy-search-btn
    background-color: $dark-color6
    border: none
    color: $gray-color5
    width: 48px
    &:hover
      cursor: pointer
</style>
<template>
  <div class="yy-set-list-wrapper">
    <div class="yy-set-list-head">
      <div class="yy-head-title">云端</div>
    </div>
    <div
      v-for="item in datasets"
      :key="item.id"
      class="yy-set-list-item row items-center"
      @click.stop="$emit('selectDataset', item)"
    >
      <q-icon
        class="yy-set-icon"
        :name="'img:' + iconBase64.get('dataSetLight')"
      ></q-icon>
      <div>{{ item.datasetName }}</div>
    </div>
    <template v-if="datasets.length < 0">
      <div
        v-for="n in 10 - datasets.length"
        :key="n"
        class="yy-set-list-placeholder"
      ></div>
    </template>
  </div>
</template>
<script>
import useDrawerMaterial from "@composables/useDrawerMaterial";
export default {
  name: "DatasetList",
  emits: ["selectDataset"],
  props: {
    datasets: {
      type: Array,
      required: true
    }
  },
  setup () {
    const { iconBase64 } = useDrawerMaterial();
    return { iconBase64 }
  },
  methods: {
    selectDataset () {

    }
  }
}
</script>
<style lang="sass" scoped>
@import "@sass/quasar.variables.sass"
.yy-set-list-wrapper
  position: absolute
  top: 100%
  left: 0
  z-index: 100
  background-color: $dark-color6
  border-radius: 8px
  width: 270px
  color: white
  padding-bottom: 4px
  .yy-set-list-head, .yy-set-list-item, .yy-set-list-placeholder
    font-size: 14px
    font-weight: 400
    width: 100%
    border-bottom: 1px solid $dark-color7
  .yy-set-list-head
    padding: 4px
    .yy-head-title
      margin-left: 72px
      width: 32px
      text-align: center
      color: $gray-color2
      line-height: 26px
      border-bottom: 2px solid $main-color3
  .yy-set-list-item
    padding: 5px 4px
    cursor: pointer
    .yy-set-icon
      width: 24px
      height: 24px
      margin: 0 4px
  .yy-set-list-placeholder
    height: 34px
</style>
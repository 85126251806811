import { getAction, postAction, putAction, baseAction, deleteAction } from '@utils/request'

const pageSize = 1000

export function getChannelList (parameter) {
  return getAction(`/yunyu/yunyuMapChannel/list`, { pageNo: 1, pageSize, ...parameter })
}

export function getChannelInfoList (channel) {
  console.log("getChannelInfoList api params:", channel)
  return getAction(`/yunyu/yunyuChannel${channel.channelCode.replace(/^\S/, s => s.toUpperCase())}/list`, { pageNo: 1, pageSize })
}

export function getMyChannelList (parameter) {
  return getAction(`/yunyu/yunyuMapChannel/myChannelList`, parameter)
}

/* 添加频道 */
export function addChannel (coverFile, fileName, channelInfo) {
  let formData = new window.FormData();

  formData.append("channel", new Blob([JSON.stringify(channelInfo)], { type: "application/json" }));
  formData.append('coverFile', coverFile, fileName);

  let headers = {
    'Content-Type': 'multipart/form-data'
  }
  for (var [a, b] of formData.entries()) {
    console.log("formdata.entries", a, b);
  }
  return baseAction('/yunyu/yunyuMapChannel/add', { data: formData }, 'post', headers)
}

/* 只更新频道文字信息 */
export function updateChannelInfo (channelInfo) {
  return updateChannel(null, '', channelInfo)
}

/* 更新频道 */
export function updateChannel (coverFile, fileName, channelInfo) {
  let formData = new window.FormData();

  formData.append("channel", new Blob([JSON.stringify(channelInfo)], { type: "application/json" }));
  let content = coverFile
  if (!coverFile) content = new Blob([])
  formData.append('coverFile', content, fileName)

  let headers = {
    'Content-Type': 'multipart/form-data'
  }
  for (var [a, b] of formData.entries()) {
    console.log("formdata.entries", a, b);
  }
  return baseAction('/yunyu/yunyuMapChannel/edit', { data: formData }, 'put', headers)
}


/*删除频道*/
export function deleteChannel (parameter) {
  return deleteAction(`/yunyu/yunyuMapChannel/delete`, parameter)
}

/*获取频道的数据集*/
export function getChannelDatasetList (parameter) {
  return getAction(`/yunyu/yunyuChannelDataset/channelDatasetList`, parameter)
}

/*更新频道的数据集*/
export function updateChannelDataset (parameter) {
  return putAction(`/yunyu/yunyuChannelDataset/edit`, parameter)
}

/*批量更新频道的数据集*/
export function updateChannelDatasets (parameter) {
  return postAction(`/yunyu/yunyuChannelDataset/editBatch`, parameter)
}

/*删除频道数据集*/
export function deleteChannelDataset (parameter) {
  return deleteAction(`/yunyu/yunyuChannelDataset/delete`, parameter)
}


/*获取频道的所有数据*/
export function getChannelData (parameter) {
  return getAction(`/yunyu/yunyuChannelDataset/channelDataList`, parameter)
}

/*获取共享频道的所有共享数据*/
export function getSharedChannelData (parameter) {
  return getAction(`/yunyu/yunyuChannelDataset/sharedChannelDataList`, parameter)
}

/*根据频道id获取共享频道信息*/
export function getSharedChannelById (parameter) {
  return getAction(`/yunyu/yunyuMapChannel/querySharedChannelById`, parameter)
}
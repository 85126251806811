<template>
  <div class="yy-my yy-my-database column no-wrap items-stretch">
    <div class="yy-my-tabs row items-center">
      <div
        v-for="(item, index) in tabs"
        :key="item.title"
        class="yy-my-tab column items-center no-wrap"
        :class="{ active: activeTab == index }"
        @click="selectTab(index)"
      >
        <q-icon
          :name="'img:' + (activeTab == index ? item.iconActive : item.icon)"
          class="yy-my-tab-icon"
        ></q-icon>
        <div
          class="yy-my-tab-title"
          :class="{ 'title-active': activeTab == index }"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="yy-my-head row items-center justify-between">
      <div class="yy-my-head-title">数据集</div>
      <q-btn unelevated class="yy-my-head-btn" @click="showUploadDialog = true"
        >上传数据集</q-btn
      >
    </div>
    <div flat class="yy-line"></div>
    <q-scroll-area v-if="datasets.length > 0" class="yy-my-list-wrapper">
      <q-list class="yy-my-list">
        <q-item
          v-ripple
          clickable
          v-for="(item, index) in datasets"
          :key="item.id"
          class="yy-my-item"
          :class="{ active: activeIndex == index }"
          @click="selectItem(index)"
        >
          <q-icon
            class="yy-my-item-icon"
            :name="'img:' + iconBase64.get('dataSetLight')"
          />
          <div class="column yy-my-info">
            <div class="yy-my-title">{{ item.datasetName }}</div>
            <div class="yy-my-intro">创建于 {{ item.createTime }}</div>
          </div>
          <OperBtnGroup
            :btnGroup="btnGroup0"
            @doOperation="operHandler($event, index)"
          ></OperBtnGroup>
        </q-item>
      </q-list>
    </q-scroll-area>
    <div v-else class="yy-my-tips q-mt-lg">您还没有上传任何数据</div>
    <DialogConfirm
      :confirm="confirm"
      @clickOk="confirm.callback"
      @clickCancel="confirm.cancel"
    ></DialogConfirm>
    <UploadDataset
      :show="showUploadDialog"
      @clickClose="showUploadDialog = false"
      @reloadClose="reload"
    ></UploadDataset>
    <ViewDataset
      :show="showViewDialog"
      @clickClose="showViewDialog = false"
      :datasetMeta="dataset4View"
    ></ViewDataset>
  </div>
</template>
<script>
//import { reactive } from "vue"
//import { useStore } from "vuex";
import useDrawerMaterial from "@composables/useDrawerMaterial";
import useDatasetOperations from '@/composables/useDatasetOperations'
import DialogConfirm from '@/components/common/DialogConfirm'
import { ref, reactive, onMounted, inject, watch } from "vue"
import OperBtnGroup from '@/components/common/OperBtnGroup'
import UploadDataset from '@/components/drawer/databoard/UploadDataset'
import ViewDataset from '@/components/drawer/databoard/ViewDataset'

export default {
  name: "MyDatabase",
  emits: ["changeBox"],
  setup () {
    const { iconBase64 } = useDrawerMaterial();
    const tabs = [{
      title: "云端", icon: iconBase64.get("cloud"), iconActive: iconBase64.get("cloudLight"),
    }, {
      title: "本地", icon: iconBase64.get("local"), iconActive: iconBase64.get("localLight")
    }]

    /* const datasets = reactive([{ id: 1, setName: "2018全国马拉松金牌", createTime: "2022-04-20" }, { id: 1, setName: "2019全国马拉松金牌", createTime: "2022-04-20" }, { id: 1, setName: "2020全国马拉松金牌", createTime: "2022-04-20" }, { id: 1, setName: "2021全国马拉松金牌", createTime: "2022-04-20" }]) */
    const confirm = ref({})
    const datasets = ref([])
    const { getDatasets, deleteDataset } = useDatasetOperations(confirm, datasets)
    const emitter = inject('$emitter')
    const dataset4View = ref({})
    const showViewDialog = ref(false)
    const activeItem = ref(null)
    const activeIndex = ref(null)

    const selectItem = function (index, force = false) {
      console.log('select my channel item', index)
      if (activeIndex.value == index && !force) return
      if (index >= 0) {
        activeIndex.value = index
        activeItem.value = datasets.value[index]
      } else {
        activeIndex.value = null
        activeItem.value = null
      }
      emitter.emit('selectDatasetItemEvent', activeItem.value)
    }

    const showInfo = (index) => {
      console.log('showInfo', index, datasets)
      dataset4View.value = datasets.value[index]
      showViewDialog.value = true

    }
    const btnGroup = new Map([[['channelDelete', '删除'], deleteDataset], [['information', '数据列表'], showInfo]])
    const btnGroup0 = reactive([...btnGroup.keys()])

    watch(datasets, (newValue) => {
      console.log('watch dataset List', newValue)
      if (newValue.length > 0) {
        if (activeItem.value == null) {
          selectItem(0, true)
        } else {
          let foundIndex = newValue.findIndex(value => value.id == activeItem.value.id)
          if (foundIndex < 0) {
            selectItem(0, true)
          } else {
            selectItem(foundIndex, true)
          }
        }
      } else (
        selectItem(-1, true)
      )
    }, { immediate: true })

    onMounted(getDatasets)
    return {
      iconBase64,
      tabs,
      datasets,
      getDatasets,
      btnGroup,
      btnGroup0,
      confirm,
      dataset4View,
      showViewDialog,
      activeItem,
      activeIndex,
      selectItem
    };
  },
  components: {
    DialogConfirm,
    OperBtnGroup,
    UploadDataset,
    ViewDataset
  },
  data () {
    return {
      activeTab: 0,
      showUploadDialog: false
    };
  },

  methods: {
    selectTab (index) {
      console.log('select tab', index)
      this.activeTab = index
    },
    operHandler (btnIndex, index) {
      console.log('oper handler', btnIndex, index)
      let btnArr = [...this.btnGroup.values()]
      btnArr[btnIndex](index)
    },
    reload () {
      this.showUploadDialog = false
      this.getDatasets()
    },

  }
};
</script>

<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/quasar.sass"
@import "@sass/common.edit.sass"
@import "@sass/common.my.sass"
.yy-my-database
  .yy-my-tabs
    padding-top: 8px
    .yy-my-tab
      width: 64px
      height: 64px
      border-radius: 6px
      border: 1px dashed $dark-color5
      &.active
        border-color: $gray-color2
      &-icon
        margin-top: 12px
        width: 28px
        height: 28px
      &-title
        margin-top: 0
        width: auto
        line-height: 22px
        border-bottom: none
  .yy-my-list-wrapper
    .yy-my-list
      .yy-my-item
        padding: 6px 6px 5px 8px!important
        border-radius: 4px
        &:hover, &.active
          background-color: $dark-color7!important
          cursor: pointer
          .q-focus-helper
            opacity: 0!important
          .yy-my-title
            color: $gray-color2

        .yy-my-info
          flex: 1
          color: $gray-color5
          font-weight: 400
          margin-left: 8px
          .yy-my-title
            font-size: 14px
            line-height: 18px
            margin-bottom: 3px
          .yy-my-intro
            font-size: 12px
            line-height: 16px

        &-icon
          width: 24px
          height: 24px
  .yy-line
    margin-right: -2px
  .yy-my-tips
    padding-left: 40px
</style>



import { postAction, getAction, putAction, baseAction } from '@utils/request'

export function uploadAvatar (file, userInfo) {
  let formData = new window.FormData();

  formData.append("userInfo", new Blob([JSON.stringify(userInfo)], { type: "application/json" }));
  formData.append('avatarFile', file, userInfo.fileName);

  let headers = {
    'Content-Type': 'multipart/form-data'
  }
  for (var [a, b] of formData.entries()) {
    console.log("formdata.entries", a, b);
  }
  return baseAction('/sys/user/userUploadAvatar', { data: formData }, 'put', headers)
}

/**
 * 修改邮箱邮件发送验证码
 */
export function emailVeriCode2New (parameter) {
  return postAction('/sys/user/emailVeriCode2New', parameter)
}

/**
 * 修改个人资料
 */
export function userEdit (parameter) {
  return putAction('/sys/user/userEdit', parameter)
}

/**
 * 修改登录密码
 */
export function changePassword (parameter) {
  return putAction('/sys/user/userChangePassword', parameter)
}

/**
 * 用户注销账号
 */
export function userDeregister (parameter) {
  return postAction('/sys/user/userDeregister', parameter)
}

/**
 * 用户反馈
 */
export function userFeedback (parameter) {
  return postAction('/yunyu/yunyuFeedback/add', parameter)
}

/**
 * 获取用户角色
 */
export function getUserRoles (parameter) {
  return getAction(`/sys/api/queryUserRoles`, parameter)
}

/**
 * 获取用户名称和头像
 */
export function getUserNameAvatar (parameter) {
  return getAction(`/sys/user/queryNickNameAvatarByUserName`, parameter)
}


<template>
  <div class="yy-search-container row items-center no-wrap">
    <q-input
      v-model="search"
      placeholder="搜索频道"
      class="standout bg-white col yy-search-box"
    />
    <q-btn class="yy-search-btn" icon="search" flat />
  </div>
</template>
<script>
export default {
  name: "Searche4Channel",
};
</script>
<style lang="sass" scoped>
@import "@sass/quasar.sass"
.yy-search-container
  @include drawer-head-box
  padding: 12px 8px
  .yy-search-box
    height: 36px
    width: 180px
    border-radius: 4px
    font-size: 12px
  .yy-search-btn
    padding: 0 0 0 11px
    &::before
      display: none
</style>
<style lang="sass">
@import "@sass/quasar.variables.sass"
.yy-search-container
  .q-field__control
    padding-left: 4px
    height: 36px
    &::before
      border-bottom-color: $gray-color1
    &::after
      background-color: $active-color
  .yy-search-btn
    .q-focus-helper
      width: 0
      height: 0
</style>

<template>
  <div class="column items-stretch">
    <BoardHead
      title="问题反馈"
      @go-back="$emit('changeStatus', 'Profile')"
    ></BoardHead>
    <div class="yy-main-box column items-center self-stretch">
      <q-form class="items-center" @submit.prevent.stop="commitFeedback">
        <q-input
          class="yy-input-wrapper"
          outlined
          clearable
          dense
          hide-bottom-space
          autogrow
          autofocus
          counter
          dark
          label-color="main-color3"
          color="true-main-color3"
          bg-color="gray-color3"
          type="textarea"
          maxlength="500"
          v-model="model.feedback"
          label="内容"
          input-class="yy-edit-textarea"
          :rules="feedbackRules"
          lazy-rules
        />
        <q-btn
          class="yy-btn"
          unelevated
          color="main-color3"
          label="提交"
          type="submit"
          :loading="doing"
          :disable="doing"
        />
      </q-form>
    </div>
  </div>
</template>
<script>
import BoardHead from "@components/drawer/BoardHead";
import { reactive } from "vue";
import { userFeedback } from "@api/account-api";
import _ from 'lodash'
export default {
  name: "Feedback",
  emits: ["changeStatus"],

  setup () {
    const model = reactive({
      feedback: ""
    });
    const feedbackRules = [(val) => !_.isEmpty(val) || "反馈内容不能为空"];

    return {
      model,
      feedbackRules,
    }
  },
  components: {
    BoardHead,
  },
  data () {
    return {
      doing: false,
    }
  },
  created () {
    this.commitFeedback = this.__.debounce(this.commitFeedbackHandler, 500);
  },
  methods: {
    commitFeedbackHandler () {
      this.doing = true
      const params = {
        username: this.$store.state.userName,
        feedback: this.model.feedback,
      }
      userFeedback(params).then(() => {
        this.$q.notify({
          message: "反馈信息提交成功。",
          color: "positive",
          timeout: 2500,
          actions: [
            { label: '返回', color: 'white', handler: () => { this.$emit('changeStatus', 'Profile') } }
          ]
        })
        setTimeout(() => { this.$emit('changeStatus', 'Profile') }, 4000)

      }).finally(() => {
        this.doing = false
      })
    },


  }
}
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/drawer.auth.sass"
</style>
<style lang="sass">
@import "@sass/quasar.variables.sass"
.yy-main-box
  .yy-edit-textarea
    min-height: 160px!important
</style>



<template>
  <div
    class="yy-head-wrapper"
    :class="{ 'yy-all-transparent': allTransparent }"
  >
    <span
      class="yy-head-title"
      :class="{ 'yy-dark-mode': darkMode, 'yy-main-title': mainTitle }"
      >{{ title }}</span
    >
    <q-btn flat class="yy-head-btn" @mousedown.prevent="$emit('goBack')">
      <q-icon class="yy-head-btn-icon" :name="iconData" />
    </q-btn>
  </div>
  <div
    flat
    class="yy-line"
    :class="{ 'yy-line-transparent': allTransparent }"
  ></div>
</template>
<script>
import useDrawerMaterial from "@composables/useDrawerMaterial";
export default {
  name: "BoardHead",
  props: {
    title: {
      type: String,
      default: "默认标题",
    },
    mainTitle: {
      type: Boolean,
      default: false,
    },
    darkMode: {
      type: Boolean,
      default: true,
    },
    allTransparent: { type: Boolean, default: false }
  },
  setup () {
    const { iconBase64 } = useDrawerMaterial();
    return {
      iconBase64
    }
  },
  emits: ["goBack"],
  computed: {
    iconData: function () {
      let buttonData = "img:" + this.iconBase64.get("backButton")
      if (this.darkMode) {
        let darkolor = "6A727C";
        buttonData = buttonData.replace(/E5E5E5/gi, darkolor);
      }
      return buttonData
    },
  },
};
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
.yy-dark-mode
  color: $gray-color5
  font-family: inherit
  font-weight: 400
.yy-line
  margin: 0 $drawer-xpadding
.yy-line-transparent
  border-color: transparent
.yy-main-title
  Font-family: Roboto
  font-weight: 300
  Font-size: 36px

.yy-all-transparent
  .yy-head-title
    color: $gray-color2
  .yy-head-btn
    background-color: transparent
</style>

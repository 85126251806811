<template>
  <div
    v-if="channelList && channelList.length > 0"
    class="yy-channel-board column no-wrap items-stretch"
  >
    <SelectedPanel
      class="yy-selected-panel"
      :title="selectedChannel.channelName"
      :intro="selectedChannel.channelIntro"
      @refreshData="refreshChannels"
    ></SelectedPanel>

    <div flat class="yy-line"></div>
    <q-scroll-area class="yy-channel-list-wrapper">
      <q-list class="yy-channel-list">
        <transition-group name="list" tag="p">
          <template v-for="(item, index) in channelList" :key="item.id">
            <q-item
              :clickable="index != selected"
              v-ripple
              class="yy-channel-list-item"
              :active="index == selected"
              @click="selectChannel(index)"
            >
              <q-item-section class="yy-channel-avatar" avatar top>
                <q-avatar>
                  <q-icon
                    class="yy-channel-icon"
                    size="2.25rem"
                    :name="'img:' + item.avatar"
                  />
                  <!-- <img class="yy-channel-icon" :src="'/img/' + item.avatar" /> -->
                </q-avatar>
              </q-item-section>

              <q-item-section>
                <q-item-label class="yy-channel-title">{{
                  item.channelName
                }}</q-item-label>
                <q-item-label caption :lines="2" class="yy-channel-intro">{{
                  item.channelIntro
                }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </transition-group>
      </q-list>
    </q-scroll-area>
  </div>
  <div v-else class="yy-tips-wrapper">
    <div class="yy-tips">暂无共享频道数据</div>
  </div>
  <q-inner-loading
    :showing="loadingVisible"
    label="正在刷新数据，请稍等..."
    label-class="text-true-main-color3"
    label-style="font-size: 1.1em"
  />
</template>
<script>
import { ref, computed, watch } from "vue"
import { useStore } from "vuex";
import useDrawerMaterial from "@composables/useDrawerMaterial";
import SelectedPanel from "@components/drawer/channel/SelectedPanel"
import useChannelOperations from "@composables/useChannelOperations"
export default {
  name: "ChannelBoard",
  emits: ["closeBoard"],
  setup () {
    const store = useStore();
    const { channelIconBase64, iconBase64 } = useDrawerMaterial();
    const { refreshSharedChannels, getSelectedChannelData } = useChannelOperations()
    const channelList = computed(() => store.getters.channelList)
    const selectedChannel = ref(store.getters.selectedChannel)
    if (selectedChannel.value == null) {
      selectedChannel.value == { channelName: "未选择频道", channelIntro: "未有简介" }
    }
    const selected = ref(channelList.value ? (channelList.value.findIndex(value => value.id == selectedChannel.value.id)) : -1)
    console.log('store channelList', channelList)

    watch(() => store.getters.channelList, (newValue) => {
      if (newValue.length > 0) {
        selectedChannel.value = newValue[selected.value]
      }
    })

    return {
      channelIconBase64,
      iconBase64,
      channelList,
      selectedChannel,
      selected,
      getSelectedChannelData,
      refreshSharedChannels
    };
  },
  components: {
    SelectedPanel
  },
  data () {
    return {
      loadingVisible: false
    };

  },
  created () {
    this.refreshChannels = this.__.debounce(this.refreshChannelsHandler, 500);
  },
  methods: {
    selectChannel (index) {
      if (this.selected == index) return
      this.selectedChannel = this.channelList[index]
      this.selected = index
      this.getSelectedChannelData(this.selectedChannel)
    },
    refreshChannelsHandler () {
      this.loadingVisible = true
      this.refreshSharedChannels(() => {
        this.$q.notify({
          message: "频道列表刷新完成。",
          color: "positive"
        })
      }, null, () => {
        this.loadingVisible = false
      })
    }
  }
};
</script>
<style lang="sass">
@import @sass/transition.sass
@import "@sass/quasar.variables.sass"

.yy-channel-board
  .yy-active-channel
    background-color: $gray-color4
    border: 1px solid $btn-color1
    border-radius: 4px
  .yy-channel-list-wrapper
    .yy-channel-list
      &-item
        &:hover,
        &:focus
          background-color: $dark-color6!important
          .q-focus-helper
            opacity: 0!important
            &::before
              //opacity: 0!important
            &::after
              //opacity: 0!important
</style>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/common.my.sass"
.yy-channel-board
  height: 100%
  @media screen and (max-height: 212px)
    height: auto
  .yy-selected-panel
    margin: 8px 0 28px
  .yy-channel-list-wrapper
    height: calc(100% - 104px - 2px )
    @media screen and (max-height: 212px)
      height: 200px
    .yy-channel-list
      &-item
        padding: 4px 0!important
        &:hover
          .yy-channel-avatar
            opacity: 1
          .yy-channel-title
            color: $gray-color2!important
        .yy-channel-title
          font-size: 18px
          line-height: 24px!important
          color: $gray-color5!important
          font-weight: 400!important
        .yy-channel-intro
          font-size: 12px
          line-height: 16px!important
          margin-top: 0!important
          color: $gray-color5!important
          font-weight: 400!important
        .yy-channel-avatar
          padding: 0 2px!important
          opacity: 0.4
          .yy-channel-icon
            width: 36px
            height: 36px
            border-radius: 50%
            object-fit: cover
.yy-tips-wrapper
  padding-left: 6px!important
  padding-right: 6px!important
</style>


import { postAction, getAction, putAction, baseAction } from '@utils/request'

/**
 * 注册
 * @param {*} parameter 
 * @returns 
 */
export function register (parameter) {
  return postAction("/sys/user/registerByEmail", parameter)
}

/**
 * 激活
 * @param {*} parameter 
 * @returns 
 */
export function active (parameter) {
  console.log('active param', parameter)
  return postAction("/sys/user/active", parameter, false)
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return postAction('/sys/login', parameter)
}

export function phoneLogin (parameter) {
  return postAction('/sys/phoneLogin', parameter)
}

export function getSmsCaptcha (parameter) {
  //return postAction('/account/sms', parameter)
  return getAction(`/sys/randomImage/${parameter}`)
}

export function logout () {
  let headers = {
    'Content-Type': 'application/json;charset=UTF-8'
  }
  return baseAction('/sys/logout', {}, 'post', headers)
}

/**
 * 第三方登录
 * @param token
 * @param thirdType
 * @returns {*}
 */
export function thirdLogin (token, thirdType) {
  let url = `/sys/thirdLogin/getLoginUser/${token}/${thirdType}`
  let headers = {
    'Content-Type': 'application/json;charset=UTF-8'
  }
  return baseAction(url, {}, 'get', headers)
}

/**
 * 强退其他账号
 * @param token
 * @returns {*}
 */
export function forceLogout (parameter) {
  return postAction('/sys/online/forceLogout', parameter)
}

/**
 * 重置密码邮件发送验证码
 */
export function emailVeriCode (parameter) {
  return postAction('/sys/user/emailVeriCode', parameter)
}

/**
 * 重置
 */
export function resetPassword (parameter) {
  return putAction('/sys/user/resetPassword', parameter)
}
<template>
  <q-dialog v-model="showDialog" persistent>
    <div class="yy-my-dialog column items-stretch no-wrap">
      <div class="row justify-between items-center">
        <div class="yy-my-dialog-title">查看数据集</div>
        <q-btn
          class="yy-my-dialog-btn"
          unelevated
          v-close-popup
          @click="$emit('clickClose')"
        >
          <q-icon
            class="yy-my-dialog-btn-icon"
            :name="'img:' + iconBase64.get('closeDialog')"
          ></q-icon>
        </q-btn>
      </div>
      <div class="yy-my-dialog-caption">
        {{ datasetMeta.datasetName }}.geojson
      </div>
      <div class="yy-my-dialog-line"></div>
      <div class="yy-my-dialog-table">
        <div class="yy-head row no-wrap">
          <div class="yy-col"></div>
          <div class="yy-col column items-center">
            <label>string</label>
            <label>Name</label>
          </div>
          <div class="yy-col column items-center">
            <label>int</label>
            <label>Verity</label>
          </div>
          <div class="yy-col"></div>
        </div>
        <div class="yy-body">
          <div
            class="yy-row row no-wrap"
            v-for="(item, index) in dataset"
            :key="item.id"
          >
            <div class="yy-col">{{ index + 1 }}</div>
            <div class="yy-col">{{ item.dataName }}</div>
            <div class="yy-col">1</div>
            <div class="yy-col"></div>
          </div>
          <template v-if="dataset.length < 13">
            <div
              v-for="n in 13 - dataset.length"
              :key="n"
              class="yy-row row no-wrap"
            >
              <div class="yy-col" v-for="m in 4" :key="m"></div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <input
      type="file"
      id="uploads"
      class="yy-my-file"
      accept=".geojson,.json,application/json"
      @change="selectFile"
      ref="uploadFile"
    />
  </q-dialog>
</template>
<script>
import { ref, watch, toRefs } from 'vue'
import useDrawerMaterial from "@composables/useDrawerMaterial";
import { fetchDatasetByTableName } from "@api/dataset-api"
export default {
  name: "ViewDatasetDialog",
  emits: ["clickClose"],
  props: {
    show: { type: Boolean, default: false },
    datasetMeta: { type: Object, required: true },
    /* dataset: {
      type: Array, default: () => [{ id: "1", name: "2020万科新年马拉松", verity: 1 }, { id: "2", name: "2020厦门马拉松", verity: 1 }, { id: "3", name: "2020眉山仁寿半程马拉松", verity: 1 }, { id: "4", name: "2020石家庄国际徒步大会", verity: 1 }]
    } */
  },
  setup (props) {
    console.log("view dataset props", props)
    const { iconBase64 } = useDrawerMaterial();
    const dataset = ref([])
    const { show, datasetMeta } = toRefs(props)
    console.log('props confirm', show)
    const showDialog = ref(false)

    watch(
      show,
      (value) => {
        console.log('watch confirm', value, datasetMeta)
        showDialog.value = value
        if (value) {
          fetchDatasetByTableName(datasetMeta.value.tableName).then(res => {
            dataset.value = res
          })
        }
      }
    )

    return {
      dataset,
      iconBase64,
      showDialog
    }
  },
  data () {
    return {

    }
  },
  methods: {
    openFolder () {
      console.log('openFolder ', document.getElementById("uploads"))
      document.getElementById("uploads").click();
    },
    selectFile (e) {
      console.log('select file', e)
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@sass/quasar.sass"
@import "@sass/common.edit.sass"
@import "@sass/common.dialog.sass"

.yy-my-dialog
  overflow: hidden
  padding-bottom: 0
  max-height: 480px
  @include scrollBarStyle

  &-caption
    margin-top: 12px
  &-line
    height: 1px
    margin: 0 -40px
    background-color: $gray-color2
  &-table
    height: 372px
    margin: 0 -40px
    font-size: 12px
    font-weight: 400
    color: white
    .yy-head
      .yy-col
        border: none
        label
          line-height: 24px
          &:first-child
            color: $gray-color5

    .yy-body
      max-height: 314px
      overflow-y: auto
      @include scrollBarStyle
      .yy-row
        border-top: 1px solid $dark-color7
        &:last-child
          border-bottom: 1px solid $dark-color7
        .yy-col
          line-height: 23px
          height: 23px
          border-right: 1px solid $dark-color7
          &:last-child
            border-right: none
    .yy-col
      width: 360px
      text-align: center
      &:first-child, &:last-child
        width: 40px
</style>

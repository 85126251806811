import { ref } from "vue";
export default function useDrawerHandler (defaultFontSize, fontSizeMap) {
  const initDrawerWidth = 356
  const initDrawerMiniWidth = 56
  const drawerWidth = ref(initDrawerWidth)
  const drawerMiniWidth = ref(initDrawerMiniWidth)
  const screenWidth = ref(0)

  const resizeHandler = function () {
    screenWidth.value = document.body.clientWidth;
    let ratio = window.devicePixelRatio;
    console.log("ratio", ratio);
    console.log("resize html font size:", screenWidth.value);
    let fontSize = defaultFontSize;
    let keys = [...fontSizeMap.keys()];
    let values = [...fontSizeMap.values()];
    console.log("keys", keys);
    console.log("values", values);
    let index = keys.findIndex((key) => {
      let keyArr = key.split(",");
      return (
        (key != ">" &&
          screenWidth.value <= keyArr[0] * 1 &&
          (keyArr.length < 2 || keyArr[1] * 1 <= ratio)) ||
        key == ">"
      );
    });
    console.log("index", index);
    fontSize = values[index];
    console.log("fontSize:", fontSize);
    drawerWidth.value = (initDrawerWidth / defaultFontSize) * fontSize;
    drawerMiniWidth.value = (initDrawerMiniWidth / defaultFontSize) * fontSize;
    console.log('drawer Width, MiniWidth:', drawerWidth.value, drawerMiniWidth.value)
  }
  return {
    resizeHandler,
    drawerWidth,
    drawerMiniWidth
  }
}
<template>
  <drawer-head subTitle="频道设置"></drawer-head>
</template>
<script>
import DrawerHead from "@components/frontline/DrawerHead";
export default {
  name: "Setting4Channel",
  components: {
    DrawerHead,
  },
};
</script>

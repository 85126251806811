<template>
  <div class="yy-main-area column items-stretch">
    <BoardHead
      :title="portionTitle"
      :darkMode="true"
      @go-back="closeBoard"
    ></BoardHead>
    <div class="yy-box-wrapper">
      <component :is="portionName"></component>
    </div>
  </div>
</template>
    
<script>
import BoardHead from "@components/drawer/BoardHead";
import ChannelBoard from "@components/drawer/ChannelBoard";
import ChannelInfo from "@components/drawer/ChannelInfo";
export default ({
  name: "DrawerMainArea",
  emits: ["closeBoard"],
  props: {
    portionTitle: { type: String, default: '默认标题' },
    portionName: { type: String, default: '' }
  },
  data () {
    return {

    }
  },
  components: {
    BoardHead,
    ChannelBoard,
    ChannelInfo
  },
  methods: {
    closeBoard () {
      this.$emitter.emit('toggleDrawerEvent', 0)
      this.$emit('closeBoard')
    }
  }
})
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
</style>
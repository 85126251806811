<template>
  <div class="yy-tree-board column items-stretch">
    <BoardHead title="空间图谱" @go-back="$emit('closeBoard')"></BoardHead>
    <div class="row items-center yy-theme-wrapper no-wrap">
      <q-avatar rounded size="2.25rem" class="self-start">
        <img src="@img/icon-tree.png" />
      </q-avatar>
      <div class="yy-theme col-grow column">
        <div class="yy-theme-name">世界各国军事组织关系</div>
        <div class="yy-theme-manager self-stretch row no-wrap items-center">
          <q-avatar size="1.5rem" class="yy-theme-manager-avatar">
            <img src="/img/temp-zcy.png" />
          </q-avatar>
          <div class="yy-theme-manager-name col-grow">朱朝阳</div>
          <div class="yy-theme-manager-time">2021-05-13 23:15:46</div>
        </div>
        <div class="yy-theme-views">16,584 次浏览</div>
      </div>
    </div>
    <div flat class="yy-line"></div>
    <div class="yy-label">数据<br />集合</div>
    <div flat class="yy-line"></div>
    <div class="yy-tree-wrapper">
      <q-tree
        :nodes="lazy"
        default-expand-all
        node-key="label"
        @lazy-load="onLazyLoad"
        @update:expanded="afterShow"
        @after-show="afterShow"
        @after-hide="afterHide"
        text-color="main-color5"
        color="main-color5"
        v-model:expanded="expanded"
        v-model:selected="selected"
      />
    </div>
  </div>
</template>
<script>
import BoardHead from "@components/drawer/BoardHead";
import { ref } from "vue";

const nodes = [
  {
    label: "世界各国军事组织架构",
    icon: "public",
    children: [
      { label: "美利坚合众国", lazy: true, icon: "folder" },
      { label: "大日本国", lazy: true, icon: "folder" },
      { label: "印度共和国", lazy: true, icon: "folder" },
      { label: "中国台湾地区", lazy: true, icon: "folder" },
    ],
  },
];

export default {
  name: "ChannelInfo",
  emits: ["closeBoard"],
  inject: ["boardWidth"],
  setup() {
    return {
      lazy: ref(nodes),

      onLazyLoad({ node, key, done }) {
        // call fail() if any error occurs

        setTimeout(() => {
          // simulate loading and setting an empty node
          if (key.indexOf("Lazy load empty") > -1) {
            done([]);
            return;
          }

          const label = node.label;
          done([
            { label: `${label}.1` },
            { label: `${label}.2`, lazy: true },
            {
              label: `${label}.3`,
              children: [
                { label: `${label}.3.1`, lazy: true },
                { label: `${label}.3.2`, lazy: true },
              ],
            },
          ]);
        }, 1000);
      },
      onExpanded(expanded) {
        console.log("expanded:", expanded);
        expanded.forEach((node) => {
          node.icon = "place";
        });
      },
    };
  },
  data() {
    return {
      selected: "",
      update: "",
      expanded: [],
    };
  },
  components: {
    BoardHead,
  },
  computed: {
    boardMaxWidth() {
      return { "max-width": `${this.boardWidth.value}px` };
    },
  },
  methods: {
    login() {
      this.$emit("changeStatus", "User");
    },
    afterShow(event) {
      console.log("after show:", this.expanded, event);
    },
    afterHide(event) {
      console.log("after hide", this.expanded, event);
    },
  },
};
</script>
<style lang="sass">
@import "@sass/quasar.sass"
.yy-tree-board
  .yy-page-input-color
    color: $gray-color2
  .yy-tree-wrapper
    .q-tree__node-header
      padding: 6px
      margin-top: 3px
</style>
<style lang="sass" scoped>
@import "@sass/quasar.sass"
@import "@sass/drawer.board.sass"
.yy-tree-board
  .yy-theme-wrapper
    padding: 15px 14px
    .yy-theme
      margin-left: 4px
      &-name
        color: $main-color2
        font-size: 22px
        font-weight: 400
      &-manager
        margin-top: 18px
        color: $gray-color1
        font-size: 14px
        &-name
          margin-left: 4px
      &-views
        color: $gray-color1
        font-size: 14px
  .yy-label
    @include square-label
    padding: 4px 14px
  .yy-tree-wrapper
    padding: 0 10px
    font-size: 14px
</style>

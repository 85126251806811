<template>
  <div
    v-if="selectedChannel"
    class="yy-channel-info column no-wrap items-stretch"
  >
    <SelectedPanel
      class="yy-selected-panel"
      :title="selectedChannel.channelName"
      :intro="selectedChannel.channelIntro"
    ></SelectedPanel>
    <div class="yy-manager row">
      <q-btn
        round
        unelevated
        v-for="item in managerList"
        :key="item.name"
        padding="0"
      >
        <q-tooltip
          class="yy-avatar-tip"
          anchor="top middle"
          self="bottom middle"
          :offset="[0, 0]"
          transition-show="slide-up"
          transition-hide="slide-down"
          >{{ item.name }}</q-tooltip
        >
        <q-avatar>
          <img :src="item.avatar" />
        </q-avatar>
      </q-btn>
    </div>

    <div class="yy-search-box-wrapper"><SearchBox></SearchBox></div>
    <div flat class="yy-line"></div>

    <div class="yy-list-head row justify-between items-center">
      <div class="yy-label">频道内容</div>
      <div class="yy-list-sort">
        <span :class="{ 'yy-current-sort': sortType == 0 }">最新</span
        ><span>/</span><span>热点</span><span>/</span><span>推荐</span>
      </div>
    </div>

    <q-virtual-scroll class="yy-update-list-wrapper" :items="channelInfoList">
      <template v-slot="{ item }">
        <q-item
          :key="item.id"
          clickable
          v-ripple
          class="yy-update-list-item"
          @click="selectChannelItem(item)"
        >
          <q-item-section top avatar class="yy-item-icon">
            <img :src="iconBase64.get('channelListPoint')" />
          </q-item-section>

          <q-item-section>
            <q-item-label :lines="1" class="yy-main-label">{{
              item.name
            }}</q-item-label>
            <q-item-label :lines="1" class="yy-sub-label"
              ><span>{{
                item.updateTime == null
                  ? item.createTime.substring(0, 10)
                  : item.updateTime.substring(0, 10)
              }}</span>
              <span>{{ item.views }} 次浏览</span>
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-virtual-scroll>
    <!-- <q-scroll-area
      visible
      class="yy-update-list-wrapper"
      id="scroll-area-with-virtual-scroll-1"
    >
      <q-virtual-scroll
        scroll-target="#scroll-area-with-virtual-scroll-1 > .scroll"
        :items="channelInfoList"
        :virtual-scroll-item-size="15"
      >
        <template v-slot="{ item, index }">
          <q-item :key="index" dense>
            <q-item-section>
              <q-item-label> #{{ index }} - {{ item.name }} </q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-virtual-scroll> -->

    <!-- <q-list class="yy-update-list">
        <q-item
          v-for="item in channelInfoList"
          :key="item.id"
          clickable
          v-ripple
          class="yy-update-list-item"
          @click="selectChannelItem(item)"
        >
          <q-item-section top avatar class="yy-item-icon">
            <img :src="iconBase64.get('channelListPoint')" />
          </q-item-section>

          <q-item-section>
            <q-item-label :lines="1" class="yy-main-label">{{
              item.name
            }}</q-item-label>
            <q-item-label :lines="1" class="yy-sub-label"
              ><span>{{
                item.updateTime == null
                  ? item.createTime.substring(0, 10)
                  : item.updateTime.substring(0, 10)
              }}</span>
              <span>{{ item.views }} 次浏览</span>
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list> -->
    <!-- </q-scroll-area> -->
  </div>
  <div else>暂无频道数据</div>
</template>
<script>
import SelectedPanel from "@components/drawer/channel/SelectedPanel"
import SearchBox from "@components/SearchBox"
import { useStore } from "vuex";
import useDrawerMaterial from "@composables/useDrawerMaterial";

export default {
  name: "ChannelInfo",
  setup () {
    const store = useStore();
    const selectedChannel = store.getters.selectedChannel
    const { iconBase64 } = useDrawerMaterial();
    console.log('selectedChannel', selectedChannel)
    const channelInfoList = store.getters.channelInfoList

    return {
      selectedChannel,
      iconBase64,
      channelInfoList,
    }
  },
  data () {
    return {
      name: "前线",
      sortType: 0,
      managerList: [
        { name: "朱朝阳", avatar: "/img/temp-zcy.png" },
        { name: "数学老师", avatar: "/img/temp-m.png" },
        { name: "女孩", avatar: "/img/temp-g.png" },
        { name: "母亲", avatar: "/img/temp-p.png" },
      ],
      current: 1,
      searchKey: "",
    };
  },
  inject: ["boardWidth"],
  components: {
    SelectedPanel,
    SearchBox,
  },

  computed: {
    boardMaxWidth () {
      return { "max-width": `${this.boardWidth.value}px` };
    },
  },
  methods: {
    login () {
      this.$emit("changeStatus", "User");
    },
    selectChannelItem (item) {
      console.log("emit selectChannelItem event")
      this.$emitter.emit('selectChannelItemEvent', item)
    },

  },
};
</script>
<style lang="sass">
@import '@sass/quasar.variables.sass'
.yy-channel-info
  .yy-update-list
    &-item
      padding: 9px 8px
      &:hover,
      &:focus
        background-color: $dark-color6!important
        .q-focus-helper
          opacity: 0!important
</style>
<style lang="sass" scoped>
@import "@sass/quasar.sass"
@import "@sass/drawer.board.sass"
$top-height: calc(#{$drawer-board-header-height} + 28px + 48px + 48px + 1px)
$media-max-height: calc(#{$top-height} + 50px)
.yy-channel-info
  height: 100%
  @media screen and (max-height: $media-max-height)
    height: auto
  .yy-selected-panel
    margin: 6px 0 4px
  .yy-manager
    padding-left: 40px
    margin-bottom: 4px
    .q-btn
      padding: 0 2px!important
      .q-avatar
        width: 24px
        height: 24px
    .yy-avatar-tip
      background-color: $gray-color3
      font-size: 12px!important
      color: $gray-color2
  .yy-search-box-wrapper
    margin: 8px 0 6px
  .yy-list-head
    margin-top: 16px
    padding-left: 40px
    color: $gray-color5
    .yy-label, .yy-list-sort
      font-size: 12px
      line-height: 32px
      color: $gray-color5
    .yy-list-sort
      .yy-current-sort
        color: $back-color1
  .yy-update-list-wrapper
    margin-right: 0 - $drawer-xpadding
    padding-right: 0 - $drawer-xpadding
    height: calc(100% - #{$top-height})
    @include scrollBarStyle

    @media screen and (max-height: $media-max-height)
      height: 200px
    .yy-update-list

      &-item
        padding: 9px 0 9px 8px
        .yy-item-icon
          align-items: start
          min-width: 32px!important
          img
            width: 24px!important
            height: 30px!important
        .yy-main-label
          color: $gray-color2
          font-size: 14px
          width: 230px
          line-height: 14px!important
        .yy-sub-label
          color: $gray-color1
          font-size: 12px
          line-height: 12px!important
          span:not(:first-child)
            margin-left: 30px
</style>

<template>
  <div class="yy-main-area column items-stretch">
    <BoardHead
      :title="portionTitle"
      :darkMode="true"
      @go-back="closeBoard"
    ></BoardHead>
    <div v-if="isEditor" class="yy-box-wrapper">
      <component
        :is="portionName"
        @changeBox="changeBox"
        v-bind="comPros"
      ></component>
    </div>

    <NeedAuth v-else></NeedAuth>
  </div>
</template>
    
<script>
import BoardHead from "@components/drawer/BoardHead";
import MyDatabase from "@components/drawer/databoard/MyDatabase";
import ChannelEdit from "@components/drawer/projectboard/ChannelEdit";
import CoverUpdate from "@components/drawer/projectboard/CoverUpdate";
import DataLayer from "@components/drawer/projectboard/DataLayer";
import NeedAuth from "@components/common/NeedAuth";
import { computed } from "vue";
import { useStore } from "vuex";
export default ({
  name: "DataBoard",
  emits: ["closeBoard"],
  setup () {
    const store = useStore()
    return {
      isEditor: computed(() => store.getters.isEditor)
    }
  },
  data () {
    return {
      portions: new Map([['MyDatabase', '我的数据仓库'], ['ChannelEdit', '创建频道/设置频道'], ['CoverUpdate', '设置/修改封面'], ['DataLayer', '数据图层管理']]),
      portionTitle: "",
      portionName: "",
      comPros: {}
    }
  },
  components: {
    BoardHead,
    MyDatabase,
    ChannelEdit,
    CoverUpdate,
    DataLayer,
    NeedAuth
  },
  created () {
    this.portionNames = [...this.portions.keys()]
    this.portionTitles = [...this.portions.values()]
    this.portionName = this.portionNames[0]
    this.portionTitle = this.portionTitles[0]
  },
  methods: {
    closeBoard () {
      console.log('closeboard')
      if (this.portionName == this.portionNames[0]) {
        this.$emitter.emit('toggleDrawerEvent', 0)
        this.$emit('closeBoard')
      } else {
        this.portionName = this.portionNames[0]
        this.portionTitle = this.portionTitles[0]
      }

    },
    changeBox (args) {
      console.log('changebox', args)
      this.portionName = args[0]
      this.portionTitle = args[1] ? args[1] : this.portions.get(args[0])
      this.comPros = args[2] ? args[2] : {}
    },

  }
})
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
@import "@sass/common.my.sass"
</style>
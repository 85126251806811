<template>
  <div class="yy-cover-update column items-center">
    <q-avatar class="yy-q-avatar">
      <q-icon
        v-show="newChannel.coverUrl != ''"
        :name="'img:' + newChannel.coverUrl"
      />
    </q-avatar>
    <div class="yy-cover-wrapper">
      <q-img
        v-if="newChannel.coverUrl != ''"
        class="yy-cover"
        :src="newChannel.coverUrl"
        spinner-color="white"
        fit="cover"
      ></q-img>
    </div>
    <q-btn
      label="选择图片"
      unelevated
      class="yy-light-q-btn yy-small-q-btn"
      @click="selectPicture"
    ></q-btn>
    <div class="yy-btn-wrapper row self-end justify-end">
      <q-btn
        unelevated
        class="yy-q-btn"
        label="取消"
        @click="updateCover(false)"
      />
      <q-btn
        unelevated
        :disable="!coverSelected"
        class="yy-q-btn"
        :label="channel.coverUrl ? '修改' : '添加'"
        @click="updateCover(true)"
      />
    </div>
    <input
      type="file"
      id="uploads"
      class="yy-img-file"
      accept="image/png, image/jpeg, image/gif, image/jpg"
      @change="selectFile"
      ref="uploadImg"
    />
  </div>
</template>
<script>
import { reactive, ref } from "vue"
export default {
  name: "CoverUpdate",
  emits: ["back2Box"],
  props: {
    channel: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  setup (props) {
    const coverSelected = ref(false)
    const newChannel = reactive({ ...props.channel })
    const uploadImg = ref(null)
    return { coverSelected, newChannel, uploadImg }
  },
  methods: {
    selectPicture () {
      console.log('select picture ', document.getElementById("uploads"))
      document.getElementById("uploads").click();
    },
    selectFile (e) {
      //上传图片
      // this.option.img
      console.log("select file", e.target)
      try {
        var file = e.target.files[0];
        if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
          this.$q.notify({
            message: "图片类型必须是.gif、jpeg、jpg、png、bmp中的一种"
          })
          return false;
        }
        const fileMaxSize = 1024 * 100
        if (file.size > fileMaxSize) {
          this.$q.notify({
            message: `图片大小不能超过100KB`
          })
          return
        }
        var reader = new FileReader();
        reader.onload = e => {
          let data;
          let coverBlob = new Blob([e.target.result])
          if (typeof e.target.result === "object") {
            // 把Array Buffer转化为blob 如果是base64不需要

            data = window.URL.createObjectURL(coverBlob);
          } else {
            data = e.target.result;
          }
          this.newChannel.coverUrl = data
          this.newChannel.coverBlob = coverBlob
          this.coverSelected = true
        };
        // 转化为base64
        // reader.readAsDataURL(file)
        // 转化为blob
        reader.readAsArrayBuffer(file);

      } finally {
        this.$refs.uploadImg.value = ''
      }

    },
    updateCover (success) {
      if (success) {
        this.$emit('back2Box', { channel: this.newChannel })
      } else {
        this.$emit('back2Box', { channel: this.channel })
      }

    }
  },
}
</script>
<style lang="sass" scoped>
@import "@sass/common.edit.sass"
.yy-cover-update
  padding-top: 8px
  margin-left: -14px
  width: calc(100% + 14px + 14px)
  .yy-cover-wrapper
    margin: 32px 0 12px
    background-color: $dark-color6
    height: 240px
    width: 100%
    padding: 11px 41px
    .yy-cover
      width: 100%
      height: 100%
      border-radius: 6px
  .yy-btn-wrapper
    margin: 66px 20px 0 0
</style>
<template>
  <q-drawer
    v-model="drawer"
    show-if-above
    :mini="miniState"
    mini-to-overlay
    :width="drawerWidth"
    :mini-width="drawerMiniWidth"
    :breakpoint="0"
    class="yy-drawer"
  >
    <div class="fit row no-wrap">
      <q-scroll-area :thumb-style="{ width: '0' }" class="yy-list">
        <q-list class="column items-center no-wrap">
          <q-item
            clickable
            v-ripple
            class="yy-item yy-main-item"
            @click="itemClick(firstItemName)"
            :title="mainTitle"
            :active="firstItemName == componentName"
            active-class="yy-active"
          >
            <q-item-section
              avatar
              class="yy-item-section row items-center justify-center"
            >
              <q-avatar v-if="mainIcon" class="yy-item-avatar">
                <img :src="mainIcon" />
              </q-avatar>
              <q-avatar v-else color="gray-color3" text-color="white"
                >{{ firstChar }}
              </q-avatar>
            </q-item-section>
          </q-item>

          <q-item
            clickable
            v-ripple
            class="yy-item yy-selected-item"
            @click="itemClick(secondItemName)"
            active-class="yy-active"
            :active="secondItemName == componentName"
            title="选择频道"
          >
            <q-item-section
              avatar
              class="yy-item-section row items-center justify-center"
            >
              <!-- <q-icon :name="selectedIcon" /> -->
              <q-avatar class="yy-item-avatar">
                <img :src="selectedIcon" />
              </q-avatar>
            </q-item-section>
          </q-item>
          <q-item
            v-for="(item, index) in drawerIconBase64"
            :key="item.name"
            clickable
            :active="item.name == componentName"
            active-class="yy-active"
            v-ripple
            class="yy-item yy-sub-item"
            :class="{ second: index == 0 }"
            @click="itemClick(item.name, index)"
            :title="item.title"
          >
            <q-item-section
              avatar
              class="yy-item-section row items-center justify-center"
            >
              <q-icon class="yy-icon" :name="'img:' + item.data" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-scroll-area>
      <q-scroll-area :visible="!miniState" class="yy-scroll-area">
        <transition name="component-fade" mode="out-in">
          <component
            :is="componentObjName"
            :portionTitle="portionTitle"
            :portionName="portionName"
            @close-board="miniState = true"
          >
            ></component
          >
        </transition>
      </q-scroll-area>
    </div>
    <div
      v-if="!miniState"
      class="yy-drawer-close-btn"
      @click="closeByBtn"
    ></div>
  </q-drawer>
</template>
<script>
import useDrawerMaterial from "@composables/useDrawerMaterial";
import useDrawerHandler from "@composables/useDrawerHandler";
import { ref, computed, inject, onMounted, onUnmounted } from "vue";
import MainBoard from "@components/drawer/MainBoard";
import DrawerMainArea from "@components/drawer/DrawerMainArea";
import ChannelInfoWithHead from "@components/drawer/ChannelInfoWithHead";
import SearchBoard from "@components/drawer/SearchBoard";
import TreeBoard from "@components/drawer/TreeBoard";
import MyProject from "@components/drawer/ProjectBoard";
import MyDatabase from "@components/drawer/DataBoard";
import ChannelIntro from "@components/frontline/ChannelIntro";
import DataTree from "@components/frontline/DataTree";
import Feedback from "@components/frontline/Feedback";
import Search4Channel from "@components/frontline/Search4Channel";
import Setting4Channel from "@components/frontline/Setting4Channel";
import UpdateRecord from "@components/frontline/UpdateRecord";
import Setting4Map from "@components/frontline/Setting4Map";
import { useStore } from "vuex";
export default {
  name: "FrontlineDrawer",
  components: {
    MainBoard,
    DrawerMainArea,
    SearchBoard,
    TreeBoard,
    ChannelIntro,
    DataTree,
    Feedback,
    Search4Channel,
    Setting4Channel,
    UpdateRecord,
    Setting4Map,
    ChannelInfoWithHead,
    MyProject,
    MyDatabase
  },
  setup () {
    const store = useStore()
    let miniState = ref(true)
    let componentName = ref("")

    const defaultFontSize = inject("defaultFontSize");
    const fontSizeMap = inject("fontSizeMap");
    const { mainIconData, drawerIconBase64, channelIconBase64 } =
      useDrawerMaterial();

    const mainIcon = computed(() => {
      let iconData = mainIconData.value
      if (miniState.value && store.getters.isAuthenticated && componentName.value == 'MainBoard' || store.getters.isAuthenticated && componentName.value != 'MainBoard') {
        iconData = store.state.userAvatar;
      }
      console.log('compute mainIcon', iconData)
      return iconData
    });

    const mainTitle = computed(() => {
      if (mainIcon.value == store.state.userAvatar) {
        return ""
      }
      return "云舆标记"
    });

    //let mainIcon = ref(store.state.userAvatar)
    /* const selectedIcon = computed(() => {
      return "img:" + channelIconBase64.value[0].data;
    }); */
    //let selectedIcon = ref("img:" + channelIconBase64.value[0].data)
    let selectedIcon = ref(channelIconBase64.value[0].data)
    const { resizeHandler, drawerWidth, drawerMiniWidth } = useDrawerHandler(
      defaultFontSize,
      fontSizeMap
    );

    onMounted(() => {
      resizeHandler();
      window.addEventListener("resize", resizeHandler);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", resizeHandler, false);
    });

    let firstChar = computed(() => store.state.userName != null ? store.state.userName.charAt(0) : '')

    return {
      drawer: ref(false),
      miniState,
      firstItemName: ref("MainBoard"),
      secondItemName: ref("ChannelBoard"),
      threeItemName: ref("ChannelInfoWithHead"),
      fourItemName: ref("MyProject"),
      fifthItemName: ref("MyDatabase"),
      drawerIconBase64,
      channelIconBase64,
      mainIcon,
      mainTitle,
      selectedIcon,
      resizeHandler,
      drawerWidth,
      drawerMiniWidth,
      fontSizeMap,
      componentName,
      firstChar,
    };
  },
  data () {
    return {
      componentObjName: '',
      portionTitle: '',
      portionName: '',
    };
  },
  provide () {
    return {
      boardWidth: computed(() => this.drawerWidth - this.drawerMiniWidth),
    };
  },

  created () {
    this.itemClick = this.__.debounce(this.clickHandler, 50);
    this.$emitter.on("toggleDrawerEvent", this.toggleDrawer)
    this.$emitter.on("showLoginEvent", this.showLogin)
  },
  mounted () {
    if (
      this.$route.redirectedFrom &&
      this.$route.redirectedFrom.path.includes("activelogin")
    ) {
      this.miniState = false;
      this.componentName = "MainBoard";
    }
  },
  unmounted () {
    // 移除组件时，取消定时器
    this.itemClick.cancel();
  },
  methods: {
    clickHandler (iconName, index = 0) {
      console.log('click icon', iconName, this.threeItemName, iconName != this.threeItemName)
      if (this.componentName == iconName) {
        this.miniState = !this.miniState;
      } else {
        this.miniState = false;
        this.componentName = iconName;
        this.componentObjName = iconName

      }
      this.portionName = this.drawerIconBase64[index].name
      this.portionTitle = this.drawerIconBase64[index].title
      if (iconName != this.firstItemName && iconName != this.threeItemName && iconName != this.fourItemName && iconName != this.fifthItemName) {
        //this.firstIcon = this.firstImgOut;
        this.componentObjName = "DrawerMainArea"
        if (iconName == this.secondItemName) {
          this.portionName = iconName
          this.portionTitle = "切换频道"
        }
      }

      this.$emitter.emit('toggleDrawerEvent', this.miniState ? 0 : this.drawerWidth - this.drawerMiniWidth)

      if (iconName == this.fifthItemName) {
        this.$emitter.emit('changeMapComEvent', "Map4Dataset")
      } else if (iconName == this.threeItemName || iconName == this.secondItemName || iconName == this.fourItemName) {
        this.$emitter.emit('changeMapComEvent', "Map4Channel")
      }

      if (iconName != this.fifthItemName && iconName != this.fourItemName) {
        this.$emitter.emit('showSharedChannelEvent')
      }

    },
    toggleDrawer (e) {
      console.log('toggle Drawer', e)
      this.miniState = e == 0 ? true : false
    },
    closeByBtn () {
      this.miniState = true
      this.$emitter.emit('toggleDrawerEvent', 0)
    },
    showLogin () {
      this.miniState = false
      this.componentName = this.firstItemName;
      this.componentObjName = this.firstItemName
      this.portionName = "Login"
    }
  },
};
</script>
<style lang="sass">
@import "@sass/quasar.variables.sass"
.yy-drawer
  background-color: $dark-color3
  overflow-x: hidden !important
  .yy-list
    .yy-item
      &:hover,
      &:focus
        .q-focus-helper
          opacity: 0
    .q-icon
      width: 36px!important
      height: 36px!important
.q-drawer--mini-animate .q-item__section--avatar
  min-width: 36px!important
  align-items: center
.q-drawer--mini-animate .q-item__section--side
  padding-right: 0!important
.yy-scroll-area
  .q-scrollarea__content
    display: flex
    flex-direction: column
    width: 100%
  .q-field__messages > div
    white-space: break-spaces
.q-drawer__content
  .q-field__messages > div
    white-space: break-spaces
</style>
<style lang="sass" scoped>
@import "@sass/transition.sass"
@import "@sass/quasar.variables.sass"
$active-bg-color: $dark-color1
$big-item-width: 64px
.yy-list
  background-color: $dark-color7
  width: $drawer-min-width
  .yy-item
    padding: 0!important
    min-height: 36px!important
    min-width: 36px!important

    /** &.yy-main-item
     margin-top: 14px!important
     margin-bottom: 14px!important
     width: $drawer-min-width
     height: $drawer-min-width
     position: relative
     .yy-item-section
     position: absolute
     width: 64px
     height: 64px
     max-width: 64px!important
     left: -4px
     border-radius: 50%
     .yy-item-avatar
     width: 36px
     height: 36px **/
    &.yy-main-item,
    &.yy-selected-item
      width: $drawer-min-width
      height: $big-item-width
      position: relative
      .yy-item-section
        position: absolute
        width: $big-item-width
        height: $big-item-width
        max-width: 64px!important
        left: -4px
        border-radius: 50%
    &.yy-main-item
      margin-top: 8px!important
      margin-bottom: 8px!important
      .yy-item-avatar
        width: 36px
        height: 36px
    &.yy-selected-item
      margin-bottom: 32px!important
      .yy-item-avatar
        width: 48px
        height: 48px
    &.yy-sub-item

      .yy-item-section
        width: 48px
        height: 48px
        //background-color: $dark-color1
        background-color: $dark-color7
        &:hover
          background-color: $dark-color7
  .yy-sub-item
    .yy-icon
      width: 21px!important
      height: 21px!important

.yy-scroll-area
  background-color: $dark-color5
  flex: 1
  border: 0
  .q-scrollarea__content
    display: flex
    flex-direction: column
.yy-active
  .yy-item-section
    //background-color: $dark-color3!important
    background-color: $dark-color5!important
.yy-drawer-close-btn
  position: absolute
  right: -20px
  width: 20px
  height: 48px
  top: 50%
  transform: translateY(-50%)
  border-radius: 0 4px 4px 0
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDkgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS4zNTQgMTIuNTQ1YTEgMSAwIDAgMSAwLTEuMDlMNy4xNi45NzVDNy43MDQuMTM4IDkgLjUyMiA5IDEuNTE4djIwLjk2MmMwIC45OTYtMS4yOTYgMS4zOC0xLjgzOS41NDVMLjM1NCAxMi41NDV6IiBmaWxsPSIjNkE3MjdDIi8+PC9zdmc+) no-repeat 4px center
  background-color: $dark-color5
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25)
  cursor: pointer
</style>

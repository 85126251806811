import { watch } from "vue";
import _ from 'lodash'
export default function usePasswordWatch (model, pass2Ref) {

  watch(
    () => model.password1,
    () => {
      console.log('watch password1', model)
      if (_.isEmpty(model.password2)) {
        pass2Ref.value.resetValidation()
      } else { pass2Ref.value.validate() }
    }
  );
}
<template>
  <router-view />
</template>
<script>
export default {
  name: "App",

};
</script>
<style lang="sass">
@import "@sass/common.sass"
</style>

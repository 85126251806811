<template>
  <div class="yy-channel-edit column no-wrap items-stretch">
    <div class="yy-my-input-wrapper column">
      <input
        id="channelName"
        ref="channelNameRef"
        prop="channelName"
        autofocus="autofocus"
        autocomplete="off"
        type="text"
        v-model="newChannel.channelName"
        maxlength="20"
        @blur="validateBlur"
        @input="validateInput"
        :class="{ 'validate-feedback': modelControl['channelName'] }"
      />
      <label class="order-first" for="channelName">频道名称</label>
      <div class="yy-validate-feedback" v-if="modelControl['channelName']">
        {{ modelControl['channelName'] }}
      </div>
    </div>
    <div
      ref="coverUrlRef"
      class="yy-cover-wrapper row justify-between items-end"
    >
      <div
        class="yy-img-wrapper"
        :class="{ 'no-border': newChannel.coverUrl != '' }"
      >
        <q-btn
          v-if="newChannel.coverUrl == ''"
          flat
          class="yy-img-add-btn"
          @mousedown="updateCover"
        >
          <q-icon
            size="1.5rem"
            :name="'img:' + iconBase64.get('addPointImgBtn')"
          />
        </q-btn>
        <q-img
          v-else
          :src="newChannel.coverUrl"
          spinner-color="white"
          class="yy-channel-cover"
          fit="cover"
        ></q-img>
      </div>
      <q-btn
        :label="coverBtnLabel"
        unelevated
        class="yy-light-q-btn yy-small-q-btn"
        @mousedown="updateCover"
      ></q-btn>
    </div>
    <input type="hidden" prop="coverUrl" v-model="newChannel.coverUrl" />
    <div class="yy-validate-feedback" v-if="modelControl['coverUrl']">
      {{ modelControl['coverUrl'] }}
    </div>
    <div class="yy-my-input-wrapper column">
      <textarea
        id="channelIntro"
        ref="channelIntroRef"
        prop="channelIntro"
        v-model="newChannel.channelIntro"
        rows="3"
        maxlength="50"
        @blur="validateBlur"
        @input="validateInput"
        :class="{ 'validate-feedback': modelControl['channelIntro'] }"
      ></textarea>
      <label class="order-first" for="channelIntro">频道简介</label>
      <div class="yy-validate-feedback" v-if="modelControl['channelIntro']">
        {{ modelControl['channelIntro'] }}
      </div>
    </div>
    <div class="yy-btn-wrapper row justify-end">
      <q-btn
        unelevated
        class="yy-q-btn"
        label="取消"
        @mousedown="cancelOperation"
      />
      <q-btn
        unelevated
        class="yy-q-btn"
        :label="newChannel.id ? '确定' : '创建'"
        @mousedown="saveChannel"
        :disable="newChannel.id != '' && !updateEnable"
      />
    </div>
  </div>
</template>
<script>
import { toRefs, ref, reactive, computed, watch } from "vue"
import useDrawerMaterial from "@composables/useDrawerMaterial";
import useTextareaHandler from '@/composables/useTextareaHandler'
import useChannelOperations from '@/composables/useChannelOperations'
import useFormValidator from '@/composables/useFormValidator'
import { useStore } from "vuex";
import { addChannel, updateChannel } from "@api/channel-api"
export default {
  name: "ChannelEdit",
  emits: ["go2Box", "back2Box", "updateLoading"],
  props: {
    channel: {
      type: Object,
      default: () => { }
    }
  },
  setup (props) {
    const userId = ref('')
    const { channel } = toRefs(props)
    const channelIntroRef = ref(null)
    const newChannel = reactive({})
    console.log('newChannel', newChannel)
    const coverBlob = ref(null)
    const modelControl = ref({})
    useTextareaHandler(channelIntroRef, () => newChannel.channelIntro)

    const coverBtnLabel = computed(() => {
      if (newChannel.coverUrl != '') return "修改封面"
      else return "添加封面"
    })
    const { iconBase64 } = useDrawerMaterial()
    const { rules, refreshSharedChannelsIfNeed } = useChannelOperations()
    const { validateBlur, validateInput, validateAll } = useFormValidator(rules, newChannel, modelControl)

    watch(channel, (newValue) => {
      newChannel.id = newValue.id || ''
      newChannel.channelName = newValue.channelName || ''
      newChannel.channelIntro = newValue.channelIntro || ''
      newChannel.coverUrl = newValue.coverUrl || ''
      newChannel.shared = newValue.shared || '0'
      if (newValue.coverBlob) coverBlob.value = newValue.coverBlob
      modelControl.value = {}
      console.log('The new channel value is: ', newValue, newChannel)
    }, { immediate: true })

    userId.value = useStore().state.user.id

    const updateEnable = computed(() => {
      let oldC = channel.value
      let newC = newChannel
      return oldC.channelName != newC.channelName || oldC.channelIntro != newC.channelIntro || oldC.coverBlob != undefined
    })
    return { newChannel, iconBase64, channelIntroRef, coverBtnLabel, modelControl, validateBlur, validateAll, validateInput, userId, coverBlob, updateEnable, refreshSharedChannelsIfNeed }
  },

  created () {
    this.saveChannel = this.__.debounce(this.saveChannelHandler, 200);
  },
  activated () {
    this.$nextTick(() => {
      this.$refs.channelNameRef.focus()
    })
  },
  methods: {
    saveChannelHandler () {
      this.validateAll(async () => {
        try {
          this.$emit("updateLoading", true)
          let fileName = this.userId + '_' + Date.now() + '.png'
          let message = "频道创建成功！"
          if (this.newChannel.id == '') {
            console.log("do commit create")
            await addChannel(this.coverBlob, fileName, this.newChannel)
          } else {
            console.log("do commit update")
            let updateParams = (({ id, channelName, channelIntro }) => ({ id, channelName, channelIntro }))(this.newChannel)
            await updateChannel(this.coverBlob, fileName, updateParams)
            message = "频道修改成功！"
          }
          this.$q.notify({
            message,
            color: "positive"
          })
          this.refreshSharedChannelsIfNeed(this.newChannel)
          this.$emit("back2Box", { reload: Date.now() })
        } catch (err) {
          console.log("commit channel fail", err)
        } finally {
          this.$emit("updateLoading", false)
          this.coverBlob = null
        }
      })

    },
    updateCover () {
      this.$emit("go2Box", ["CoverUpdate", this.coverBtnLabel, { channel: this.newChannel }])
    },
    cancelOperation () {
      this.coverBlob = null
      this.$emit('back2Box')
    }
  },
}
</script>
<style lang="sass" scoped>
@import "@sass/common.edit.sass"
$item-width: 230px
.yy-channel-edit
  padding: 12px 2px 12px 40px
.yy-my-input-wrapper
  font-weight: 400
  label
    font-size: 12px
    line-height: 24px
    padding: 0
    color: $gray-color5
  input, textarea
    width: $item-width
    color: $gray-color5
    background-color: $dark-color6
    &:focus
      background-color: $dark-color6
  input
    height: 32px
  textarea
    height: 64px
.yy-cover-wrapper
  margin: 8px 0
  .no-border
    border: none
  .yy-img-wrapper
    margin: 0
    width: 120px
    height: 120px
    border-radius: 6px
    overflow: hidden
    .yy-img-add-btn
      width: 116px
      height: 116px
    .yy-channel-cover
      width: 100%
      height: 100%
      background-color: $dark-color7
.yy-btn-wrapper
  margin-top: 58px
</style>
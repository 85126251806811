<template>
  <div v-if="isAuthenticated" class="yy-need-identity column items-center">
    <div class="yy-tips">抱歉，<br />您暂时未被授予该功能的使用权限。</div>
  </div>
  <div v-else class="yy-need-identity column items-center">
    <div class="yy-tips">
      抱歉，<br />该项功能需要验证身份后才能使用，请先登录。
    </div>
    <q-btn unelevated class="yy-my-head-btn" @click="goLogin" label="去登录" />
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "NeedAuth",
  setup () {
    const store = useStore()
    return {
      isAuthenticated: computed(() => store.getters.isAuthenticated)
    }
  },
  methods: {
    goLogin () {
      this.$emitter.emit('showLoginEvent')
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@sass/common.my.sass"
</style>
<template>
  <div class="yy-search-head-wrapper self-stretch row items-center">
    <div class="yy-icon-wrapper row items-center justify-center">
      <q-icon size="2.25rem" :name="'img:' + iconData" />
    </div>
    <div class="yy-head-title col-grow">{{ title }}</div>
    <div class="yy-head-label content-end">在线<br />频道</div>
  </div>
  <div flat class="yy-line"></div>
</template>
<script>
export default {
  name: "SearchBoardHead",
  props: {
    title: {
      type: String,
      default: "默认标题",
    },
    iconData: {
      type: String,
    },
  },
};
</script>
<style lang="sass" scoped>
@import "@sass/quasar.sass"
.yy-search-head-wrapper
  padding: 16px 0px 16px 16px
  .yy-icon-wrapper
    width: 48px
    height: 48px
    border-radius: 50%
    background-color: rgba(138, 180, 248, 0.2)
  .yy-head-title
    color: $gray-color2
    font-size: 36px
    font-weight: 400
    margin-left: 6px
  .yy-head-label
    @include square-label
</style>

<template>
  <div class="yy-search-board column items-stretch">
    <SearchBoardHead
      :title="channelIconBase64[0].name"
      :iconData="channelIconBase64[0].data"
      @go-back="$emit('closeBoard')"
    ></SearchBoardHead>
    <div class="yy-search-wrapper row no-wrap">
      <q-input
        dense
        filled
        color="main-color4"
        bg-color="dark-color4"
        v-model="search"
        placeholder="搜索频道"
        class="col"
        input-class="yy-search-input"
      />
      <q-btn class="yy-search-btn" color="main-color4" unelevated>
        <q-icon
          name="img:data:image/svg+xml,%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7.5' stroke='%2385969F'/%3E%3Cpath stroke='%2385969F' stroke-width='2' d='M12.768 13.36l5 6'/%3E%3C/svg%3E"
        ></q-icon>
      </q-btn>
    </div>
    <div class="yy-list-head row justify-end">
      <div class="yy-list-sort">
        <span :class="{ 'yy-current-sort': sortType == 0 }">最近浏览</span
        ><span>/</span><span>热度</span><span>/</span><span>更新</span>
      </div>
    </div>
  </div>
</template>
<script>
import useDrawerMaterial from "@composables/useDrawerMaterial";
import SearchBoardHead from "@components/drawer/SearchBoardHead";
export default {
  name: "ChannelBoard",
  emits: ["closeBoard"],
  setup () {
    const { channelIconBase64 } = useDrawerMaterial();
    return {
      channelIconBase64,
    };
  },
  data () {
    return {
      sortType: 0,
    };
  },
  components: {
    SearchBoardHead,
  },
  methods: {},
};
</script>
<style lang="sass">
@import "@sass/quasar.variables.sass"
.yy-search-board
  .yy-search-wrapper
    .q-field--dense
      .q-field__control
        height: 36px
    .yy-search-input
      height: 36px!important
      font-size: 14px!important
      padding-top: 0!important
      padding-bottom: 0!important
      color: $gray-color1!important
      border-top-left-radius: 4px
      border-bottom-left-radius: 4px
</style>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
.yy-search-board
  padding: 0 14px

  .yy-search-wrapper
    margin-top: 12px
    border-radius: 4px
    background-color: $dark-color4
    height: 36px
    .yy-search-btn
      border-left: 1px solid $gray-color1
      border-top-left-radius: 0
      border-bottom-left-radius: 0
      .q-icon
        width: 17px
        height: 20px
  .yy-channel-name
    margin: 0
    font-size: 36px
    font-weight: 700
    line-height: 72px
    color: $gray-color2
  .yy-list-head
    margin: 6px 14px
    font-size: 12px
    .yy-list-name
      color: $gray-color3
  .yy-channel-list
    padding: 0 14px
    &-item
      padding: 6px 2px 6px 22px
      .yy-channel-label
        margin-left: 12px
        color: $gray-color1
        font-size: 14px
</style>

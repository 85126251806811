<template>
  <div class="column items-stretch">
    <BoardHead
      title="设置"
      @go-back="$emit('changeStatus', 'Profile')"
    ></BoardHead>
  </div>
  <div class="yy-main-box column items-center self-center">
    <div class="yy-btn-wrapper row no-wrap">
      <q-btn
        flat
        label="注销账号"
        class="yy-btn"
        @click="deregister"
        :loading="doing"
      />
    </div>
  </div>
</template>
<script>
import BoardHead from "@components/drawer/BoardHead";
import { userDeregister } from "@api/account-api";
export default {
  name: "Setting",
  components: {
    BoardHead,
  },
  created () {
    this.deregister = this.__.debounce(this.deregisterHandler, 500);
  },
  methods: {
    deregisterHandler () {
      this.$q.dialog({
        title: '确认操作',
        message: '您确定要注销自己的账号吗？注销后无法再使用该账号登录及进行一些列操作',
        ok: { label: '确定' },
        cancel: { label: '取消' },
        focus: 'cancel',
        persistent: true
      }).onOk(() => {
        this.doing = true
        const params = {
          id: this.$store.state.user.id,
        }
        userDeregister(params).then(() => {
          this.$q.notify({
            message: "注销账号成功。",
            color: "positive"
          })
          this.$store.dispatch("removeUser")
          this.$emit('changeStatus', 'Profile')
        }).finally(() => {
          this.doing = false
        })
      }).onCancel(() => {
        // console.log('>>>> Cancel')
      }).onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
      })
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
.yy-main-box
  margin-top: 20px
</style>
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
//import "amfe-flexible";
import _ from 'lodash'
import Vue3Storage from "vue3-storage-secure";
import { axios } from "./utils/axios-plus"
import mitt from 'mitt'
import animated from 'animate.css'

let app = createApp(App)
app.config.globalProperties.__ = _
app.config.globalProperties.$axios = axios
app.config.globalProperties.$http = axios
app.config.globalProperties.$emitter = mitt()
app.provide('$emitter', app.config.globalProperties.$emitter)
app.use(Quasar, quasarUserOptions)
  .use(Vue3Storage, { namespace: "yunyumap_", secureKey: "20210920" })
  .use(store)
  .use(router)
  .use(animated)
  .mount("#app");

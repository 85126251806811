import Schema from 'async-validator';
import _ from 'lodash'
Schema.warning = function () { };
export default function useFormValidator (rules, form, modelControl) {
  const validator = new Schema(rules)
  const validateBlur = (e) => {
    console.log('validateBlur', e)
    const prop = e.target.attributes.prop.value
    if (!prop) {
      return false
    }
    let obj = {}
    obj[prop] = form[prop]

    validator.validate(obj, (errors, fields) => {
      if (errors && fields[prop]) {
        //console.log(errors, fields);
        //console.log(fields[prop][0].message);

        modelControl.value[prop] = fields[prop][0].message
        return errors
      }
      modelControl.value[prop] = null
    })
  }

  const validateInputHandler = (e) => {
    const prop = e.target.attributes.prop.value
    if (!prop) {
      return false
    }
    if (modelControl.value[prop]) {
      validateBlur(e)
    } else {
      return
    }
  }

  const validateInput = _.debounce(validateInputHandler, 300);

  const validateAll = (callback) => {
    console.log('validate all')
    validator.validate(form).then((value) => {
      // 校验通过
      console.log("validate form ok", value);
      if (typeof callback == "function") callback()
    }).catch(({ errors, fields }) => {
      console.log("validate form fail", errors, fields);
      for (let key in fields) {
        modelControl.value[key] = fields[key][0].message
      }
      console.log(modelControl);
      return errors
    })
  }

  return {
    validateBlur,
    validateAll,
    validateInput
  }
}
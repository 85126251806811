<template>
  <q-layout view="lhr LpR lfr">
    <Drawer v-if="initSuccess"></Drawer>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import Drawer from "@components/drawer/Drawer";
import { ref, provide } from "vue";
export default {
  name: "Layout05",
  props: {
    initSuccess: {
      type: Boolean,
      required: true
    }
  },
  setup () {
    provide(
      "fontSizeMap",
      new Map([
        ["320", 12],
        ["375,2", 14],
        ["375,3", 16],
        ["414", 14],
        ["600", 14],
        ["768", 14],
        ["900", 14],
        ["1024", 14],
        ["1200", 14],
        ["1439", 16],
        ["1920", 16],
        [">", 18],
      ])
    );
    provide("defaultFontSize", "16");
    const tabs = ref([
      { name: "products", label: "PRODUCTS", to: "/products" },
      { name: "company", label: "COMPANY", to: "/company" },
      { name: "blog", label: "BLOG", to: "/blog" },
      { name: "app", label: "APP", to: "/app" },
      { name: "login", label: "登录", to: "/login" },
      { name: "register", label: "注册", to: "/register" },
    ]);
    return {
      //leftDrawerOpen: ref(false),
      tab: ref("company"),
      tabs,
    };
  },
  components: {
    Drawer,
  },
  created () {
    //this.$q.screen.setSizes({ xs: 480, sm: 600, md: 900, lg: 1200, xl: 1800 });
    this.$q.screen.setSizes({ sm: 600, md: 1024, lg: 1440, xl: 1920 });
    this.show();
  },
  methods: {
    show () {
      // prints out Quasar version
      console.log("this.$q.screen.sizes:", this.$q.screen);
    },
  },
};
</script>
<style lang="sass">
@import "@sass/common.sass"
@import "@sass/quasar.variables.sass"
$indecator-color: #FF9A02

.yy-header
  padding-top: 4px
  background-color: black!important
.yy-toolbar
  background-color: $dark-color1
  min-height: 44px!important
  padding: 0 0 0 20px!important
.yy-logo
  height: 36px!important
  width: 36px!important
  &:hover
    cursor: pointer
.yy-toolbar-title
  color: $head-font-color
  &:hover
    cursor: pointer
  .yy-main-title
    font-weight: bold
    font-size: 24px
    //line-height: 32px
    line-height: 0.85
    padding-bottom: 4px
  .yy-sub-title
    font-size: 10px
    //line-height: 12px
    line-height: 1
.text-yy-indecator
  color: $active-color
  height: 4px!important
  top: -4px!important
.yy-tabs
  overflow: visible!important
  font-size: 12px
  color: $head-font-color
.yy-tab
  padding: 0!important
  width: 90px
  @media screen and (max-width: 899px) and (min-width: 600px)
    width: 85px
.yy-small-tab
  padding: 0!important
  width: 40px
  &.first
    margin-left: 24px
.yy-tab-content
  min-width: auto!important
</style>

<template>
  <div class="yy-board-wrapper column items-stretch">
    <BoardHead
      title="YUNYUMAP"
      :mainTitle="true"
      :darkMode="true"
      @go-back="closeBoard"
    ></BoardHead>
    <div class="yy-profile column">
      <q-btn
        v-if="isAuthenticated"
        round
        @click="$emit('changeStatus', 'Avatar')"
      >
        <q-avatar v-if="userAvatar"> <img :src="userAvatar" /></q-avatar>
        <q-avatar v-else color="gray-color3" text-color="white"
          >{{ firstChar }}
        </q-avatar>
        <q-tooltip
          class="bg-gray-color3"
          anchor="top middle"
          self="bottom middle"
          :offset="[10, 10]"
          transition-show="scale"
          transition-hide="scale"
          >修改个人头像</q-tooltip
        >
      </q-btn>
      <q-avatar v-else> <img :src="userAvatar" /></q-avatar>
      <div class="yy-info items-stretch column">
        <div v-if="isAuthenticated" class="yy-name">{{ userName }}</div>
        <div v-if="isAuthenticated" class="yy-intro">简介：{{ userBio }}</div>
      </div>
    </div>
    <div v-if="!isAuthenticated" class="yy-btn-wrapper row no-wrap">
      <q-btn
        flat
        label="登录"
        class="yy-btn"
        @click="$emit('changeStatus', 'Login')"
      />
      <q-btn
        flat
        label="注册"
        class="yy-btn"
        @click="$emit('changeStatus', 'Register')"
      />
    </div>
    <div v-else class="yy-btn-wrapper row no-wrap">
      <q-btn
        flat
        label="修改"
        class="yy-btn"
        @click="$emit('changeStatus', 'UserEdit')"
      >
        <q-tooltip
          class="bg-gray-color3"
          transition-show="scale"
          transition-hide="scale"
          >修改个人信息</q-tooltip
        >
      </q-btn>
      <q-btn
        flat
        label="退出"
        class="yy-btn"
        @click="logout"
        :loading="doing"
        :disable="doing"
      >
        <q-tooltip
          class="bg-gray-color3"
          transition-show="scale"
          transition-hide="scale"
          >退出登录</q-tooltip
        >
      </q-btn>
    </div>
    <div class="yy-line"></div>
    <div class="yy-info-wrapper column">
      <div
        class="yy-info yy-info-about"
        @click="$emit('changeStatus', ['Policy', 'about', 'Profile'])"
      >
        关于我们
      </div>
      <div class="yy-info yy-info-feedback" @click="feedback">反馈问题</div>
      <div
        class="yy-info yy-info-contact"
        @click="$emit('changeStatus', ['Policy', 'contact', 'Profile'])"
      >
        联系我们
      </div>
      <div class="yy-info yy-info-setting" @click="setting">设置</div>
    </div>
    <div class="yy-line"></div>
    <div class="yy-other">
      <div class="yy-version">版本： V{{ version }}</div>
      <div class="yy-rule-wrapper">
        <a
          class="yy-rule"
          @click="$emit('changeStatus', ['Policy', 'privacy', 'Profile'])"
          >隐私政策</a
        ><a
          class="yy-rule"
          @click="$emit('changeStatus', ['Policy', 'agreement', 'Profile'])"
          >用户协议</a
        >
      </div>
    </div>
    <Copyright :left="true"></Copyright>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { logout } from "@api/auth-api";
import Copyright from "@components/Copyright"
import BoardHead from "@components/drawer/BoardHead";
export default {
  name: "Profile",
  emits: ["changeStatus", "closeBoard"],
  setup () {
    const store = useStore();
    return {
      firstChar: computed(() => store.state.userName ? store.state.userName.charAt(0) : ''),
      userName: computed(() => store.state.user.nickname || store.state.userName || "游客"),
      userAvatar: computed(
        () => {
          if (store.state.userName) {
            return store.state.userAvatar
          } else {
            return require("@img/avatar.svg")
          }
        }
      ),
      userBio: computed(() => store.getters.userBio),
      isAuthenticated: computed(() => store.getters.isAuthenticated),
      removeUser: () => store.dispatch("removeUser"),
    };
  },
  props: {
    profileStatus: {
      type: String,
      required: true,
    },
  },
  components: {
    BoardHead,
    Copyright
  },
  data () {
    return {
      version: '0.6_alpha',
      doing: false,
    };
  },
  methods: {
    logout () {
      this.doing = true;
      logout()
        .then(() => {
          this.removeUser();
        })
        .catch((err) => {
          console.log("logout fail: ", err);
        })
        .finally(() => {
          this.doing = false;
        });
    },
    feedback () {
      if (this.isAuthenticated) {
        this.$emit('changeStatus', 'Feedback')
      } else {
        this.$q.notify({
          message: "请先登录，用户在登录后才能填写反馈信息",
          caption: "系统提示",
          color: "warning"
        })
      }
    },
    setting () {
      if (this.isAuthenticated) {
        this.$emit('changeStatus', 'Setting')
      } else {
        this.$q.notify({
          message: "请先登录，用户在登录后才能进行相关内容设置",
          caption: "系统提示",
          color: "warning"
        })
      }
    },
    closeBoard () {
      console.log('main close board')
      this.$emitter.emit('toggleDrawerEvent', 0)

    }
  },

};
</script>

<style lang="sass" scoped>
@import "@sass/drawer.board.sass"
$m-left: 54px
.yy-line
  margin: 0 $drawer-xpadding

.yy-profile
  margin-top: 16px
  margin-left: $m-left
  margin-right: 14px
  .q-btn
    width: 48px
    height: 48px
  img
    width: 48px
    height: 48px
  .yy-info
    font-size: 18px
    font-weight: 400
    color: $gray-color2
    .yy-name
      line-height: 32px
    .yy-intro
      font-size: 12px
      line-height: 16px
      color: $gray-color5
      overflow: hidden
      white-space: break-spaces
      text-overflow: ellipsis
.yy-btn-wrapper
  margin: 30px 0 18px $m-left
.yy-info-wrapper
  margin: 18px 0 18px $m-left
  .yy-info
    display: flex
    font-size: 12px
    font-weight: 400
    color: $gray-color1
    align-items: center
    &:hover
      cursor: pointer
    &:not(:first-child)
      margin-top: 14px
    @mixin info-before($img-data)
      &::before
        display: inline-block
        content: " "
        width: 18px
        height: 18px
        margin-right: 8px
        background: left / contain no-repeat url($img-data)
    &-about
      @include info-before("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.5' y='.5' width='17' height='17' rx='8.5' stroke='%2385969F'/%3E%3Cpath d='M10.148 14h-2.31V6.604h2.31V14zM7.701 4.696c0-.328.119-.597.356-.806.237-.21.544-.315.922-.315.379 0 .686.105.923.315.237.21.356.478.356.806 0 .328-.119.597-.356.807-.237.21-.544.314-.923.314-.378 0-.685-.104-.922-.314a1.028 1.028 0 0 1-.356-.807z' fill='%2385969F'/%3E%3C/svg%3E")
    &-feedback
      @include info-before("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.5' y='4.5' width='14' height='11' rx='1.5' stroke='%2385969F'/%3E%3Cpath d='M3 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2' stroke='%2385969F'/%3E%3C/svg%3E")
    &-contact
      @include info-before("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.5' y='2.5' width='17' height='13' rx='1.5' stroke='%2385969F'/%3E%3Cpath d='M1 3l8 5 8-5' stroke='%2385969F'/%3E%3C/svg%3E")
    &-setting
      @include info-before("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.789 16.294L.577 9 4.79 1.706h8.422L17.423 9l-4.212 7.294H4.79z' stroke='%2385969F'/%3E%3Ccircle cx='9' cy='9' r='3.5' stroke='%2385969F'/%3E%3C/svg%3E")
.yy-other
  margin: 18px 0 75px $m-left
  font-size: 12px
  font-weight: 400
  color: $dark-color4
  .yy-rule-wrapper
    .yy-rule
      &:not(:first-child)
        margin-left: 8px
      &:hover
        cursor: pointer
</style>

import { getChannelList, getSharedChannelData } from "@api/channel-api"
import { getUserNameAvatar } from "@api/account-api"
import { useStore } from "vuex";
import { useStorage } from "vue3-storage-secure";
import { ST_SELECTED_CHANNEL } from "@config/constant"
export default function useChannelOperations () {
  const store = useStore();
  const storage = useStorage();

  const channelListHandler = function (channelList) {
    return channelList.map((value) => {
      return channelHandler(value)
    })
  }

  const channelHandler = function (channel) {
    if (channel) {
      channel.avatar = process.env.VUE_APP_API_BASE_STATIC_URL + channel.avatar
      channel.coverUrl = process.env.VUE_APP_API_BASE_STATIC_URL + channel.coverUrl
      if (channel.owner) {
        channel.owner.avatar = process.env.VUE_APP_API_BASE_STATIC_URL + channel.owner.avatar
      }
    }
    return channel
  }

  const channelOwnerHandler = function (channel) {
    if (channel && channel.owner) {
      channel.owner.avatar = process.env.VUE_APP_API_BASE_STATIC_URL + channel.owner.avatar
    }
    return channel
  }

  const getSelectedChannelData = async function (selectedChannel) {
    console.log("get selected channel data")
    try {
      let res = null
      if (!selectedChannel.owner) {
        res = await getUserNameAvatar({ userName: selectedChannel.createBy })
        res.avatar = process.env.VUE_APP_API_BASE_STATIC_URL + res.avatar
      }
      await store.dispatch('updateSelectedChannelObj', { selectedChannel, owner: res })
      res = await getSharedChannelData({ id: selectedChannel.id })
      await store.dispatch("setChannelInfoList", res)
    } catch (e) {
      console.log('get selected channel data error:', e)
    }
  }

  const refreshSharedChannels = async function (success, fail, complete) {
    console.log("refresh shared channels")
    try {
      let res = null

      res = await getChannelList()
      let channelList = channelListHandler(res.records)
      await store.dispatch("setChannelList", channelList)
      let selectedChannel = store.getters.selectedChannel
      let update = false
      if (channelList.length > 0 && (selectedChannel == null || channelList.findIndex((value) => value.id == selectedChannel.id) < 0)) {
        selectedChannel = channelList[0]
      } else if (channelList.length > 0) {
        let found = channelList.find((value) => value.id == selectedChannel.id && value.shared == "1")
        selectedChannel = found
        if (found) update = true
      } else {
        selectedChannel = null
      }
      if (selectedChannel) {
        if (!update) {
          res = await getUserNameAvatar({ userName: selectedChannel.createBy })
          selectedChannel.owner = res
          selectedChannel = channelOwnerHandler(selectedChannel)
        }
        res = await getSharedChannelData({ id: selectedChannel.id })
        await store.dispatch("setChannelInfoList", res)
      } else {
        await store.dispatch("setChannelInfoList", null)
      }
      const storageChannel = storage.getSecureStorageSync(ST_SELECTED_CHANNEL)
      if (update && storageChannel && selectedChannel.id == storageChannel.id) {
        await store.dispatch('updateSelectedChannel', selectedChannel)
      } else {
        await store.dispatch('setSelectedChannel', selectedChannel)
      }
      console.log('get selectedChannel:', selectedChannel)
      console.log('get channel info list result:', store.getters.channelInfoList)

      if (typeof success == "function") {
        success()
      }
    } catch (e) {
      console.log('refresh shared channels error:', e)
      if (typeof fail == "function") {
        fail()
      }
    } finally {
      if (typeof complete == "function") {
        complete()
      }
    }
  }

  const refreshSharedChannelsIfNeed = function (channel, selectedNeed = false) {
    console.log("refresh shared channels if need")
    let selectedChannel = store.getters.selectedChannel
    if (channel.shared != "1" || (selectedNeed && channel.id != selectedChannel.id)) { return }
    refreshSharedChannels()
  }

  const rules = {
    channelName: { required: true, min: 2, message: '频道名称不能为空，长度至少为2个汉字/字符' },
    channelIntro: { required: true, min: 2, message: '频道简介不能为空,长度至少为2个汉字/字符' },
    coverUrl: { required: true, message: '必须为频道选择一张封面图片' }
  }

  return {
    channelListHandler,
    channelHandler,
    rules,
    refreshSharedChannels,
    refreshSharedChannelsIfNeed,
    getSelectedChannelData
  }

}
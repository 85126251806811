<template>
  <div class="title row items-center no-wrap">
    <h1 v-if="mainTitle != ''" class="main">{{ mainTitle }}</h1>
    <h2 v-if="subTitle != ''" class="sub">
      {{ mainTitle == '' ? '' : '—' }}{{ subTitle }}
    </h2>
  </div>
</template>
<script>
export default {
  name: "DrawerHead",
  props: {
    mainTitle: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="sass" scoped>
@import "@sass/quasar.variables.sass"
@import "@sass/quasar.sass"
.title
  @include drawer-head-box

  .main
    font-size: 22px
    margin: 0
    line-height: 1
    font-weight: 400
  .sub
    font-size: 16px
    margin: 0
    line-height: 1
    font-weight: 400
</style>
